import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: felxt-start;
  @media screen and ${tokens.device.phoneUp} {
  }
  @media screen and ${tokens.device.tabletUp} {
    justify-content: center;
    flex: 1 0 auto;
    align-self: center;
    max-height: 720px;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
    min-width: 0px;
    max-height: none;
  }
  @media screen and ${tokens.device.largeUp} {
    margin-right: 96px;
    align-self: flex-start;
    margin-left: 96px;
    min-width: 0px;
  }
`;

export const StartOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  @media screen and ${tokens.device.phoneUp} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }
  @media screen and ${tokens.device.tabletUp} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const AcceptTerms = styled.div`
  box-sizing: border-box;
  padding: 24px 80px 0px 80px;
  width: 100%;
  align-self: center;
  max-width: 544px;
`;

export const Title = styled.div`
  ${tokens.type.pMedium};
  box-sizing: border-box;
  padding: 0 48px;
  width: 100%;
  margin-bottom: 8px;
  max-width: 580px;
  align-self: center;
`;

export const Span = styled.span`
  display: block;
`;

export const QuitButton = styled.div`
  display: none;
  &:hover {
    cursor: pointer;
  }
  @media screen and ${tokens.device.tabletUp} {
    display: inherit;
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;
