import styled from "styled-components";
import * as tokens from "../../../../pages/tokens";

export const FormWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  rowgap: 16px;
  margin: 24px 0px;
  width: 100%;
`;

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 720px;
  padding: 48px 0px;
  margin: 0 80px;
  flex: 1 0 0;
  gap: 0px;
`;

export const TermsBoxWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 8px 24px 24px;
  margin: 0;
  max-height: 350px;
  gap: 0px;
  width: 100%;
  border: 1px solid ${tokens.color.grays400};
  background-color: ${tokens.color.graysWhite};
  border-radius: 8px;
`;

export const TermsBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 16px 0 0;
  margin: 0;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${tokens.color.graysWhite};
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 20px;
    border: 5px solid ${tokens.color.graysWhite};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
    border-radius: 20px;
    border: 5px solid ${tokens.color.graysWhite};
  }
  p {
    margin: 0 0 15px 0;
    padding: 0;
    line-height: 1.4em;
  }
  p.title {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 16px;
  }
  p.subtitle {
    font-weight: 600;
    margin-top: 5px;
  }
  p.list {
    margin-bottom: 5px;
  }
  p.listlast {
    margin-bottom: 15px;
  }
`;

export const AcceptTerms = styled.div`
  margin-top: 16px;
`;

export const Title = styled.p`
  ${tokens.type.h2};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  ${tokens.type.pLarge};
  color: ${tokens.color.black85};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
`;

export const SubTitle = styled.p`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  margin-top: 40px;
`;

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -8px 0px 16px 0px;
  padding: 0px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`;
