import * as Styled from "./Styles";
import QuoteResult from "../../components/Quote/QuoteResult";

type Info = {
  title: string;
  text: string;
};
const enum Direction {
  forward = 1,
  back = -1,
}
const clearInfo = {
  show: false,
  info: {
    title: null,
    text: null,
  },
};

interface Props {
  contactChange?: (contactinfo: any) => void;
  contactInfo?: any;
}

export default function QuoteDetails(props: Props) {
  return <QuoteResult />;
}
