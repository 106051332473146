import { useEffect, useState } from "react";
import * as Styled from "./Styles";
import ModalButton from "../ModalButton/ModalButton";
import FormField from "../FormField/FormField";
import { State } from "../PrimaryButton/PrimaryButton";
import { useAnimate, AnimatePresence, motion } from "framer-motion";
import { color } from "../../pages/tokens";
import AcceptTerms from "../Terms/Terms";

interface Props {
  doneClick?: (values: any) => void;
  cancelClick?: (withsave?: boolean) => void;
  updateSubTitle?: (message: string) => void;
  contactChange?: (info: any) => void;
  contactInfo?: any;
}

export default function CallBack(props: Props) {
  const {
    contactInfo = null,
    doneClick = () => null,
    cancelClick = () => null,
    updateSubTitle = () => null,
    contactChange = () => null,
  } = props;
  const [valid, setValid] = useState(false);
  const [validation, setValidation] = useState({
    name: false,
    email: false,
    phone: false,
    accepted: false,
  });
  const [index, setIndex] = useState(0);
  const [check, animateCheck] = useAnimate();
  const [circle, animateCircle] = useAnimate();
  const [done, setDone] = useState(false);

  const doProgress = () => {
    const animation = async () => {
      const transLoop = { ease: "linear", repeatType: "loop", repeat: 5, duration: 0.75 } as any;
      const transSpring = { type: "spring", stiffness: 400, damping: 15, mass: 1 } as any;
      const transFade = { ease: "easeInOut" } as any;
      updateSubTitle("Scheduling your call back ...");
      await animateCircle(circle.current, { rotate: 360 }, transLoop);
      animateCircle(circle.current, { opacity: 0 }, transFade);
      updateSubTitle("You're all set " + getFirstName(contactInfo.name) + "! Talk soon.");
      setDone(true);
      await animateCheck(check.current, { scale: [0, 1], opacity: [1, 1] }, transSpring);
    };
    animation();
  };

  useEffect(() => {
    if (contactInfo?.accepted) {
      setValidation({ name: true, email: true, phone: true, accepted: true });
      setValid(true);
    }
  }, [contactInfo]);

  useEffect(() => {
    if (index === 1) doProgress();
    // eslint-disable-next-line
  }, [index]);

  const getFirstName = (name: string) => {
    if (!name) return "";
    const firstname = contactInfo.name.split(" ")[0];
    return firstname.charAt(0).toUpperCase() + firstname.slice(1);
  };

  const updateValidState = (name: string, state: boolean) => {
    let temp = validation;
    temp[name] = state;
    setValidation(temp);
    setValid(checkIsValid(temp));
  };

  const handleValueUpdates = (name: string, value: string | boolean) => {
    let temp = { ...contactInfo };
    temp[name] = value;
    contactChange(temp);
  };

  const checkIsValid = (info: any) => {
    let isvalid = true;
    Object.keys(info).forEach((key: any) => {
      if (!info[key]) isvalid = false;
    });
    return isvalid;
  };

  const renderProgress = () => {
    return (
      <motion.div
        key={"progress"}
        layout
        initial={{ height: 0 }}
        animate={{ height: "auto" }}
        style={{ width: "100%" }}
      >
        <Styled.Form>
          <Styled.Progress>
            <Styled.CheckWrapper ref={check} $size={100}>
              <Check size={100} color={color.green500} stroke={0.5} />
            </Styled.CheckWrapper>
            <Styled.CircleWrapper ref={circle} $size={100}>
              <Circle size={100} color={color.grays700} stroke={0.5} />
            </Styled.CircleWrapper>
          </Styled.Progress>
        </Styled.Form>
        <Styled.ButtonBar>
          <ModalButton
            label={"Done"}
            showicon={false}
            onClick={() => doneClick(null)}
            length={200}
            theme={"secondary"}
            state={!done ? State.disabled : State.active}
          />
        </Styled.ButtonBar>
      </motion.div>
    );
  };

  const renderForm = () => {
    return (
      <motion.div
        layout
        key={"form"}
        initial={{ height: "auto" }}
        animate={{ height: "auto" }}
        exit={{ height: 0, overflow: "hidden", opacity: 0 }}
      >
        <Styled.Form>
          <FormField
            theme={"white"}
            label={"Name"}
            type={"text"}
            required={true}
            value={contactInfo?.name}
            errormessage={"Please enter your full name"}
            onChange={(value) => handleValueUpdates("name", value)}
            didValidate={(state) => updateValidState("name", state)}
          />
          <FormField
            theme={"white"}
            label={"Company email"}
            type={"email"}
            required={true}
            value={contactInfo?.email}
            errormessage={"Please provide a valid email."}
            onChange={(value) => handleValueUpdates("email", value)}
            didValidate={(state) => updateValidState("email", state)}
          />
          <FormField
            theme={"white"}
            label={"Phone number"}
            type={"phone"}
            required={true}
            value={contactInfo?.phone}
            errormessage={"Please provide a valid phone number."}
            onChange={(value) => handleValueUpdates("phone", value)}
            didValidate={(state) => updateValidState("phone", state)}
          />
          <AcceptTerms
            variant={"privacy"}
            accepted={contactInfo?.accepted}
            onChange={(state: boolean) => {
              handleValueUpdates("accepted", state);
              updateValidState("accepted", state);
            }}
          />
        </Styled.Form>

        <Styled.ButtonBar>
          <ModalButton
            label={"Cancel"}
            showicon={false}
            length={150}
            theme={"tertiary"}
            onClick={() => cancelClick(false)}
          />
          <ModalButton
            label={"Request Callback"}
            showicon={false}
            onClick={() => setIndex(1)}
            state={!valid ? State.disabled : State.active}
            theme={"secondary"}
          />
        </Styled.ButtonBar>
      </motion.div>
    );
  };

  return (
    <Styled.Wrapper>
      <AnimatePresence initial={false}>
        {index === 1 && renderProgress()}
        {index === 0 && renderForm()}
      </AnimatePresence>
    </Styled.Wrapper>
  );
}

const Check = ({ size, stroke, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0, 0, 24, 24">
      <path
        d="M 8.44 12.339 L 10.607 14.506 L 10.587 14.486 L 15.467 9.596"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path
        d="M 12 3 C 7.029 3 3 7.029 3 12 C 3 16.971 7.029 21 12 21 C 16.971 21 21 16.971 21 12 C 21 7.029 16.971 3 12 3 Z"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
    </svg>
  );
};

const Circle = ({ size, stroke, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0, 0, 24, 24">
      <path
        d="M 12 3 C 7.029 3 3 7.029 3 12 C 3 16.971 7.029 21 12 21 C 16.971 21 21 16.971 21 12 C 21 10.757 20.748 9.574 20.293 8.497"
        fill="transparent"
        strokeWidth={stroke}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray=""
      ></path>
      <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
    </svg>
  );
};
