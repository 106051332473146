import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const themeColors = {
  white: {
    color: tokens.color.graysTextPrimary,
    borderColor: tokens.color.grays200,
    backgroundColor: tokens.color.graysWhite,
  },
  blue: {
    color: tokens.color.graysWhite,
    borderColor: tokens.color.graysWhite20,
    backgroundColor: tokens.color.primaryGPBlue,
  },
  orange: {
    color: tokens.color.graysTextPrimary,
    borderColor: tokens.color.warning300a30,
    backgroundColor: tokens.color.warning200,
  },
};

export const Container = styled(motion.div)<{
  $theme: string;
  $context: string;
}>`
  box-sizing: border-box;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 24px;
  top: 0px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: ${(props) => themeColors[props.$theme].bg};
  z-index: 50;
  @media screen and ${tokens.device.tabletUp} {
    display: ${(props) => (props.$context === "home" ? "none" : "flex")};
  }
`;

export const LeftWrapper = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  overflow: visible;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  overflow: visible;
`;

export const Logo = styled.div<{ $context: string }>`
  display: ${(props) => (props.$context === "home" ? "inherit" : "none")};
  @media screen and ${tokens.device.tabletUp} {
    display: inherit;
  }
`;

export const ButtonBack = styled.div<{ $show: boolean }>`
  display: none;
  @media screen and ${tokens.device.tabletUp} {
    display: ${(props) => (props.$show ? "inherit" : "none")};
  }
`;

export const ButtonBackIcon = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? "inherit" : "none")};
  align-items: center;
  justtify-content: flex-start;
  gap: 8px;
  padding: 0;
  margin: 0;
  @media screen and ${tokens.device.tabletUp} {
    display: none;
  }
`;

export const ButtonQuit = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? "inherit" : "none")};
`;

export const ButtonTalk = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? "inherit" : "none")};
  }
`;

export const ButtonCall = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? "inherit" : "none")};
  }
`;
