export const Testimonials = [
  {
    logo: "zoom",
    comment:
      "G-P's EOR service was a game-changer for our global expansion. Their team made the process seamless. Highly recommend!",
    company: "Zoom",
  },
  {
    logo: "impossible foods",
    comment:
      "We've been using G-P's EOR service for a year now. It's top-notch, efficient, and has made international hiring a breeze.",
    company: "Impossible Foods",
  },
  {
    logo: "yelp",
    comment:
      "Best EOR service we've used. G-P made hiring abroad so simple and efficient. Their support team is also fantastic.",
    company: "Yelp",
  },
  {
    logo: "drift",
    comment:
      "We're thoroughly impressed with G-P's EOR service. They've made global hiring easy and efficient. Highly recommend their services!",
    company: "Drift",
  },
  {
    logo: "seattle seahawks",
    comment:
      "G-P's EOR service has been instrumental in our global growth. Their expertise has made international hiring a seamless process.",
    company: "Seattle Seahawks",
  },
];
