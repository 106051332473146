import styled from "styled-components";
import * as tokens from "../tokens";
import { motion } from "framer-motion";

export const Viewport = styled(motion.div)<{
  $hidescroll: boolean;
}>`
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: ${(p) => (p.$hidescroll ? "hidden" : "auto")};
`;

export const QuotePage = styled.div`
  position: relative;
  boxSizing: "border-box",
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex: 1 0 auto;
  overflow: hidden;
  background-color: ${tokens.color.graysWhite};
  @media screen and ${tokens.device.tabletUp} {
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const SurveyItemWrapper = styled.div`
  margin: auto auto;
  @media screen and ${tokens.device.tabletUp} {
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const Content = styled(motion.div)`
  position: absolute;
  boxsizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
