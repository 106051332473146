import { useState, useEffect } from "react";
import { color, motion as trans } from "../../pages/tokens";
import * as Styled from "./Styles";
import { motion } from "framer-motion";

interface Props {
  pages?: string[];
  size?: { width: number; height: number };
  selectedFactor?: number;
  theme?: "white" | "blue" | "orange" | string;
  selected?: number;
  progress?: number;
  showcheck?: boolean;
  right?: number;
  onChange?: (index: number) => void;
}

export default function PageIndicator(props: Props) {
  const {
    pages = ["Page 1", "Page 2", "Page 3", "Page 4"],
    size = { width: 2, height: 2 },
    selectedFactor = 50,
    theme = "blue",
    selected = 0,
    progress = 2,
    right = 40,
    showcheck = true,
    onChange = () => null,
  } = props;
  const [index, setIndex] = useState(selected);
  const [items, setItems] = useState(pages);
  const [context, setContext] = useState(selected === 0 ? "darkBG" : "lightBG");
  const [hovered, setHovered] = useState(-1);
  useEffect(() => setIndex(selected), [selected]);
  useEffect(() => setItems(pages), [pages]);
  useEffect(() => setContext(index === 0 ? "darkBG" : "lightBG"), [index]);

  const setState = (i: number, item = "") => {
    if (i === index)
      return {
        ...Styled.theme[theme][context].selected,
        height: selectedFactor,
        width: size.width,
      };
    if (i < index)
      return {
        ...Styled.theme[theme][context].selected,
        height: size.height,
        width: size.width,
      };
    return { ...Styled.theme[theme][context].default, height: size.height, width: size.width };
  };

  const handleClick = (i: number) => {
    if (i > progress) return;
    //setIndex(i); - we just trigger the page navigation that will set the new page index
    onChange(i);
  };

  const setHover = (i: number) => {
    if (i === index) return Styled.theme[theme][context].hoverselected;
    return Styled.theme[theme][context].hoverdefault;
  };

  const renderBullet = (item: any, i: number) => {
    return (
      <Styled.Bullet
        $size={size}
        initial={setState(i)}
        variants={Styled.theme[theme][context]}
        transition={hovered !== i ? { ...trans.easIn, duration: 0.85 } : trans.spring}
        title={item}
        whileHover={setHover(i)}
        animate={setState(i, item)}
        onClick={() => handleClick(i)}
      />
    );
  };

  const renderExitArrow = (item: any, i: number) => {
    return (
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 24 24"
        overflow={"visible"}
      >
        <motion.path
          d="M 11.953 23.918 C 18.493 23.918 23.906 18.504 23.906 11.965 C 23.906 5.438 18.481 0.012 11.941 0.012 C 5.414 0.012 0 5.438 0 11.965 C 0 18.504 5.426 23.918 11.953 23.918 Z"
          fill={Styled.theme[theme][context]}
          variants={Styled.theme[theme][context]}
          whileHover={setHover(i)}
          initial={theme}
          transition={hovered === -1 ? trans.themeChange : trans.spring}
          animate={i === index ? { fill: color.green500 } : setState(i, item)}
          onClick={() => handleClick(i)}
        ></motion.path>
        <path
          d="M 5.941 17.18 C 5.601 17.18 5.273 16.887 5.273 16.324 C 5.273 11.508 7.324 8.531 11.977 8.531 L 12.164 8.531 L 12.164 6.071 C 12.164 5.637 12.48 5.285 12.938 5.285 C 13.266 5.285 13.465 5.415 13.805 5.742 L 18.938 10.535 C 19.195 10.77 19.278 11.004 19.278 11.227 C 19.278 11.461 19.195 11.695 18.938 11.93 L 13.805 16.758 C 13.5 17.039 13.254 17.18 12.925 17.18 C 12.723 17.187 12.528 17.11 12.384 16.968 C 12.24 16.826 12.161 16.632 12.165 16.43 L 12.165 13.934 L 11.977 13.934 C 9.457 13.934 7.875 14.578 6.738 16.699 C 6.528 17.109 6.223 17.179 5.941 17.179 Z"
          fill={"white"}
        ></path>
      </motion.svg>
    );
  };

  const renderCheck = (item: any, i: number) => {
    return (
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        overflow={"visible"}
      >
        <path d="M 0 0 L 24 0 L 24 24 L 0 24 Z" fill="transparent"></path>
        <motion.path
          d="M 11.75 3 C 6.92 3 3 6.92 3 11.75 C 3 16.58 6.92 20.5 11.75 20.5 C 16.58 20.5 20.5 16.58 20.5 11.75 C 20.5 6.92 16.58 3 11.75 3 Z M 11.93 14.15 L 10.47 15.61 L 8.96 14.1 L 6.82 11.96 L 8.28 10.5 L 10.42 12.64 L 15.19 7.87 L 16.7 9.38 Z"
          fill={Styled.theme[theme][context]}
          variants={Styled.theme[theme][context]}
          whileHover={setHover(i)}
          initial={theme}
          transition={hovered === -1 ? trans.themeChange : trans.spring}
          animate={i === index ? { fill: color.green500 } : setState(i, item)}
          onClick={() => handleClick(i)}
        ></motion.path>
      </motion.svg>
    );
  };

  const createPages = () => {
    if (!items) return;
    return items.map((item, i) => {
      return (
        <Styled.Contain
          key={item + "_" + i}
          onMouseOver={() => setHovered(i)}
          onMouseOut={() => setHovered(-1)}
        >
          <Styled.Caption onClick={() => handleClick(i)}>
            <Styled.Title $show={i === hovered} $disabled={i > progress}>
              {item}
            </Styled.Title>
          </Styled.Caption>
          <Styled.BulletContainer>
            {i === items.length - 1
              ? showcheck
                ? renderCheck(item, i)
                : renderExitArrow(item, i)
              : renderBullet(item, i)}
          </Styled.BulletContainer>
        </Styled.Contain>
      );
    });
  };

  return (
    <Styled.Wrapper
      initial={{ right: 40 }}
      animate={{ right: right }}
      transition={{ ...trans.easIn, duration: 0.25 }}
    >
      {createPages()}
    </Styled.Wrapper>
  );
}
