import { useSignUp } from "../../../../contexts/SignUp";
import ProgressCheck from "../../../ProgressCheck/ProgressCheck";
import * as Styled from "./Styles";

export function CreatingAccount() {
  const { setPageIndex, pageIndex } = useSignUp();
  return (
    <Styled.QuestionWrapper style={{ justifyContent: "center", alignItems: "center" }}>
      <ProgressCheck playing={true} progressEnd={() => setPageIndex(pageIndex + 1)} />
    </Styled.QuestionWrapper>
  );
}
