import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const FieldContainer = styled.div<{ $fill: boolean }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 0; /* grow and shrink prop. */
  max-width: 100%;
  min-width: ${(p) => (p.$fill ? "100%" : "40%")};
  gap: 0px;
`;

export const ErrorMessage = styled.div<{ $show: boolean }>`
  box-sizing: border-box;
  ${tokens.type.pSmall};
  color: ${tokens.color.error};
  position: absolute;
  bottom: -38px;
  left: 0px;
  right: 0px;
  display: ${(p) => (p.$show ? "block" : "none")};
  z-index: ${(p) => (p.$show ? 10 : 0)};
  background-color: ${tokens.color.errorBG};
  margin: 0;
  height: 34px;
  padding: 8px 12px;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FieldName = styled.p`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
`;
