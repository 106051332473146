import styled from "styled-components";
import { color, type } from "../../pages/tokens";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 32px;
  width: 100%;
  overflow-y: hidden;
  padding: 11px 16px 11px 16px;
  border: 0;
  border-radius: 8px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const TextArea = styled.textarea<{ $size: "regular" | "small" | "tiny" }>`
  ${(p) => (p.$size === "regular" ? type.pMedium : type.pSmall)};
  padding: 0;
  width: 100%;
  overflow-y: hidden;
  resize: none;
  border: 0;
  outline: none;
  color: ${color.graysTextPrimary};
  background: transparent;
`;
