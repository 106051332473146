import * as Styled from "./Styles";
import { useState, useEffect } from "react";
import { useAnimationControls } from "framer-motion";
import { motion as trans } from "../../pages/tokens";
import Icon, { ico } from "../../assets/images/Icon";

type Size = {
  width?: number | string;
  height?: number | string;
};

interface Props {
  onChange?: (value: string) => void;
  didValidate?: (state: boolean) => void;
  placeholder?: string;
  name?: string;
  isValid?: boolean;
  value?: string;
  state?: "blurred" | "focused" | "disabled" | "invalid";
  required?: boolean;
  size?: Size;
}

export default function TextArea(props: Props) {
  const {
    placeholder = "",
    name = "textarea",
    onChange = () => null,
    didValidate = () => null,
    value = "",
    isValid = true,
    state = "blurred",
    required = false,
    size = { height: 60 },
  } = props;
  const [text, setText] = useState(value);
  const [currentState, setCurrentState] = useState(state);
  const [valid, setValid] = useState(isValid);
  const [isInitialized, setIsInitialized] = useState(false);
  const controls = useAnimationControls();
  useEffect(() => setText(value), [value]);
  useEffect(() => {
    controls.start(currentState);
  }, [currentState, controls]);

  const handleChange = (input: string) => {
    setText(input);
    onChange(input);
    let isvalid = required && input === "" ? false : true;
    setCurrentState(isvalid ? "focused" : "invalid");
    setValid(isvalid);
    didValidate(isvalid);
  };

  const handleFocus = (input: string) => {
    if (!isInitialized) setIsInitialized(true);
    setCurrentState("focused");
  };

  const handleBlur = (input: string) => {
    didValidate(true);
    setCurrentState("blurred");
    let isvalid = required && input === "" ? false : true;
    setCurrentState(isvalid ? "blurred" : "invalid");
    setValid(isvalid);
    didValidate(isvalid);
  };

  return (
    <Styled.Wrapper
      $size={size}
      variants={Styled.parentvariants}
      initial={"blurred"}
      transition={trans.easeInOut}
      animate={controls}
    >
      <Styled.ErrorIcon $show={!valid && isInitialized}>
        <Icon name={ico.warning} theme={"error"} />
      </Styled.ErrorIcon>
      <Styled.TextArea
        name={name}
        value={text ? text : ""}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        onFocus={(e) => handleFocus(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        variants={Styled.childvariants}
        initial={"blurred"}
        transition={trans.easeInOut}
        animate={controls}
      />
    </Styled.Wrapper>
  );
}
