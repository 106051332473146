import styled from "styled-components";
import { color } from "../../pages/tokens";
import { motion } from "framer-motion";

export const FABContainer = styled(motion.div)`
  position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  background-color: white;
  bottom: 40px;
  right: 40px;
  border: 1px solid rgba(227, 232, 237, 1);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
`;

export const Badge = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  color: ${color.graysWhite};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  background-color: red;
  top: 0px;
  right: 0px;
  transform: translateX(15%) translateY(-15%);
`;
