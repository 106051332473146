import * as Styled from "./Styles";
import { ButtonType } from "../../SurveyButton/SurveyButton";
import NavButton, { State } from "../../NavButton/NavButton";
import ResponseTemplate from "../ResponseTemplate/ResponseTemplate";
import UIButton from "../../UIButton/UIButton";
import { ico } from "../../../assets/images/Icon";

type Info = {
  title: string;
  text: string;
};

type Size = {
  width?: number | string;
  height?: number | string;
};

type Response = {
  options?: any;
  selected?: boolean[];
  subtitle?: string;
  description?: string;
  buttontype?: ButtonType;
  multiselect?: boolean;
  fieldname?: string;
  placeholder?: string;
  control?: "buttonlist" | "textarea";
  size?: Size;
  theme?: "blue" | "orange";
  onChange?: (name: string, values: any[]) => void;
  showMore?: () => void;
  didValidate?: (isValid: boolean) => void;
  clickInfo?: (info: Info) => void;
};

interface Props {
  onChange?: (name: string, values: any) => void;
  navigate?: (direction: number) => void;
  showMore?: (name: string) => void;
  didValidate?: (valid: boolean) => void;
  clickInfo?: (info: Info) => void;
  responses?: Response[];
  control?: "buttonlist" | "textarea";
  title?: string;
  info?: Info;
  description?: string;
  required?: "none" | "all" | "any" | boolean[];
  values?: [];
  theme?: "white" | "blue" | "orange" | string;
}

export default function Question(props: Props) {
  const {
    onChange = () => null,
    navigate = () => null,
    showMore = () => null,
    clickInfo = () => null,
    responses = null,
    title = "title",
    description = null,
    required = "any",
    values = null,
    info = null,
    theme = "white",
  } = props;

  const isValid = (valuesdata: any) => {
    if (!valuesdata || !valuesdata[0]) return false;
    if (required === "none") return true;
    let valid = null as boolean;
    valuesdata.map((item: any, i: number) => {
      if (required === "any" && valid === true) return null;
      if (item instanceof Array) {
        // check button row (booelan) for at least one true
        valid = item.find((val: Boolean) => val) !== undefined;
      } else {
        // check form field os not empty
        valid = item !== "";
      }
      return null; // .map requires return value
    });
    return valid;
  };

  const setValues = (value: [] | string, i: number) => {
    if (value && value instanceof Array) {
      return value[i];
    }
    if (value && value! instanceof Array) {
      return value;
    }
    return null;
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>
        {description}
        <MoreInfoButton info={info} showInfo={(info) => clickInfo(info)} />
      </Styled.Description>
      <Styled.Responses>
        {responses &&
          responses.map((item, i) => {
            return (
              <ResponseTemplate
                key={item.fieldname + "_" + i}
                subtitle={item.subtitle}
                description={item.description}
                options={item.options}
                values={setValues(values, i)}
                multiselect={item.multiselect}
                fieldname={item.fieldname}
                placeholder={item.placeholder}
                buttontype={item.buttontype}
                control={item.control}
                size={item.size}
                onChange={(name: string, values: boolean[] | string) => {
                  onChange(name, values);
                }}
                showMore={(name: string) => showMore(name)}
                clickInfo={(info) => clickInfo(info)}
              />
            );
          })}
      </Styled.Responses>
      <Navigation theme={theme} isValid={isValid(values)} navigate={(i) => navigate(i)} />
    </Styled.QuestionWrapper>
  );
}

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | string;
  navigate?: (direction: number) => void;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { theme = "white", navigate = () => null, isValid = false } = props;
  return (
    <Styled.NavButtons>
      <NavButton label={"Back"} theme={theme} onClick={() => navigate(-1)} />
      <NavButton
        theme={theme}
        state={isValid ? State.active : State.disabled}
        showicon={false}
        label={"Next"}
        length={200}
        onClick={() => (isValid ? navigate(1) : null)}
      />
    </Styled.NavButtons>
  );
};

/**
 * More Info Button
 */
interface Props {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: Props) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};
