import * as Styled from "./Styles";
import NavButton from "../../../NavButton/NavButton";
import UIButton from "../../../UIButton/UIButton";
import { ico } from "../../../../assets/images/Icon";
import FormField from "../../../FormField/FormField";
import { useState } from "react";
import usePageStore from "../../../../callbacks/usePageData";
import { useUserInfo } from "../../../../contexts/UserInfo";
import { useGIA } from "../../../../contexts/GIAContext";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";
import { shortIntro } from "../../../GIADrawer/GIAData";
import { useSignUp } from "../../../../contexts/SignUp";
import { useNavigate } from "react-router-dom";

const initialValidation = {
  name: false,
  phone: false,
  email: false,
};

type Info = {
  title: string;
  text: string;
};

const info = {
  title: "Don't share your account information",
  text: "Employee data is personal and often sensitive in nature.\n\nNever share you credentials and login information with colleagues or other people in your organization. You can always provide them with their own credentials as needed.",
};

interface Props {
  onChange?: (values: any[]) => void;
  didValidate?: (valid: boolean) => void;
  clickInfo?: (info: Info) => void;
  context?: "quote" | "contact";
}

export default function AcountInfo(props: Props) {
  const { didValidate = () => null } = props;
  const { showGIAMessages, setShowGIAMessages, lastMessage, setLastMessage } = useGIA();
  const { setChatGTPThinking, messages, setMessages } = useGIA();
  const { userData, setUserData } = useUserInfo();
  const [valid, setValid] = useState(userData.contactInfo ? true : false);
  const [validation, setValidation] = usePageStore(initialValidation);

  /** displays info panel based on the selected information icon */
  const showInfo = (info: Info) => {
    if (!showGIAMessages) setShowGIAMessages(true);
    const newMessage: ChatMessage = {
      isResponding: false,
      message: { title: info.title, text: info.text },
      sender: MessageSender.GIA,
    };
    if (lastMessage && lastMessage.message.text === newMessage.message.text) return;
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) setMessages([...shortIntro, newMessage]);
      else setMessages([...messages, newMessage]);
      setLastMessage(newMessage);
    }, 1000);
  };

  const handleDidValidate = (fieldname: string, state: any) => {
    let isvalid = true;
    if (!state) isvalid = false;
    Object.keys(validation).forEach((key) => {
      if (key !== fieldname) {
        if (!validation[key]) isvalid = false;
      }
    });
    setValidation({ [fieldname]: state });
    setValid(isvalid);
    didValidate(isvalid);
  };

  const handleChange = (fieldname: string, value: any) => {
    setUserData({ contactInfo: { ...userData.contactInfo, [fieldname]: value } });
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Create your G-P Account</Styled.Title>
      <Styled.Description>
        You'll use this information to login and authenticate with G-P.
        <MoreInfoButton info={info} showInfo={(info) => showInfo(info)} />
      </Styled.Description>

      <Styled.FormWrapper>
        <FormField
          label="Full name"
          fieldname="name"
          type={"text"}
          value={userData ? userData.contactInfo?.name : ""}
          onChange={(value) => handleChange("name", value)}
          didValidate={(state) => handleDidValidate("name", state)}
          errormessage={"Please enter your full name."}
        />
        <FormField
          label="Phone number"
          fieldname="phone"
          type={"phone"}
          value={userData ? userData.contactInfo?.phone : ""}
          onChange={(value) => handleChange("phone", value)}
          didValidate={(state) => handleDidValidate("phone", state)}
          errormessage={"Please enter a valid phone number."}
        />
        <FormField
          label="Company Email"
          fieldname="company_email"
          type={"email"}
          value={userData ? userData.contactInfo?.email : ""}
          onChange={(value) => handleChange("email", value)}
          didValidate={(state) => handleDidValidate("email", state)}
          errormessage={"Please enter a valid company email."}
        />
      </Styled.FormWrapper>
      <Styled.SubTitle>
        Note: we'll send this email and link to confirm account activation.
      </Styled.SubTitle>
      <Navigation theme={"blue"} isValid={valid} />
    </Styled.QuestionWrapper>
  );
}

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | string;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { theme = "white" } = props;
  const { setPageIndex, pageIndex } = useSignUp();
  const navigate = useNavigate();
  return (
    <Styled.NavButtons>
      <NavButton
        theme={"white"}
        showicon={false}
        label={"Cancel"}
        length={125}
        onClick={() => navigate("/get-started")}
      />
      <NavButton
        theme={theme}
        showicon={false}
        label={"Next"}
        length={200}
        onClick={() => setPageIndex(pageIndex + 1)}
      />
    </Styled.NavButtons>
  );
};

/**
 * More Info Button
 */

interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};
