import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const InfoPanel = styled.div<{ $visible: boolean }>`
  position: absolute;
  box-sizing: border-box;
  background-color: ${tokens.color.graysWhite};
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  overflow: hidden;
  ${tokens.type.pRegular};
  border-left: 1px solid ${(p) => (p.$visible ? tokens.color.grays200 : "transparent")};
  color: ${tokens.color.graysTextPrimary};
`;

export const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin: 0;
  padding: 0;
  width: 100%;
  color: ${tokens.color.graysTextPrimary};
  height: 100%;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin: 0;
  padding: 16px;
  flex: 1;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;
    border-radius: 20px;
    border: 5px solid white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
    border-radius: 20px;
    border: 5px solid white;
  }
`;

export const Section = styled.div`
  ${tokens.type.sectionTitle};
  position: sticky;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  width: 100%;
  margin: 0;
  padding: 0 32px;
  padding-top: 4px;
  width: 100%;
  border-bottom: 1px solid ${tokens.color.grays200};
`;

export const ButtonGroup = styled.div<{ $align: "left" | "right" }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: ${(p) => (p.$align === "left" ? "flex-start" : "flex-end")};
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 8px;
  flex: 1;
`;

export const Title = styled.div`
  ${tokens.type.pSmall};
  ${tokens.type.pBoldFace};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  padding-top: 4px;
  min-width: max-content;
  text-transform: none;
`;

export const Text = styled.p`
  ${tokens.type.pMedium};
  margin: 0px 0px 16px 0px;
  padding: 0px;
  opacity: 0.7;
  white-space: pre-wrap;
`;

export const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 16px;
`;
