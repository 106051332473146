import PageHero from "../../PageHero/PageHero";

interface Props {
  navigate?: (direction: number) => void;
}

export default function QuoteHero(props: Props) {
  const { navigate = () => null } = props;
  return (
    <PageHero
      context={"quote"}
      theme={"blue"}
      section={"Configuration and Pricing"}
      title={"Configure the solution that's right for you."}
      text={
        "With just a few questions, we'll help configure and price a solution that's right for you."
      }
      listitems={[
        "Select your base service level",
        "Choose extended service add-on's",
        "Pick unique employee support options",
        "Get an approximate cost estimate",
      ]}
      navigate={(i) => navigate(i)}
    />
  );
}
