import styled from "styled-components";
import { motion } from "framer-motion";

export const Carousel = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 32px;
`;

export const MotionCard = styled(motion.div)`
  position: absolute;
  bottom: 0px;
`;

export const CardWrapper = styled(motion.div)`
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  height: 200px;
`;
