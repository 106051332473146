import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const Switch = {
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "What is prompting the switch from your current EOR?",
      alt: "Why switch?",
      description:
        "We want to make sure our recommendation addressed any of the gaps you're experiencing with your current provider.",
      template: "question",
      responses: [
        {
          options: Data.reasonstoswitch,
          fieldname: "reasonstoswitch",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "In which country or countries have you been using your current EOR?",
      alt: "Countries",
      description:
        "Some countries have unique legal and compliance requirements that may require specific attention and services.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many employees are currently managed under your existing EOR?",
      alt: "How many employees",
      description:
        "Depending on the number of contractors that need to be converted, you may have access to size discounts and benefits.",
      responses: [
        {
          options: Data.numberof,
          fieldname: "employees",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What is you timeline for completing the switch from your current provider?",
      alt: "Timeline",
      description:
        "How quickly you need to transition employees may require specific legal, HR and compliance support to help expedite the process.",
      responses: [
        {
          options: Data.timeframe,
          fieldname: "timeframe",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What are your priorities and areas of focus as you make this switch in provider?",
      alt: "Priorities",
      description:
        "We'll make sure your proposed configuration and pricing emphasizes supporting those areas that you feel are most critical to your business.",
      responses: [
        {
          options: Data.priorities,
          fieldname: "priorities",
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};

export default Switch;
