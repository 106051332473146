import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  margin: 0px 80px;
  padding: 48px 0;
  gap: 24px;
  flex: 1 0 0;
`;

export const Title = styled.p`
  ${tokens.type.h2};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
  text-align: center;
`;

export const Description = styled.div`
  ${tokens.type.pRegular};
  color: ${tokens.color.graysTextTertiary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 32px;
  max-width: 550px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 0px;
  padding: 0px;
`;
