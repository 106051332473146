import { createContext, useContext, useState } from "react";

const SignUpContext = createContext(null);

interface Props {
  children?: any;
}

function SignUpProvider(props: Props) {
  const { children } = props;
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [didScrollTerms, setDidScrollTerms] = useState<boolean>(false);
  return (
    <SignUpContext.Provider value={{ pageIndex, didScrollTerms, setDidScrollTerms, setPageIndex }}>
      {children}
    </SignUpContext.Provider>
  );
}

function useSignUp() {
  const context = useContext(SignUpContext);
  return context;
}

export { SignUpProvider, useSignUp };
