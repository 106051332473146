import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";
import imgQuoteLrg from "../../assets/images/Thumbnail_Quote_Lrg@3x.png";
import imgQuoteSml from "../../assets/images/Thumbnail_Quote_Sml@3x.png";
import imgContactLrg from "../../assets/images/Thumbnail_Contact_Lrg@3x.png";
import imgContactSml from "../../assets/images/Thumbnail_Contact_Sml@3x.png";

interface Props {
  type: "quote" | "contact";
}

const smallImage = (type: string) => {
  return type === "quote" ? imgQuoteSml : imgContactSml;
};

const largeImage = (type: string) => {
  return type === "quote" ? imgQuoteLrg : imgContactLrg;
};

export const MotionButton = styled(motion.div)`
  display: flex;
  flex-direction: flex-row;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${tokens.color.grays400};
  cursor: pointer;
  @media screen and ${tokens.device.tabletOnly} {
    flex-direction: column;
    align-items: center;
    max-width: 180px;
    height: 224px;
  }
  @media screen and ${tokens.device.tabletUp} {
    flex-direction: column;
    align-items: center;
    width: 180px;
    height: 240px;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const Image = styled.div<Props>`
  width: 100px;
  height: 75px;
  display: block;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => smallImage(props.type)});
  @media screen and ${tokens.device.phoneUp} {
    background-image: url(${(props) => largeImage(props.type)});
    width: 150px;
    height: 125px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media screen and ${tokens.device.tabletUp} {
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  color: ${tokens.color.graysTextPrimary};
  @media screen and ${tokens.device.phoneUp} {
    align-items: flex-end;
    width: auto;
    flex: auto;
  }
  @media screen and ${tokens.device.tabletUp} {
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const Text = styled.p`
  ${tokens.type.pRegular};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Glyph = styled.span`
  ${tokens.type.pGlyph};
  color: ${tokens.color.primaryGPBlue};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
