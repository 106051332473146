import styled from "styled-components";
import * as tokens from "../../../pages/tokens";
import imgDoneDark from "../../images/done_dark@16_dark.svg";
import imgDoneLight from "../../images/done_dark@16_light.svg";

export const ValueProp = styled.div`
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  @media screen and ${tokens.device.tabletUp} {
    gap: 0px;
  }
`;

export const Logo = styled.div`
  padding-bottom: 64px;
  display: none;
  @media screen and ${tokens.device.tabletUp} {
    display: inherit;
  }
`;

export const Title = styled.h3`
  ${tokens.type.pRegular};
  @media screen and ${tokens.device.tabletUp} {
    margin-bottom: 16px;
  }
`;

export const SubTitle = styled.p`
  ${tokens.type.pSmall};
  padding: 0;
  margin: 0;
  margin-bottom: 28px;
  text-transform: uppercase;
`;

export const Header = styled.h3`
  ${tokens.type.h1};
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  font-weight: 400;
`;

export const List = styled.ul`
  ${tokens.type.pRegular}
  margin: 0;
  padding: 0;
  opacity: 0.8;
`;

export const ListItem = styled.li<{ $dark: boolean }>`
  ${tokens.type.pRegular}
  ${tokens.color.graysWhite}
  list-style-position: outside;
  list-style-image: url(${(p) => (p.$dark ? imgDoneDark : imgDoneLight)});
  list-style-type: circle;
  margin-left: 20px;
  margin-top: 8px;
  line-height: 1.2em;
  padding: 0;
`;
