import styled from "styled-components";
import { motion } from "framer-motion";
import * as tokens from "../../pages/tokens";

export const iconSizes = {
  small: { size: 16, stroke: 1 },
  regular: { size: 24, stroke: 1.5 },
  medium: { size: 32, stroke: 1 },
  large: { size: 40, stroke: 1 },
  xlarge: { size: 64, stroke: 0.6 },
};

export const themeColors = {
  white: {
    color: tokens.color.graysTextPrimary,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: tokens.color.grays200,
  },
  blue: {
    color: tokens.color.graysWhite,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: tokens.color.graysWhite25,
  },
  orange: {
    color: tokens.color.graysTextPrimary,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: tokens.color.warning300a30,
  },
  blueLink: {
    color: tokens.color.primaryGPBlue,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "rgba(0,0,0,0)",
  },
  whiteLink: {
    color: tokens.color.graysSystem,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "rgba(0,0,0,0)",
  },
  skyLink: {
    color: tokens.color.graysWhite50,
    backgroundColor: tokens.color.graysWhite10,
    borderColor: tokens.color.graysWhite50,
  },
  system: {
    color: tokens.color.graysTextTertiary,
    backgroundColor: tokens.color.graysWhite100,
    borderColor: tokens.color.graysWhite100,
  },
};

interface Props {
  color?: string;
  type?: string;
  theme?: string;
  size?: string;
}

const setPadding = (type: string, size: string) => {
  switch (type) {
    case "unframed":
    case "link":
      return 0;
    case "tertiary":
      if (size === "large") return "16px 16px";
      return "8px 12px";
    default:
      return "0px 20px 0px 16px";
  }
};

const setHeight = (type: string) => {
  switch (type) {
    case "unframed":
    case "link":
    case "tertiary":
      return "auto";
    default:
      return "40px";
  }
};

export const Button = styled(motion.div)<Props>`
  ${tokens.type.btnMedium};
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(p) => (p.type === "link" ? "row-reverse" : "row")};
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  border-radius: ${(props) => (props.type === "tertiary" ? "4px" : "100px")};
  padding: ${(props) => setPadding(props.type, props.size)};
  height: ${(props) => setHeight(props.type)};
  background-color: "rgba(0,0,0,0)";
  border-width: 1px;
  border-style: ${(props) => (props.type === "unframed" ? "none" : "solid")};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: ${(props) => (props.type === "link" ? "underline" : "none")};
  }
`;
