import Icon, { ico } from "../../assets/images/Icon";
import * as Styled from "./Styles";
import GIAMessages from "../GIADrawer/GIAMessages";
import GIAIcon from "../../assets/images/GIAIcon";
import InputMessage from "../InputMessage/InputMessage";
import { useGIA } from "../../contexts/GIAContext";
import { ChatMessage, LiveConnection } from "../EnumsAndTypes/EnumsAndTypes";
import { useLayoutEffect, useRef } from "react";
import camila from "../../assets/images/camila@2x.png";
import { TertiaryButton } from "../TertiaryButton/TertiaryButton";

type Info = {
  title: string;
  text: string;
};

interface Props {
  title?: string;
  text?: string;
  visible?: boolean;
  didClose?: (show: boolean, info: Info) => void;
}

export default function InfoPanel(props: Props) {
  const { visible = false } = props;
  const { handleSendMessage, messages, chatGTPThinking, connected } = useGIA();
  const ref = useRef(null);

  useLayoutEffect(() => {
    let timer = null;
    if (!ref) return;
    timer = setTimeout(() => {
      ref?.current.scrollTo(0, ref.current.scrollHeight);
    }, 300);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [messages, chatGTPThinking]);

  const setPlaceHolder = () => {
    if (connected === LiveConnection.Connected) return "Message Camila";
    if (connected !== LiveConnection.Connected) return "How can I help?";
  };

  return (
    <Styled.InfoPanel $visible={visible}>
      <Styled.Wrapper>
        {connected === LiveConnection.Disconnected && <GIAHeader />}
        {connected !== LiveConnection.Disconnected && <LiveAgentHeader />}
        <Styled.Content ref={ref}>
          <GIAMessages />
        </Styled.Content>
        <Styled.Footer>
          <InputMessage
            onSendMessage={(message: ChatMessage) => handleSendMessage(message)}
            placeholder={setPlaceHolder()}
          />
        </Styled.Footer>
      </Styled.Wrapper>
    </Styled.InfoPanel>
  );
}

const GIAHeader = () => {
  const { setShowGIAMessages } = useGIA();
  return (
    <Styled.Section>
      <Styled.ButtonGroup $align={"left"}>
        <Icon name={ico.history} size={20} stroke={2} theme={"system"} />
      </Styled.ButtonGroup>
      <Styled.Title>
        <GIAIcon height={18} />
        GIA
      </Styled.Title>
      <Styled.ButtonGroup $align={"right"}>
        <Icon name={ico.dotsVertical} size={20} stroke={2} theme={"system"} />
        <Icon name={ico.x} size={28} theme={"white"} onClick={() => setShowGIAMessages(false)} />
      </Styled.ButtonGroup>
    </Styled.Section>
  );
};

const LiveAgentHeader = () => {
  const { disconnectLiveAgent, connected } = useGIA();
  const setConnectingText = () => {
    if (connected === LiveConnection.Connected) return "Camila";
    if (connected === LiveConnection.Connecting) return "Connecting ...";
    if (connected === LiveConnection.Disconnecting) return "Disconnecting ...";
  };
  return (
    <Styled.Section>
      <Styled.ButtonGroup $align={"left"}>
        <img src={camila} width={32} height={32} alt={"profile"} />
        <Styled.Title>{setConnectingText()}</Styled.Title>
      </Styled.ButtonGroup>
      <Styled.ButtonGroup $align={"right"}>
        <TertiaryButton label={"disconnect"} onClick={() => disconnectLiveAgent()} />
      </Styled.ButtonGroup>
    </Styled.Section>
  );
};
