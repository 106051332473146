import PageHero from "../PageHero/PageHero";

interface Props {
  afterhours?: boolean;
  navgiate?: (i: number) => void;
  showCallBackModal?: (show: boolean) => void;
}

export default function ContactHero(props: Props) {
  const { afterhours = false, navgiate = () => null, showCallBackModal = () => null } = props;

  return (
    <PageHero
      afterhours={afterhours}
      context={"contact"}
      theme={"orange"}
      section={"Talk with an Expert"}
      title={"Talk with a globalization expert about your needs."}
      text={
        "Our team of globalization experts is available to talk about your goals and work with you to:"
      }
      listitems={[
        "Understand your unique needs and objectives",
        "Configure and price the ideal solution",
        "Provide a live demo and walk-through",
        "Answer questions and clarify pricing",
      ]}
      navigate={(n: number) => {
        if (n === 0) showCallBackModal(true);
        if (n === 1) navgiate(1);
      }}
    />
  );
}
