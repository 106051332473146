import { useEffect, useState } from "react";
import * as Styled from "./Styles";
import { useAnimationControls } from "framer-motion";
import UIButton from "../UIButton/UIButton";

interface ButtonProps {
  label?: string;
  state?: "disabled" | "active" | "selected" | "pressed" | "hovered";
  didSelect?: (state: boolean) => void;
  navigate?: (i: number) => void;
}
export default function TimeSlotButton(props: ButtonProps) {
  const {
    label = "label",
    state = "active",
    didSelect = () => null,
    navigate = () => null,
  } = props;
  const [buttonState, setButtonState] = useState(state);
  const controls = useAnimationControls();
  useEffect(() => {
    setButtonState(state);
  }, [state]);
  const handleClick = () => {
    if (buttonState === "disabled") return;
    setButtonState(buttonState === "active" ? "selected" : "active");
    controls.start(buttonState === "active" ? "selected" : "active");
    didSelect(buttonState === "active" ? false : true);
  };

  return (
    <Styled.Wrapper>
      <Styled.TimeSlotButton
        initial={buttonState}
        variants={Styled.variants}
        animate={controls}
        onClick={() => handleClick()}
        whileHover={buttonState !== "disabled" ? "hovered" : null}
        whileTap={buttonState !== "disabled" ? "pressed" : null}
        tabIndex={0}
      >
        {label}
      </Styled.TimeSlotButton>
      <Styled.Confirmation $show={buttonState === "selected"}>
        <UIButton
          showicon={false}
          type={"tertiary"}
          theme={"orange"}
          label="Cancel"
          size={"large"}
          onClick={() => handleClick()}
        />
        <UIButton
          showicon={false}
          type={"tertiary"}
          theme={"orange"}
          label={"Confirm"}
          size={"large"}
          onClick={() => navigate(1)}
        />
      </Styled.Confirmation>
      <Styled.Carret $show={buttonState === "selected"} />
    </Styled.Wrapper>
  );
}
