import * as Styled from "./Styles";
import { useState, useRef, useEffect } from "react";
import Icon, { ico } from "../../assets/images/Icon";
import { useAnimationControls } from "framer-motion";
import * as tokens from "../../pages/tokens";

interface Props {
  value?: string;
  name?: string;
  placeholder?: string;
  theme?: "framed" | "unframed" | "white" | "clear";
  type?: "text" | "email" | "phone";
  passsord?: boolean;
  isValid?: boolean;
  required?: boolean;
  state?: "blurred" | "focused" | "disabled" | "invalid";
  leftIcon?: boolean;
  leftIconName?: ico;
  rightIcon?: boolean;
  rightIconName?: ico;
  errorIconName?: ico;
  width?: string | number;
  fill?: boolean;
  onChange?: (value: string) => void;
  onKeyDown?: (key: string) => void;
  didValidate?: (valid: boolean) => void;
  didBlur?: (state: boolean) => void;
  onFocus?: () => void;
}
export default function InputField(props: Props) {
  const {
    value = "",
    placeholder = "",
    theme = "framed",
    name = "textinput_0",
    type = "text",
    isValid = true,
    state = "blurred",
    leftIcon = false,
    required = true,
    leftIconName = ico.search,
    rightIcon = false,
    rightIconName = ico.eye,
    errorIconName = ico.warning,
    width = "100%",
    fill = false,
    passsord = false,
    onChange = () => null,
    onKeyDown = () => null,
    didValidate = () => null,
    didBlur = () => null,
    onFocus = () => null,
  } = props;
  const [text, setText] = useState(value);
  const [valid, setValid] = useState(isValid);
  const [isInitialized, setIsInitialized] = useState(false);
  const inputRef = useRef(null);
  const controls = useAnimationControls();

  useEffect(() => {
    if (inputRef?.current) inputRef.current.value = value;
    setText(value);
  }, [value]);

  const updateStyle = (tostyle: any) => {
    controls.start(tostyle);
  };

  const validateField = (input: string) => {
    const isvalid = validateValue(input);
    setValid(isvalid);
    return isvalid;
  };

  const handleChange = (input: string) => {
    setText(formatValue(input));
    onChange(formatValue(input));
    const isvalid = validateField(input);
    didValidate(isvalid);
    updateStyle(isvalid || input === "" ? "focused" : "invalid");
  };

  const handleKeyDown = (key: string) => {
    onKeyDown(key);
    if (key !== "Enter") return;
    if (inputRef && inputRef.current) {
      inputRef.current.blur();
      const isvalid = validateField(inputRef.current.value);
      didValidate(isvalid);
      updateStyle(isvalid ? "blurred" : "invalid");
      didBlur(true);
    }
  };

  const handleBlur = (input: string) => {
    const isvalid = validateField(input);
    didValidate(isvalid);
    updateStyle(isvalid ? "blurred" : "invalid");
    didBlur(true);
  };

  const handleFocus = (input: string) => {
    if (!isInitialized) setIsInitialized(true);
    updateStyle(valid || input === "" ? "focused" : "invalid");
    didBlur(false);
    onFocus();
  };

  const showError = () => {
    if (!isInitialized) return false;
    if (!valid) return true;
    return false;
  };

  const validateValue = (input: string) => {
    if (!required) return true;
    switch (type) {
      case "phone":
        return validatePhone(input) ? true : false;
      case "email":
        return validateEmail(input) ? true : false;
      case "text":
        return input !== "" ? true : false;
      default:
        return input === "" ? false : true;
    }
  };

  function formatValue(value: string) {
    switch (type) {
      case "phone":
        return formatPhone(value);
      default:
        return value;
    }
  }

  return (
    <Styled.FieldWrapper
      $showicon={leftIcon}
      $theme={theme}
      $width={width}
      $fill={fill}
      variants={Styled.variants[theme]}
      initial={state}
      transition={tokens.motion.easeInOut}
      animate={controls}
    >
      <Styled.LeftIcon $show={leftIcon}>
        <Icon name={leftIconName} theme={"white"} />
      </Styled.LeftIcon>
      <Styled.InputField
        $password={passsord && text !== ""}
        ref={inputRef}
        placeholder={placeholder}
        name={name}
        type={passsord ? "password" : "text"}
        value={text ? text : ""}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        onFocus={(e) => handleFocus(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e.key)}
      />
      <Styled.RightIcon $show={rightIcon}>
        <Icon name={rightIconName} theme={"white"} />
      </Styled.RightIcon>
      <Styled.ErrorIcon $show={showError()}>
        <Icon name={errorIconName} theme={"error"} />
      </Styled.ErrorIcon>
    </Styled.FieldWrapper>
  );
}

// formats string to phone number format
function formatPhone(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

// validate phone
function validatePhone(phone: string) {
  return (
    String(phone)
      .toLowerCase()
      // eslint-disable-next-line
      .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
  );
}

// validate email
function validateEmail(email: string) {
  return (
    String(email)
      .toLowerCase()
      // eslint-disable-next-line
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
}
