import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 0;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 64px;
  overflow: auto;
  justify-content: center;
`;
