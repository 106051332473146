import { MessageSender, NextAction } from "../EnumsAndTypes/EnumsAndTypes";

//** List of functions describing when they should be used **//
export const functionList = [
  {
    name: "how_quickly_can_you_onboard",
    description:
      "Run this function if the users asks how quickly we can get an employee on boarded",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "employee_relocating_spain",
    description:
      "Run this function if the users asks how they can get started with an employee that is relocating to spain",
    parameters: { type: "object", properties: {} },
  },
  {
    name: "create_account",
    description: "Run this function the user conforms that they want to creat an account.",
    parameters: { type: "object", properties: {} },
  },
];

// ** Functions matching the function list that get rnu by chat GPT **//
export function how_quickly_can_you_onboard(parameters: any) {
  let response = {
    next: null,
    role: MessageSender.GIA,
    content:
      "We can typically onboard employees you already have in mind in a matter of days, just about anywhere in the world.",
  };
  return response;
}
export function employee_relocating_spain(parameters: any) {
  let response = {
    next: NextAction.signUp,
    role: MessageSender.GIA,
    content:
      "We can get you started now. You'll need about 5 minutes to create a\nG-P account and provide us with the details of the employee you're looking to onboard.\n\nNote: Once you create an account, if you don't onboard an employee within the first 30 days, we'll charge you a $100 monthly fee until you either cancel your account or onboard an employee.",
  };
  return response;
}
export function create_account(parameters: any) {
  let response = {
    next: NextAction.createAccount,
    role: MessageSender.GIA,
    content: "Great! Let me redirect you to where you can create your account.",
  };
  return response;
}
