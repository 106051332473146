import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  ${tokens.type.pSmall};
  text-decoration: none;
  text-transform: none;
  box-sizing: border-box;
  display: flex;
  justificy-content: center;
  aloign-items: center;
  padding: 6px 10px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid ${tokens.color.primaryGPBlue};
  color: ${tokens.color.primaryGPBlue};
  cursor: pointer;
`;
