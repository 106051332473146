import * as Styled from "./Styles";
import UIButton from "../UIButton/UIButton";
import { ico } from "../../assets/images/Icon";
import WeeklyCalendar from "../WeeklyCalendar/WeeklyCalendar";

type Info = {
  title: string;
  text: string;
};

const shceduleInfo = {
  title: "Meeting with or Global Experts",
  text: "Our global experts are available for an in person meeting to discuss your goals and needs.\n\nAll meetings are video enabled to allow for screen sharing - this makes demos, walk throughs and presenting materials mush easier.\n\nHaving a video meeting does not require you to turn on your camera however.",
};

interface Props {
  onChange?: (name: string, values: any) => void;
  navigate?: (direction: number) => void;
  showMore?: (name: string) => void;
  didValidate?: (valid: boolean) => void;
  clickInfo?: (info: Info) => void;
  title?: string;
  info?: Info;
  description?: string;
  theme?: "white" | "blue" | "orange" | string;
}
export default function ScheduleMeeting(props: Props) {
  const { navigate = () => null, clickInfo = () => null, info = shceduleInfo } = props;
  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Select a date and time to talk to a G-P expert.</Styled.Title>
      <Styled.Description>
        We'll scheudle a meeting and send you an invitation.
        <MoreInfoButton info={info} showInfo={(info) => clickInfo(info)} />
      </Styled.Description>
      <Styled.Responses>
        <WeeklyCalendar navigate={(i) => navigate(i)} />
      </Styled.Responses>
    </Styled.QuestionWrapper>
  );
}

/**
 * More Info Button
 */
interface Props {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: Props) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        onClick={() => showInfo(info)}
        type={"tertiary"}
      />
    </Styled.InfoButton>
  );
};
