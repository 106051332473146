import * as Styled from "./Styles";
import { motion as trans } from "../../pages/tokens";
import UIButton from "../UIButton/UIButton";
import { ico } from "../../assets/images/Icon";
import { Children } from "react";

type Size = {
  height?: number | string;
  width?: number | string;
};

interface Props {
  title?: string;
  subtitle?: string;
  show?: boolean;
  size?: Size;
  children?: any;
  cancelClick?: (withsave?: boolean) => void;
}
export default function ModalWindow(props: Props) {
  const {
    title = "hello",
    subtitle = null,
    size = null,
    cancelClick = () => null,
    children = null,
  } = props;

  const setContentChildren = (object: any) => {
    if (object.type.name === undefined) return object;
    if (object.type.name.toString().includes("ModalButton")) return null;
    return object;
  };

  return (
    <Styled.Container>
      <Styled.ModalFrame
        $size={size}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={trans.spring}
      >
        <Styled.Header>
          <Styled.CloseButton>
            <UIButton
              icon={ico.xCircle}
              type={"unframed"}
              size={"medium"}
              theme={"white"}
              onClick={() => cancelClick(false)}
            />
          </Styled.CloseButton>
          <Styled.Title>{title}</Styled.Title>
          <Styled.SubTitle $show={subtitle !== ""}>{subtitle}</Styled.SubTitle>
        </Styled.Header>
        <Styled.Content>
          {Children.map(children, (child) => {
            return setContentChildren(child);
          })}
        </Styled.Content>
      </Styled.ModalFrame>
    </Styled.Container>
  );
}
