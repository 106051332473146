import * as Styled from "./Styles";
import { Children } from "react";
import { AnimatePresence } from "framer-motion";
import { motion as trans } from "../../pages/tokens";
import ModalWindow from "../ModalWindow/ModalWindow";
import { useState, cloneElement } from "react";

type Size = {
  height?: number | string;
  width?: number | string;
};

interface Props {
  visible?: boolean;
  opacity?: number;
  title?: string;
  subtitle?: string;
  children?: any;
  size?: Size;
  clickOutsideCancels?: boolean;
  cancelClick?: (withsave?: boolean) => void;
}

export default function ModalOverlay(props: Props) {
  const {
    title = "title",
    subtitle = "subtitle",
    visible = false,
    opacity = 0.8,
    children = null,
    size = null,
    clickOutsideCancels = false,
    cancelClick = () => null,
  } = props;

  const [sub, setSub] = useState(subtitle);
  const updateTitle = (message: string) => {
    setSub(message);
  };

  return (
    <AnimatePresence initial={false}>
      {visible && (
        <>
          <ModalWindow
            cancelClick={(withsave: boolean) => cancelClick(withsave)}
            title={title}
            subtitle={sub}
            size={size}
            children={Children.map(children, (child) => {
              return cloneElement(child, {
                updateSubTitle: (message: string) => updateTitle(message),
              });
            })}
          />
          <Styled.Overlay
            $opacity={opacity}
            key={"overlay"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ...trans.easeInOut, duration: 0.15 }}
            onClick={() => (clickOutsideCancels ? cancelClick(false) : null)}
          />
        </>
      )}
    </AnimatePresence>
  );
}
