import { MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import * as Styled from "./Styles";

export function ThinkingBubble() {
  const variants = {
    initial: { opacity: 0, y: 50 },
    exit: { opacity: 0, transition: { duration: 0.15 } },
    animate: { opacity: 1, y: 0 },
  };

  const dotVariants = {
    on: { opacity: 1 },
    off: { opacity: 0 },
  };
  const transition = {
    ease: "linear",
    repeatType: "reverse",
    repeat: Infinity,
    duration: 0.5,
  } as any;

  return (
    <Styled.Wrapper variants={variants} initial={"initial"} animate={"animate"} exit={"exit"}>
      <Styled.Bubble $sender={MessageSender.GIA}>
        <Styled.Dots>
          <Styled.Dot
            $size={7}
            variants={dotVariants}
            initial={"off"}
            transition={{ ...transition, delay: 0 }}
            animate={"on"}
          />
          <Styled.Dot
            $size={7}
            variants={dotVariants}
            initial={"off"}
            transition={{ ...transition, delay: 0.2 }}
            animate={"on"}
          />
          <Styled.Dot
            $size={7}
            variants={dotVariants}
            initial={"off"}
            transition={{ ...transition, delay: 0.4 }}
            animate={"on"}
          />
        </Styled.Dots>
      </Styled.Bubble>
    </Styled.Wrapper>
  );
}
