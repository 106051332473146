import { cloneElement, useState, useEffect } from "react";
import * as tokens from "../../pages/tokens";
import * as Styled from "./Styles";
import { AnimatePresence } from "framer-motion";

interface Props {
  collection: any; // content object driving each card's content
  selected?: number; // avtive card
  transition?: any; // motion settings
  autoplay?: boolean; // move carousel automatically
  pause?: number; // pause length in seconds between auto play
  altfield?: string; // field to be used for accessibility title/alt
  itemTemplate: any; // component that will be used to dispkay the collection content
  paggingTemplate?: any; // component that will be used to display pagging
  paggingType?: "logo" | "bullet"; // use logo or bullet style pagging
  theme?: "dark" | "light";
}

/**
 * Creates website carousel. Takes a template (react component)
 * and a collection (data object) and animates through each item
 * in the collection using the component as the render template.
 * Note: pageType props can be set to define pagging style.
 * Pagging can be a regular bullet or a logo.
 */

export default function Carousel(props: Props) {
  // set default prop values where needed
  // always try to avoid having the define prop values
  // when a component is being droppped into another page
  const {
    collection = null,
    selected = 0,
    transition = tokens.motion.easeInOut,
    autoplay = true,
    altfield = null,
    pause = 6,
    itemTemplate = null,
    paggingTemplate = null,
    paggingType = "bullet",
    theme = "dark",
  } = props;

  // set state
  const [index, setIndex] = useState(selected); // active card
  const [items, setItems] = useState(collection); // card item collection
  const [size, setSize] = useState({ height: 0, width: 0 }); // card size (height/width)
  const [direction, setDirection] = useState(1); // transition direction
  const [isDragging, setIsDragging] = useState(false); // is card being dragged / swiped
  const [play, setPlay] = useState(false); // is carousel cycling on its own

  // update set on prop changes/updates
  useEffect(() => setIndex(selected), [selected]);
  useEffect(() => setItems(collection), [collection]);
  useEffect(() => setPlay(autoplay), [autoplay]);

  // trigger to auto cycle cards at defined interval
  useEffect(() => {
    if (play) {
      const interval = setInterval(() => {
        if (direction !== 1) setDirection(1);
        if (items && items.length - 1 > index) {
          setIndex(index + 1);
        } else {
          setIndex(0);
        }
      }, pause * 1000);
      return () => clearInterval(interval);
    }
  }, [play, direction, index, items, pause]);

  // set height of warpper to card height
  // card is absolute positioned so parent needs dimesions to size itself
  const sizeChange = (value: { height: number; width: number }) => {
    if (value.height && value.height !== size.height) {
      setSize(value);
    }
  };

  // animation variants that determine card transition
  // use custom to pass in the direction state
  // a negative (-1) moves cards in from left and out to right
  // a positive (+1) moves card in from right and out to left
  // play around with x: enter/exit values to get the right transition distance
  // depending on distance you need to set the transiton duration property
  // to make it feel smooth and natural
  const variants = {
    // card enter state
    enter: (direction: number) => {
      return {
        x: direction === 1 ? 500 : -500,
        opacity: 0,
      };
    },
    // animate to show card in this state
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    // exit card to this state
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction === 1 ? -500 : 500,
        opacity: 0,
      };
    },
  };

  // navigate to the requested item in the collection
  // if request is out of collection bounds restart
  const navigate = (page: number = null) => {
    if (page === index) return; // requested item is active already
    let dir = page <= index ? -1 : 1; // set direction based on requested item order
    if (items && items.length - 1 < page) {
      // reached end of collecation so reset to first item
      dir = 1;
      page = 0;
    }
    if (page < 0) {
      // reached the begging of the collection so go to last item
      dir = -1;
      page = items.length - 1;
    }
    setDirection(dir);
    setIndex(page);
  };

  // set the pagging data for carousel
  // uses the pageindicator component to display the pagging
  // if logo is indicator extract logo field from colleaction data
  const setPages = () => {
    if (items) {
      let pagging = [];
      const filter = paggingType !== "bullet" ? "logo" : altfield;
      const alt = filter ? true : false;
      items.map((item: any, i: number) => {
        pagging.push({ name: alt ? item[filter] : "" });
        return null;
      });
      return pagging;
    }
    return null;
  };

  // clone the temaplte item to be used as the card render
  // note that all templates for carousel must include
  // content: an object with the conentent required by the template
  // sizeChange: event with the height and width of the template
  const displayItem = () => {
    if (items && itemTemplate) {
      const element = cloneElement(itemTemplate, {
        content: items[index],
        sizeChange: (size: { height: number; width: number }) => sizeChange(size),
        theme: theme,
      });
      return element;
    }
    return null;
  };

  // ender the pagging bullets or logo based on the template slected
  // every pagging teamplte in this carousel will need the pages collection
  // and a selected prop to heighlight the appropriate page
  // optionally an onChange event will enable navigating using the page indicator
  const displayPagging = () => {
    if (items && paggingTemplate) {
      const pagging = cloneElement(paggingTemplate, {
        pages: setPages(),
        onChange: (i: number) => navigate(i),
        selected: index,
        theme: theme,
      });
      return pagging;
    }
    return null;
  };

  /**
   * Experiment with distilling swipe offset and velocity into a single variable, so the
   * less distance a user has swiped, the more velocity they need to register as a swipe.
   * Should accomodate longer swipes and short flicks using just distance thresholds and velocity > 0.
   */
  const swipeConfidenceThreshold = 5000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };
  // drag end event triggers do drag to navigate to next/previous card
  const doDrag = ({ offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);
    if (swipe < -swipeConfidenceThreshold) navigate(index + 1);
    if (swipe > swipeConfidenceThreshold) navigate(index - 1);
    setIsDragging(false);
  };

  return (
    <Styled.Carousel>
      <Styled.CardWrapper>
        <AnimatePresence initial={false} custom={direction}>
          <Styled.MotionCard
            className={"carouselitem"}
            style={{ bottom: 0 }}
            key={"carousel_card_" + index}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            custom={direction}
            transition={transition}
            onMouseOver={() => setPlay(false)}
            onMouseOut={() => setPlay(autoplay)}
            onClick={() => (!isDragging ? navigate(index + 1) : null)}
            drag="x"
            onDrag={() => setIsDragging(true)}
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.5}
            onDragEnd={(e, { offset, velocity }) => {
              doDrag({ offset, velocity });
            }}
          >
            {displayItem()}
          </Styled.MotionCard>
        </AnimatePresence>
      </Styled.CardWrapper>
      {displayPagging()}
    </Styled.Carousel>
  );
}
