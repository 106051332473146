import styled from "styled-components";
import { color, type } from "../../pages/tokens";
import { MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
`;

export const Bubble = styled.div<{ $sender: MessageSender }>`
  box-sizing: border-box;
  ${type.pMedium};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 16px;
  border-radius: 8px;
  gap: 8px;
  color: ${(p) => (p.$sender !== 4 ? color.graysTextPrimary : color.graysWhite)};
  background-color: ${(p) => (p.$sender !== 4 ? color.grays100 : color.primaryGPBlue)};
  white-space: pre-wrap;
  line-height: 1.5em;
`;

export const Title = styled.div`
  ${type.pBoldFace};
  line-height: 1.4em;
  display: flex;
  gap: 4px;
  padding-top: 8px;
  justify-content: flex-start;
  align-items: center;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-contnet: center;
  align-items: flex-start;
  overflow: hidden;
  gap: 8px;
  margin: 0;
`;

export const Video = styled.video`
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 275px;
  background-color: ${color.graysWhite};
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
  user-select: none;
  -webkit-user-select: none;
`;

export const VideoLength = styled.span`
  ${type.pSmall};
  line-height: 1em;
  color: ${color.grays700};
  user-select: none;
  -webkit-user-select: none;
  margin-left: 8px;
`;

export const File = styled(motion.div)`
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  background-color: ${color.graysWhite};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  user-select: none;
  -webkit-user-select: none;
`;

export const FileName = styled.span`
  ${type.pMedium};
  line-height: 1em;
  color: ${color.primaryGPBlue};
  user-select: none;
  -webkit-user-select: none;
`;

export const FileType = styled.span`
  ${type.pSmall};
  line-height: 1em;
  color: ${color.grays700};
  user-select: none;
  -webkit-user-select: none;
`;

export const Dots = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const Dot = styled(motion.div)<{ $size: number }>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  background-color: ${color.grays500};
  border-radius: 100px;
`;
