import * as Styled from "./Styles";
import NavButton from "../../../NavButton/NavButton";
import UIButton from "../../../UIButton/UIButton";
import { ico } from "../../../../assets/images/Icon";
import FormField from "../../../FormField/FormField";
import { useUserInfo } from "../../../../contexts/UserInfo";
import { useGIA } from "../../../../contexts/GIAContext";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";
import { shortIntro } from "../../../GIADrawer/GIAData";
import { useSignUp } from "../../../../contexts/SignUp";
import InputField from "../../../InputField/InputField";
import { useState } from "react";

type Info = {
  title: string;
  text: string;
};

const formInfo = {
  card: "1234567890",
  exp: "02/25",
  cvc: "140",
  address: "56 Dwight Avenue.",
  address2: "Building 19",
  state: "CA",
  zip: "94866",
};

const blankInfo = {
  card: "",
  exp: "",
  cvc: "",
  address: "",
  address2: "",
  state: "",
  zip: "",
};

const info = {
  title: "Understing your charges",
  text: "Using G-P has a $100 monthly subscription fee. This fee is entirely waived for the duration of your account provided you have at least one employee onboarded onto our platform. We only begin charging this fee 30 days after account activation until your first employee is onboarded.\n\nNote: provided there are no hired employees on platform, you can cancel your account at any time with no additional costs.",
};

export default function CreditCardInfo() {
  const { showGIAMessages, setShowGIAMessages, lastMessage, setLastMessage } = useGIA();
  const { setChatGTPThinking, messages, setMessages } = useGIA();
  const { userData, setUserData } = useUserInfo();
  const [formData, setFormData] = useState(blankInfo);

  /** displays info panel based on the selected information icon */
  const showInfo = (info: Info) => {
    if (!showGIAMessages) setShowGIAMessages(true);
    const newMessage: ChatMessage = {
      isResponding: false,
      message: { title: info.title, text: info.text },
      sender: MessageSender.GIA,
    };
    if (lastMessage && lastMessage.message.text === newMessage.message.text) return;
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) setMessages([...shortIntro, newMessage]);
      else setMessages([...messages, newMessage]);
      setLastMessage(newMessage);
    }, 1000);
  };

  const handleChange = (fieldname: string, value: any) => {
    setUserData({ contactInfo: { ...userData.contactInfo, [fieldname]: value } });
  };

  const handleAutoFill = () => {
    setFormData(formInfo);
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Payment information</Styled.Title>
      <Styled.Description>
        We'll charge a $100 monthly fee only if your first employee isn't onboarded within the first
        30 days of account activation. Once you onboard you're first employee, this fee is waived.
        <MoreInfoButton info={info} showInfo={(info) => showInfo(info)} />
      </Styled.Description>

      <Styled.FormWrapper>
        <Styled.CardInputWrapper>
          <InputField
            type={"text"}
            required={false}
            theme={"clear"}
            placeholder={"Card Number"}
            passsord={true}
            fill={true}
            leftIcon={true}
            leftIconName={ico.creditCard}
            value={formData.card}
            onFocus={() => handleAutoFill()}
          />
          <InputField
            type={"text"}
            required={false}
            theme={"clear"}
            placeholder={"MM/YY"}
            width={100}
            value={formData.exp}
          />
          <InputField
            type={"text"}
            required={false}
            theme={"clear"}
            placeholder={"CVC"}
            passsord={true}
            width={100}
            value={formData.cvc}
          />
        </Styled.CardInputWrapper>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 8 }}>
          <FormField
            label="Billing Address"
            fieldname="address"
            placeholder={"Street address"}
            type={"text"}
            value={formData.address}
            required={false}
            onChange={(value) => handleChange("phone", value)}
            errormessage={"Please enter a valid address."}
            fill={true}
          />
          <InputField
            type={"text"}
            required={false}
            placeholder={"Building / Floor / Unit"}
            fill={true}
            value={formData.address2}
          />
        </div>
        <div style={{ display: "flex", width: "100%", gap: 24 }}>
          <FormField
            label="State"
            fieldname="state"
            type={"text"}
            value={formData.state}
            required={false}
            onChange={(value) => handleChange("email", value)}
            errormessage={"Please enter a valid state."}
          />
          <FormField
            label="Zip code"
            fieldname="zip"
            type={"text"}
            value={formData.zip}
            required={false}
            onChange={(value) => handleChange("email", value)}
            errormessage={"Please enter a valid zip."}
          />
        </div>
      </Styled.FormWrapper>
      <Styled.SubTitle>
        Note: we'll notify via email of upcoming charges five days before they are processed.
      </Styled.SubTitle>
      <Navigation theme={"blue"} isValid={true} />
    </Styled.QuestionWrapper>
  );
}

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | string;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { theme = "white" } = props;
  const { setPageIndex, pageIndex } = useSignUp();
  return (
    <Styled.NavButtons>
      <NavButton
        theme={"white"}
        showicon={true}
        label={"Back"}
        length={"auto"}
        onClick={() => setPageIndex(pageIndex - 1)}
      />
      <NavButton
        theme={theme}
        showicon={false}
        label={"Next"}
        length={200}
        onClick={() => setPageIndex(pageIndex + 1)}
      />
    </Styled.NavButtons>
  );
};

/**
 * More Info Button
 */

interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};
