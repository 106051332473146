import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const Relo = {
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many employees are you needing to support with relocation internationally?",
      alt: "How many employees",
      description:
        "Depending on the number of relocations, you may have access to size discounts and benefits.",
      responses: [
        {
          options: Data.numberof,
          fieldname: "employees",
        },
      ],
      required: "all",
    },
    {
      title: "From which country(s) is the employee relocating?",
      alt: "Countries",
      description:
        "Some origin countries have unique legal and compliance requirements that may require specific attention and services.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countriesfrom",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "To which country(s) is the employee relocating?",
      alt: "Countries",
      description:
        "Some destination countries have unique legal and compliance requirements that may require specific attention and services.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};

export default Relo;
