import * as Styled from "./Styles";
import quoteHero from "../../assets/images/Quote_Hero@2x.png";
import contactHero from "../../assets/images/Contact_Hero@2x.png";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { ico } from "../../assets/images/Icon";
import Divider from "../Divider/Divider";
import { useState } from "react";

interface Props {
  context?: "quote" | "contact";
  section?: string;
  title?: string;
  text?: string;
  afterhours?: boolean;
  listitems?: string[];
  alttext?: string;
  theme?: "white" | "blue" | "orange" | string;
  navigate?: (direction: number) => void;
}

export default function PageHero(props: Props) {
  const {
    context = "quote",
    afterhours = false,
    section = "section",
    title = "title",
    text = "body",
    alttext = "Description of image",
    listitems = ["item 1", "item 2"],
    theme = "blue",
    navigate = () => null,
  } = props;

  const setHeroImage = () => {
    return context === "quote" ? quoteHero : contactHero;
  };

  const [showCallBack, setShowCallBack] = useState(afterhours);

  const quoteCTA = () => {
    return (
      <PrimaryButton
        theme={"blue"}
        icon={ico.carretDown}
        type={"secondary"}
        length={250}
        label={"Get Started"}
        animate={true}
        onClick={() => navigate(1)}
      />
    );
  };

  const contactCTA = () => {
    return (
      <>
        <PrimaryButton
          theme={"orange"}
          icon={ico.callBack}
          type={"secondary"}
          length={250}
          label={"Get a callback"}
          onClick={() => navigate(0)}
        />
        <Divider />
        <PrimaryButton
          theme={"orange"}
          icon={ico.calendar}
          type={"secondary"}
          length={250}
          label={"Scheudle a call"}
          onClick={() => navigate(1)}
        />
      </>
    );
  };
  const contactAfterHoursCTA = () => {
    return (
      <PrimaryButton
        theme={"orange"}
        icon={ico.calendar}
        type={"secondary"}
        length={250}
        label={"Scheudle a call"}
        onClick={() => navigate(1)}
      />
    );
  };

  return (
    <Styled.HeroWrapper $heroTheme={theme}>
      <Styled.HeroTop $heroTheme={theme}>
        <Styled.HeroContent $heroContext={context}>
          <Styled.HeroImage $heroContext={context}>
            <img src={setHeroImage()} height={"100%"} alt={alttext} />
          </Styled.HeroImage>
          <Styled.HeroText>
            <Styled.H4>{section}</Styled.H4>
            <Styled.H1 onClick={() => setShowCallBack(!showCallBack)}>{title}</Styled.H1>
            <Styled.HeroBody>
              <Styled.HeroBodyText>{text}</Styled.HeroBodyText>
              <Styled.List $heroContext={context}>
                {listitems.map((item, i) => {
                  return (
                    <Styled.ListItem key={"valueprop_" + i} $heroTheme={theme}>
                      {item}
                    </Styled.ListItem>
                  );
                })}
              </Styled.List>
            </Styled.HeroBody>
          </Styled.HeroText>
        </Styled.HeroContent>
      </Styled.HeroTop>
      <Styled.HeroBottom
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "74px 32px",
        }}
      >
        {context === "quote" ? quoteCTA() : showCallBack ? contactAfterHoursCTA() : contactCTA()}
      </Styled.HeroBottom>
    </Styled.HeroWrapper>
  );
}
