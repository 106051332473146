import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import UIButton from "../UIButton/UIButton";
import { useNavigate } from "react-router-dom";
import ContractorImage from "../../assets/images/contractor.png";

interface Props {
  title?: string;
  description?: string;
  image?: ico;
  action?: "talk to sales" | "external link" | "contractor";
  buttonlabel?: string;
  buttonicon?: ico;
  externalURL?: string;
  clickBack?: () => void;
}

export default function Redirect(props: Props) {
  const {
    title = "Let's talk.",
    description = "Managing through mergers & acquisitions often has complex requirements. Let’s talk so we can better understand your unique requirements and propose a solution that fits your needs.",
    buttonlabel = "talk to an expert",
    buttonicon = ico.chatUser,
    action = "talk to sales",
    clickBack = () => null,
    externalURL = "",
  } = props;
  const navigate = useNavigate();

  const handleMainAction = () => {
    if (action === "talk to sales") {
      navigate("/contact");
    } else {
      window.open(externalURL, "_blank");
    }
  };

  const contractorImage = () => {
    return (
      <img src={ContractorImage} width={"450px"} alt={"Screenshot of contractor dashboard."} />
    );
  };

  const talkToExpertImage = () => {
    return (
      <div
        style={{ height: 150, width: 150, borderRadius: "100%", backgroundColor: "transparent" }}
      >
        <Icon name={ico.chat} size={150} stroke={0.5} theme="system" />
      </div>
    );
  };
  //<img src={TalkToSalesImage} height={"150px"} />
  return (
    <Styled.QuestionWrapper>
      <div style={{ borderRadius: 16, overflow: "hidden" }}>
        {action === "contractor" ? contractorImage() : talkToExpertImage()}
      </div>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
      <Styled.Buttons>
        <PrimaryButton
          theme={"blue"}
          icon={buttonicon}
          type={"primary"}
          label={buttonlabel}
          animate={false}
          onClick={() => handleMainAction()}
        />
        <UIButton
          showicon={false}
          size={"large"}
          type={"link"}
          theme={"blueLink"}
          label={"Back"}
          onClick={() => clickBack()}
        />
      </Styled.Buttons>
    </Styled.QuestionWrapper>
  );
}
