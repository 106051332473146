import Icon, { ico } from "../../assets/images/Icon";
import UIButton from "../UIButton/UIButton";
import * as Styled from "./Styles";
import Products, { Product } from "../../assets/images/Products";
import { useState } from "react";

type Info = {
  title?: string;
  text?: string;
};

interface Props {
  name?: string;
  value?: string;
  valuetype?: string;
  valuequalifyer?: string;
  image?: Product;
  description?: string;
  valueprops?: string[];
  strikeout?: boolean;
  disclaimer: string;
  maxvalueprop?: number;
  info?: Info;
  clickInfo?: (info: Info) => void;
}

export default function QuoteCard(props: Props) {
  const {
    name = "Item Name",
    description = "Item Description",
    value = "$0.00",
    valuetype = "p/month",
    valuequalifyer = "Starts at",
    image = Product.Core,
    disclaimer = null,
    valueprops = defaultValueProps,
    maxvalueprop = 6,
    strikeout = false,
    info = defaultInfo,
    clickInfo = () => null,
  } = props;
  const [displayAll, setDisplayAll] = useState(false);

  const showMoreLessButton = () => {
    if (valueprops?.length > maxvalueprop) return true;
    return false;
  };

  const asterisk = () => {
    if (disclaimer) return <Styled.Asterisk>*</Styled.Asterisk>;
    return null;
  };

  const setValue = () => {
    if (disclaimer)
      return (
        <Styled.Value>
          {value}
          {asterisk()}
        </Styled.Value>
      );
    return <Styled.Value>{value}</Styled.Value>;
  };

  const disclaimerInfo = () => {
    if (disclaimer) return <Styled.Disclaimer>{disclaimer}</Styled.Disclaimer>;
    return null;
  };

  return (
    <Styled.Wrapper>
      <Styled.Info $show={true} onClick={() => clickInfo(info)}>
        <Icon name={ico.info} theme={"blueButton"} />
      </Styled.Info>
      <Styled.QuoteInfo>
        <Styled.ProductSection>
          <Styled.Image $show={image ? true : false}>
            <Products product={image ? image : null} />
          </Styled.Image>
          <Styled.Name>{name}</Styled.Name>
          <Styled.ValueSection>
            <Styled.Qualifier $strike={strikeout} $show={valuequalifyer !== ""}>
              {valuequalifyer}
            </Styled.Qualifier>
            {setValue()}
            <Styled.ValueType>{valuetype}</Styled.ValueType>
          </Styled.ValueSection>
        </Styled.ProductSection>
        <Styled.Seperator />
        <Styled.DescriptionSection>
          <Styled.Description>{description}</Styled.Description>
          <Styled.List $show={valueprops ? true : false}>
            {valueprops?.map((item: string, i: number) => {
              const key = name + "_valueprop_" + i;
              if (displayAll) return <Styled.ListItem key={key}>{item}</Styled.ListItem>;
              if (i + 1 > maxvalueprop) return null;
              return <Styled.ListItem key={key}>{item}</Styled.ListItem>;
            })}
          </Styled.List>
          <Styled.MoreButton $show={showMoreLessButton()}>
            <UIButton
              type={"link"}
              label={displayAll ? moretext.less : moretext.more}
              theme={"blueLink"}
              showicon={false}
              onClick={() => setDisplayAll(!displayAll)}
            />
          </Styled.MoreButton>
        </Styled.DescriptionSection>
      </Styled.QuoteInfo>
      {disclaimerInfo()}
    </Styled.Wrapper>
  );
}

const defaultValueProps = [
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
  "Value Prop",
];
const defaultInfo = { title: "Info Title", text: "Info Text" };
const moretext = { more: "more", less: "less" };
