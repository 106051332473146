const Convert = {
  questions: [
    {
      template: "exitflow",
      title: "Let's talk",
      alt: "Transition temporary to permanent roles",
      description:
        "Transitioning temporary team members can have unique operational and legal requirements. Talk to one of our experts to find out how we can help your business.",
      responses: null,
      required: "none",
    },
  ],
  /*
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title:
        "In which country or countries are you looking to convert your contractors into full-time employees?",
      alt: "Countries",
      description:
        "Some countries have unique legal and compliance requirements that may require specific attention.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many contractors are you planning to convert into full-time roles?",
      alt: "How many contractors",
      description:
        "Depending on the number of contractors that need to be converted, you may have access to size discounts and benefits.",
      responses: [
        {
          options: Data.numberof,
          fieldname: "contractors",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title:
        "What's your projected onboarding timeline to convert these contractor(s) into full-time employees?",
      alt: "Timeline",
      description:
        "How quickly you need to transition contractors to full time employees may require special legal, HR and compliance support to help expedite.",
      responses: [
        {
          options: Data.timeframe,
          fieldname: "timeframe",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What are your key priorities when as it related to converting your contractors?",
      alt: "Priorities",
      description:
        "We'll make sure your proposed configuration and pricing emphasizes supporting those area that you feel are most critical to your business operations.",
      responses: [
        {
          options: Data.priorities,
          fieldname: "priorities",
          multiselect: true,
        },
      ],
      required: "all",
    },
   
  ],
   */
};

export default Convert;
