import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const QuoteWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 865px;
  margin: 0px 80px;
  padding: 48px 0;
  flex: 1 0 0;
  gap: 0px;
`;

export const Disclaimer = styled.div`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextTertiary};
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px;
  background-color: ${tokens.color.grays100};
  border-radius: 16px;
`;

export const QuoteSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 0px 16px;
  gap: 0px;
`;

export const SectionTitle = styled.div`
  ${tokens.type.pLarge};
  ${tokens.type.pBoldFace};
  color: ${tokens.color.graysTextPrimary};
  height: auto;
  margin: 0px;
  padding: 0px;
`;

export const SectionDescription = styled.div`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
`;

export const CardsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 48px 48px 48px;
  gap: 48px;
`;

export const CardsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;

export const NavButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin: 0px;
  padding: 0px;
  margin-top: 32px;
`;

export const InfoButton = styled.span<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inline-flex" : "none")};
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const Link = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
