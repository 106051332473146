import * as Styled from "./Styles";
import { useEffect, useState } from "react";
import SurveyButtonList from "../../SurveyButtonList/SurveyButtonList";
import { ButtonType } from "../../SurveyButton/SurveyButton";
import { ico } from "../../../assets/images/Icon";
import UIButton from "../../UIButton/UIButton";
import TextArea from "../../TextArea/TextArea";

type Info = {
  title: string;
  text: string;
};

type Size = {
  width?: number | string;
  height?: number | string;
};

interface Props {
  subtitle?: string;
  description?: string;
  control?: "buttonlist" | "textarea";
  buttontype?: ButtonType;
  multiselect?: boolean;
  options?: any;
  values?: any;
  fieldname?: string;
  size?: Size;
  placeholder?: string;
  onChange?: (name: string, values: boolean[] | string) => void;
  showMore?: (name: string) => void;
  didValidate?: (state: boolean) => void;
  clickInfo?: (info: Info) => void;
}

export default function ResponseTemplate(props: Props) {
  const {
    subtitle = null,
    description = null,
    control = "buttonlist",
    buttontype = ButtonType.default,
    multiselect = false,
    options = null,
    values = null,
    fieldname = null,
    placeholder = "",
    onChange = () => null,
    showMore = () => null,
    didValidate = () => null,
    clickInfo = () => null,
    size = { width: 100, height: 100 },
  } = props;
  const [selectedValues, setSelectedValues] = useState(values);
  useEffect(() => setSelectedValues(values), [values]);

  const checkIsValid = (updates: any) => {
    let isvalid = false;
    if (updates && updates instanceof Array) {
      isvalid = updates.find((i: boolean) => i) !== undefined;
    } else if (updates) {
      isvalid = updates !== "";
    }
    return isvalid;
  };

  const updateSelectedValues = (updates: any) => {
    setSelectedValues(updates);
    let isvalid = checkIsValid(updates);
    didValidate(isvalid);
  };

  const handleChanges = (name: string, updates: boolean[]) => {
    updateSelectedValues(updates);
    onChange(name, updates);
  };

  const handleTextAreaChange = (name: string, updates: string) => {
    updateSelectedValues(updates);
    onChange(name, updates);
  };

  const renderButtons = () => {
    return (
      <SurveyButtonList
        type={buttontype}
        multiselect={multiselect}
        options={options}
        selected={selectedValues instanceof Array ? selectedValues : null}
        onChange={(values) => handleChanges(fieldname, values)}
        clickInfo={(info) => clickInfo(info)}
        size={size}
      />
    );
  };

  const renderTextArea = () => {
    return (
      <TextArea
        name={fieldname}
        size={size}
        value={selectedValues instanceof Array ? "" : selectedValues}
        onChange={(value) => {
          handleTextAreaChange(fieldname, value);
        }}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div>
      <Styled.SubTitle $showtit={subtitle ? true : false}>{subtitle}</Styled.SubTitle>
      <Styled.Description $showdes={description ? true : false}>{description}</Styled.Description>
      {control === "buttonlist" ? renderButtons() : renderTextArea()}
      <MoreButton show={buttontype === ButtonType.country} showMore={() => showMore("country")} />
    </div>
  );
}

interface MoreButtonProps {
  show?: boolean;
  showMore?: () => void;
}
const MoreButton = (props: MoreButtonProps) => {
  const { show = false, showMore = () => null } = props;
  return (
    <Styled.MoreButton $show={show} onClick={() => showMore()}>
      <UIButton type={"link"} label="Show More" icon={ico.carretDown} size={"small"} />
    </Styled.MoreButton>
  );
};
