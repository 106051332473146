import * as Styled from "./Style";
import Icon, { ico } from "../../assets/images/Icon";

export default function NoPage() {
  return (
    <Styled.Page>
      <Icon name={ico.beach} size={150} stroke={0.5} theme={"white"} />
      This page is watching the sunset.
      <br />
      Why don't you join?
    </Styled.Page>
  );
}
