import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Logo from "../../assets/images/Logo";
import * as Styled from "./Styles";
import { color } from "../../pages/tokens";

type Page = {
  name: string;
};

interface Props {
  pages?: Page[];
  size?: number;
  coloron?: string;
  coloroff?: string;
  gap?: number;
  selected?: number;
  transition?: any;
  hover?: number;
  type?: "bullet" | "logo";
  theme?: "dark" | "light";
  onChange?: (index: number) => void;
}

export default function PageIndicator(props: Props) {
  const {
    pages = [{ name: "Page 1" }, { name: "Page 2" }],
    size = 14,
    coloron = color.graysWhite,
    coloroff = color.graysWhite20,
    gap = 24,
    selected = 0,
    transition = { ease: "easeInOut", duration: 0.2 },
    hover = 1.2,
    type = "logo",
    theme = "dark",
    onChange = () => null,
  } = props;

  const [index, setIndex] = useState(selected);
  const [items, setItems] = useState(pages);
  useEffect(() => setIndex(selected), [selected]);
  useEffect(() => setItems(pages), [pages]);

  const states = {
    selected: { backgroundColor: coloron },
    default: { backgroundColor: coloroff },
    hovered: { scale: hover },
    logoselected: { opacity: 1, scale: hover },
    logodefault: { opacity: 0.4, scale: 1 },
    logohovered: { scale: hover },
  };

  const setState = (i: number) => {
    if (type === "logo") return i === index ? "logoselected" : "logodefault";
    return i === index ? "selected" : "default";
  };

  const handleClick = (i: number) => {
    setIndex(i);
    onChange(i);
  };

  /*
  const transformOrigin = (i: number) => {
    if (!items) return;
    if (i === 0) return { transformOrigin: "0% 50%" };
    if (i === items.length - 1) return { transformOrigin: "100% 50%" };
    return { transformOrigin: "50% 50%" };
  };
  */

  const renderBullet = (item: any, i: number) => {
    return (
      <motion.div
        key={item.name + "_" + i.toString()}
        style={{ ...Styled.indicatorStyle({ size: size }) }}
        variants={states}
        transition={transition}
        title={item.name}
        whileHover={states.hovered}
        animate={setState(i)}
        onClick={() => handleClick(i)}
      />
    );
  };

  const renderLogo = (item: any, i: number) => {
    return (
      <motion.div
        key={item.name + "_" + i.toString()}
        style={{ ...Styled.logoStyle({}) }}
        variants={states}
        transition={transition}
        title={item.name}
        whileHover={states.logohovered}
        animate={setState(i)}
        onClick={() => handleClick(i)}
      >
        <Logo height={size} theme={theme === "dark" ? "blue" : "white"} logo={item.name} />
      </motion.div>
    );
  };

  const createPages = () => {
    if (!items) return null;
    return items.map((item, i) => {
      return type === "bullet" ? renderBullet(item, i) : renderLogo(item, i);
    });
  };

  return <div style={Styled.wrapperStyle({ gap: gap })}>{createPages()}</div>;
}
