import * as Styled from "./Styles.ts";
import Logo from "../../images/Logo.tsx";

export function ValuePropCreateAccount() {
  return (
    <Styled.ValueProp>
      <Styled.Logo>
        <Logo logo={"gp"} theme={"white"} />
      </Styled.Logo>
      <Styled.Title>Onboard your first international employee in minutes or days.</Styled.Title>
      <Styled.List>
        <Styled.ListItem $dark={true}>Hire pofessionals in 180+ countries</Styled.ListItem>
        <Styled.ListItem $dark={true}>
          Onboard pofessionals in minutes or days, not months
        </Styled.ListItem>
        <Styled.ListItem $dark={true}>No legal entity required</Styled.ListItem>
        <Styled.ListItem $dark={true}>
          $100 monthly fee waived for the first 30 days
        </Styled.ListItem>
        <Styled.ListItem $dark={true}>
          No monthly fee after your first employee is onboarded
        </Styled.ListItem>
        <Styled.ListItem $dark={true}>Cancel your account anytime.</Styled.ListItem>
      </Styled.List>
    </Styled.ValueProp>
  );
}
