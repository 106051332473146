import styled from "styled-components";
import * as tokens from "../tokens";
import { motion } from "framer-motion";

export const ViewPort = styled(motion.div)`
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Page = styled.div`
  background-color: ${tokens.color.graysWhite};
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  @media screen and ${tokens.device.tabletUp} {
    flex-direction: row;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const ValuePropContainer = styled.div<{ $textColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding: 48px 24px 64px 24px;
  background-color: ${tokens.color.grays100};
  color: ${(p) => tokens.color[p.$textColor]};
  @media screen and ${tokens.device.tabletUp} {
    flex: 1;
    align-self: stretch;
    padding: 80px 32px;
    border-right: 1px solid ${tokens.color.grays300};
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
  }
`;

export const ValuePropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
  @media screen and ${tokens.device.tabletUp} {
    justify-content: space-between;
    flex: 1 0 auto;
    align-self: center;
    max-width: 544px;
    min-width: 428px;
    max-height: 720px;
    min-height: 580px;
    margin: 0;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
    min-width: 0px;
    max-height: none;
    justify-content: center;
  }
  @media screen and ${tokens.device.largeUp} {
    align-self: flex-end;
    margin-right: 96px;
  }
`;

export const OptionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 0;
  padding: 48px 24px 64px 24px;
  background-color: ${tokens.color.graysWhite};
  color: ${tokens.color.graysTextPrimary};
  z-index: 10;
  @media screen and ${tokens.device.tabletUp} {
    flex: 1;
    align-self: stretch;
    padding: 80px 32px;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
    align-items: flex-start;
  }
`;
