import styled from "styled-components";
import { motion } from "framer-motion";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled(motion.div)`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto auto;
`;

export const Content = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Confirmation = styled.p`
  display: flex;
  flex-direction: column;
  jusitify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Title = styled.h1`
  ${tokens.type.h2};
  margin: 0;
  padding: 0;
`;

export const Message = styled.p`
  ${tokens.type.pRegular};
  margin: 0;
  padding: 0;
  max-width: 500px;
  text-align: center;
`;
