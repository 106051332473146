import * as Styled from "./Styles.ts";
import Logo from "../../images/Logo.tsx";

export function ValuePropGetStarted() {
  return (
    <Styled.ValueProp>
      <Styled.Logo>
        <Logo logo={"gp"} theme={"blue"} />
      </Styled.Logo>
      <Styled.SubTitle>Global EOR</Styled.SubTitle>
      <Styled.Header>Get a proposal to match your needs.</Styled.Header>
      <Styled.Title>
        We'll configure an employer of record solution that gets you up an
        running internationally in a matter of weeks:
      </Styled.Title>
      <Styled.List>
        <Styled.ListItem $dark={false}>
          No need to setup local legal entities
        </Styled.ListItem>
        <Styled.ListItem $dark={false}>
          We'll employ, pay, and manage benefits on your behalf
        </Styled.ListItem>
        <Styled.ListItem $dark={false}>
          We'll ensure HR and legal compliance
        </Styled.ListItem>
        <Styled.ListItem $dark={false}>
          We'll bring in market insights and legal support
        </Styled.ListItem>
      </Styled.List>
    </Styled.ValueProp>
  );
}
