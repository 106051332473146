import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes";
import video_poster from "../../assets/images/video_poster@2x.png";

export const initialMessages: Array<ChatMessage> = [
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Hi. I'm GIA, G-P's digital Global Intelligence Assistant. I can help answer questions you have about G-P and all of our services.",
    },
  },
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Meanwhile, learn everything you need to know about EOR in under 2 minutes by watching this short introductory video: ",
    },
    files: [
      { name: "Introducing EOR by G-P", type: "video", size: "1:05 mins", image: video_poster },
    ],
    actions: [{ name: "More on G-P's website", type: "link" }],
  },
];

export const shortIntro: Array<ChatMessage> = [
  {
    sender: MessageSender.GIA,
    message: {
      title: null,
      text: "Hi. I'm GIA, G-P's digital Global Intelligence Assistant. I can help answer questions you have about G-P and all of our services.",
    },
  },
];
