import * as Styled from "./Styles";
import { useEffect, useState, useRef, cloneElement } from "react";
import { AnimatePresence } from "framer-motion";
import { motion as trans } from "../tokens";
import usePageStore from "../../callbacks/usePageData";
import PageHeader from "../../components/PageHeader/PageHeader";
import useResizeObserver from "../../callbacks/useResizeObserver";
import Question from "../../components/Survey/QuestionTemplate/QuestionTemplate";
import QuoteHero from "../../components/Survey/SurveyQuestions/QuoteHero";
import ContactForm from "../../components/ContactForm/ContactForm";
import InfoPanel from "../../components/InfoPanel/InfoPanel";
import QuestionTree from "../../components/Survey/SurveyQuestions/SurveyTree";
import { ResponseStore } from "../../components/Survey/SurveyQuestions/SurveyTree";
import * as SurveyData from "../../components/Survey/SurveyQuestions/SurveyData";
import SurveyPagging from "../../components/SurveyPagging/SurveyPagging";
import QuoteResult from "../../components/Quote/QuoteResult";
import { color } from "../tokens";
import ModalOverlay from "../../components/ModalOverlay/ModalOverlay";
import FindCountry from "../../components/FindCountry/FindCountry";
import Redirect from "../../components/Redirect/Redirect";
import { ico } from "../../assets/images/Icon";
import { GIAFab } from "../../components/GIAFab/GIAFab";
import { useGIA } from "../../contexts/GIAContext";
import {
  ChatMessage,
  MessageSender,
} from "../../components/EnumsAndTypes/EnumsAndTypes";
import { shortIntro } from "../../components/GIADrawer/GIAData";
import { useUserInfo } from "../../contexts/UserInfo";

type Info = {
  title: string;
  text: string;
};
const enum Direction {
  forward = 1,
  back = -1,
}
const clearInfo = {
  show: false,
  info: {
    title: null,
    text: null,
  },
};

export default function Quote() {
  const { showGIAMessages, setShowGIAMessages, messages, setMessages } =
    useGIA();
  const { userData, setUserData } = useUserInfo();
  const { lastMessage, setLastMessage, setChatGTPThinking } = useGIA();
  const [questions, setQuestions] = useState(QuestionTree.core.questions);
  const [index, setIndex] = useState(1); // track current index in the stack
  const [direction, setDirection] = useState(1);
  const [infoPanel, setInfoPanel] = useState(clearInfo);
  const [responseData, setResponseData] = usePageStore(ResponseStore);
  const [pages, setPages] = useState(null);
  const [filter, setFilter] = useState(null);
  const [exitFlow, setExitFlow] = useState(false);
  const [progress, setProgress] = useState(1); // start at one becuase first 2 pages are free to nav
  const [theme, setTheme] = useState("blue");
  const [showModal, setShowModal] = useState(false); // hide and show modals
  const ref = useRef(null); // we'll use the ref to attach to the resize ovserver
  const size = useResizeObserver(ref); // we'll use page width/height to bound animation so listen for changes

  /**
   * We'll use this to update content styles, themes, etc. based om the
   * level of progress through the survey
   */
  useEffect(() => {
    setTheme(index > 0 ? "white" : "blue");
    document.body.style.backgroundColor =
      index > 0 ? color.graysWhite : color.primaryGPBlue;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.backgroundColor = "#fff";
      document.body.style.overflow = "auto";
    };
  }, [index]);

  /**
   * we use this to set the filters list based on the user seclected scenarios
   * filters are used to conactenate question from each branch
   */
  useEffect(() => {
    let temp = responseData.scenarios;
    if (temp && temp.filter((o: boolean) => o) !== undefined) {
      const filters = responseData.scenarios.map((item: boolean, i: number) => {
        return item ? SurveyData.scenarios[i].filter : null;
      });
      setFilter(filters);
    }
  }, [responseData.scenarios]);

  /**
   * when filters are updated, we call back to get an updated list of questions
   * matching the slected filter(s). We also update the pagging indicator to reflect
   * the change in pages for questions.
   */
  useEffect(() => {
    // build the list of questions based on the response to question 1
    let items = QuestionTree.core.questions;
    filter?.map((f: string, i: number) => {
      if (f && QuestionTree[f]) items = items.concat(QuestionTree[f].questions);
      return null;
    });
    // if the flow exits we don't show contact form and quote so we don't add the wrap section
    // and we need to let pagging know not to show the check mark through exit flow state
    let exitFlow = false;
    items.map((item, i) => {
      if (item.template === "exitflow") exitFlow = true;
      return null;
    });
    if (!exitFlow) {
      setExitFlow(false);
      items = items.concat(QuestionTree.wrap.questions);
    } else {
      setExitFlow(true);
    }
    // update states
    const pages = items.map((item) => item.alt);
    setQuestions(items);
    setPages(pages);
  }, [filter]);

  useEffect(() => {
    if (showGIAMessages)
      setInfoPanel({ show: true, info: { title: "title", text: "text" } });
    else setInfoPanel({ show: false, info: { title: "title", text: "text" } });
  }, [showGIAMessages]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };
  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  /** Navigates through survey: +/- or by direct push to specific index  */
  const navPage = (i: number, goto: boolean = false) => {
    // update the index to display the cotrrect content
    if (goto) {
      setIndex(i);
      setDirection(i < index ? Direction.back : Direction.forward);
    } else if (index + i >= 0 && i <= 2) {
      // set the aboslute progress for the pagging
      if (i > 0 && index + 1 > progress) setProgress(index + 1);
      setDirection(i < 0 ? Direction.back : Direction.forward);
      setIndex(index + i);
    }
  };

  /** retrieves the user responses stored in state for a specific question */
  const getValues = (responses: any) => {
    if (!responses) return null;
    return responses.map((item: any) => {
      //console.log("resps: ", responses, "data: ", responseData);
      return responseData[item.fieldname];
    });
  };

  /** displays info panel based on the selected information icon */
  const showInfo = (info: Info) => {
    if (!infoPanel.show) {
      setInfoPanel({ show: true, info: info });
      setShowGIAMessages(true);
    }
    const newMessage: ChatMessage = {
      isResponding: false,
      message: { title: info.title, text: info.text },
      sender: MessageSender.GIA,
    };
    if (lastMessage && lastMessage.message.text === newMessage.message.text)
      return;
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) setMessages([...shortIntro, newMessage]);
      else setMessages([...messages, newMessage]);
      setLastMessage(newMessage);
    }, 1000);
  };

  /**
   *  if user changes the scenario, we will reset all the survey data with the
   * exception of the scenarios the contact info, also need to reset progress
   */
  const doResetIfNeeded = (datasetname: string) => {
    if (datasetname !== "scenarios") return;
    let temp = responseData;
    Object.keys(temp).forEach((key) => {
      if (key !== "scenarios" && key !== "contactinfo") temp[key] = null;
    });
    setResponseData(temp);
    setProgress(index);
  };

  /**
   * Grab the right question compoenent or hero and display it loading all events, data
   * etc. for the actively dislaplyed question as needed.
   */
  const setCurrentPage = () => {
    const template = questions[index].template.toLowerCase();
    if (template === "question") {
      let item = questions[index];
      const element = cloneElement(<Question />, {
        title: item.title,
        description: item.description,
        info: item.info,
        responses: item.responses,
        required: item.required,
        values: getValues(item.responses),
        theme: "white",
        showMore: (name: string) => setShowModal(true),
        clickInfo: (info: Info) => showInfo(info),
        navigate: (i: number) => navPage(i),
        onChange: (n: string, v: boolean[] | string) => {
          doResetIfNeeded(n);
          setResponseData({ [n]: v });
        },
      });
      return element;
    }
    if (template === "contacts") {
      return (
        <ContactForm
          clickInfo={(info: Info) => showInfo(info)}
          navigate={(i: number) => navPage(i)}
          onChange={(v: any) => {
            setResponseData({ contactinfo: v });
            setUserData({ contactInfo: v });
          }}
          //values={responseData.contactinfo}
          values={userData.contactInfo}
        />
      );
    }
    if (template === "quote") {
      return (
        <QuoteResult
          clickInfo={(info: Info) => showInfo(info)}
          navigate={(i) => navPage(i)}
          showProgress={true}
          contactInfo={userData.contactInfo}
        />
      );
    }
    if (template === "exitflow") {
      let item = questions[index];
      let buttonlabel = "Talk to an expert";
      let icon = ico.chatUser;
      if (item.title === "Manage Contractors") {
        buttonlabel = "Sign Up for Contractor (free)";
        icon = ico.arrowRight;
      }
      return (
        <Redirect
          title={item.title}
          description={item.description}
          buttonicon={icon}
          buttonlabel={buttonlabel}
          action={
            item.title === "Manage Contractors" ? "contractor" : "talk to sales"
          }
          clickBack={() => navPage(-1)}
        />
      );
    }
    return <QuoteHero navigate={(i: number) => navPage(i)} />;
  };

  //** transtion info for displing the right info panel */
  const viewportVariants = {
    showinfo: { marginRight: 349, borderRight: "1px solid rgba(0,0,0,1)" },
    default: { marginRight: -1, borderRight: "1px solid rgba(0,0,0,0)" },
  };

  return (
    <Styled.Viewport
      variants={viewportVariants}
      initial={"default"}
      transition={{ ...trans.easIn, duration: 0.25 }}
      animate={infoPanel.show ? "showinfo" : "default"}
    >
      <ModalOverlay
        visible={showModal}
        cancelClick={(withsave: boolean) => setShowModal(!showModal)}
        title={"Select Countries"}
        subtitle={null}
        size={{ width: "600" }}
      >
        <FindCountry
          cancelClick={() => setShowModal(!showModal)}
          doneClick={(value) => setShowModal(!showModal)}
        />
      </ModalOverlay>
      <PageHeader theme={theme} context={"quote"} />
      <SurveyPagging
        pages={pages}
        progress={progress}
        selected={index}
        onChange={(i) => navPage(i, true)}
        right={infoPanel.show ? 374 : 40}
        showcheck={!exitFlow}
      />
      <InfoPanel
        title={infoPanel.info.title}
        text={infoPanel.info.text}
        visible={infoPanel.show}
        didClose={(show, info) => {
          setInfoPanel({ show: show, info: info });
          setShowGIAMessages(false);
        }}
      />
      <Styled.QuotePage ref={ref}>
        <AnimatePresence initial={false} custom={direction}>
          <Styled.Content
            custom={direction}
            transition={{ ...trans.easIn, duration: 0.85 }}
            variants={scrollVariants}
            initial={"enter"}
            animate={"animate"}
            exit={"exit"}
            key={"page_" + index}
          >
            {setCurrentPage()}
          </Styled.Content>
        </AnimatePresence>
      </Styled.QuotePage>
      <GIAFab />
    </Styled.Viewport>
  );
}
