import styled from "styled-components";

export const ButtonList = styled.div<{ $gap: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${(p) => (p.$gap ? p.$gap : "16")}px;
  row-gap: ${(p) => (p.$gap ? p.$gap : "16")}px;
`;
