import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const core = {
  questions: [
    {
      template: "quote hero",
      title: "Introduction",
      alt: "Introduction",
      responses: null,
      required: "none",
    },
    {
      template: "question",
      title: "What is your primary business goal and/or challenge?",
      alt: "Primary goals",
      description:
        "Your goals and objectives help us understand the capabilities that best match your needs.",
      info: {
        title: "Why we ask about your primary business goals.",
        text: "Based on your business goals and objectives, we'll match the capabilities and services we think you'll need.\n\nAs an industry leader, we'll build off the data and experience we've gained from past projects, that also match your scenario, to make sure we recommend a setup that best sets you up for success.",
      },
      responses: [
        {
          options: Data.scenarios,
          fieldname: "scenarios",
          buttontype: ButtonType.multiline,
          size: { height: 100, width: 225 },
        },
      ],
      required: "all",
    },
  ],
};

export default core;
