import * as Styled from "./Styles";
import { useState } from "react";
import Icon, { ico } from "../../assets/images/Icon";
import TimeSlotButton from "../TimeSlotButton/TimeSlotButton";
import EventSummary from "../EventSummary/EventSummary";

interface Props {
  startDate?: Date;
  navigate?: (i: number) => void;
}

export default function WeeklyCalendar(props: Props) {
  const { navigate = () => null } = props;
  // use US english locale for date string formats
  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" } as any;
  const [weekStart, setWeekStart] = useState(new Date());
  const timeSlots = ["9-10am", "10-11am", "11-12pm", "1-2pm", "2-3pm"];

  // returns the first day of the weej for a given date.
  const getFirstDayWeek = (date: Date) => {
    const currentDate = new Date(date); // get date for today
    // get first day of the current week (getDate - getDay, -6 or +1 depending if getDay=0 ie sunday)
    const diff =
      currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1);
    // use set date to get a new date with the day difreence and return this
    const firstDay = new Date(currentDate.setDate(diff));
    return firstDay;
  };

  // return the week of the year
  const getWeekOfYear = (date: Date) => {
    const currentDate = new Date(date);
    const yearStartDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor(
      (currentDate.valueOf() - yearStartDate.valueOf()) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil(days / 7);
  };

  const clickPrevious = () => {
    const current = new Date(weekStart);
    const previous = new Date(current.setDate(current.getDate() - 7));
    setWeekStart(previous);
  };

  const clickNext = () => {
    const current = new Date(weekStart);
    const next = new Date(current.setDate(current.getDate() + 7));
    setWeekStart(next);
  };

  // test to see if a date is prior to today
  const dateBeforeToday = (compareDate: Date) => {
    const currentDate = new Date();
    return compareDate <= currentDate;
  };

  /*
  const isAvaialable = (min: number = 3, max: number = 10) => {
    // generate random availble
    const randNumber = Math.floor(Math.random() * max);
    return randNumber > min;
  };
  */

  const displayWeek = (firstday: Date, weekNumber: number) => {
    const today = new Date();
    const weekDiff = weekNumber - getWeekOfYear(today);
    let weekLabel = "";
    if (weekDiff < 2) {
      weekLabel = weekDiff === 0 ? "This Week" : "Next Week";
    } else {
      const start = new Date(firstday);
      let firstDay =
        start.getDate() + " " + start.toLocaleDateString("en-us", { month: "short" }) + ".";
      let weekEnds = new Date(start.setDate(start.getDate() + 5));
      let lastDay =
        weekEnds.getDate() + " " + weekEnds.toLocaleDateString("en-us", { month: "short" }) + ".";
      weekLabel = firstDay + " - " + lastDay;
    }
    return <Styled.WeekInfo>{weekLabel}</Styled.WeekInfo>;
  };

  // display the days of the week
  const writeDaysOfWeek = (days: number) => {
    const businessDays = new Array(days).fill(null as Date);
    // create an array with the next "days" business days -> excludes 0/6 that are sunday and saturday
    for (let i = 1; i < days + 3; i++) {
      const start = new Date(weekStart);
      const thisDay = new Date(start.setDate(start.getDate() + i));
      const dayOfWeek = thisDay.getDay();
      if (dayOfWeek > 0 && dayOfWeek < 6) businessDays.push(thisDay);
    }
    // iterate through the business days to display them only showing week at start of the week
    let weekNumber = null as number;
    return businessDays.map((day: Date, i: number) => {
      let showWeek = false;
      if (!weekNumber || getWeekOfYear(day) !== weekNumber) {
        weekNumber = getWeekOfYear(day);
        showWeek = true;
      }
      return (
        day && (
          <Styled.WeekDayBlock key={"datetime_" + i}>
            {showWeek ? displayWeek(getFirstDayWeek(day), weekNumber) : null}
            <Styled.WeekDayTitle $isBefore={dateBeforeToday(day)}>
              {day.toLocaleDateString("en-us", options)}
            </Styled.WeekDayTitle>
            <Styled.TimeSlots>{displayTimeSlots(dateBeforeToday(day), i, day)}</Styled.TimeSlots>
          </Styled.WeekDayBlock>
        )
      );
    });
  };

  const displayTimeSlots = (isBefore: boolean, dayindex: number, day: Date) => {
    return timeSlots.map((slot: string, i: number) => {
      /*
      const setButtonState = () => {
        if (isBefore || !isAvaialable()) return "disabled";
        return "active";
      };
      */
      const thisKey = day.getDate() + "_" + day.getMonth() + "_" + day.getFullYear() + "_" + i;
      return (
        <TimeSlotButton
          key={thisKey + "_" + i}
          label={slot}
          state={"active"}
          navigate={(i) => navigate(i)}
        />
      );
    });
  };

  return (
    <Styled.Wrapper>
      <EventSummary />
      <Styled.WeekTimes>
        <Styled.WeekSelect>
          <WeekButtons
            isBefore={dateBeforeToday(weekStart)}
            previousClick={() => clickPrevious()}
            nextClick={() => clickNext()}
          />
        </Styled.WeekSelect>
        <Styled.WeekDaysWrapper>{writeDaysOfWeek(5)}</Styled.WeekDaysWrapper>
      </Styled.WeekTimes>
    </Styled.Wrapper>
  );
}

interface ButtonProps {
  nextClick?: () => void;
  previousClick?: () => void;
  isBefore?: boolean;
}
const WeekButtons = (props: ButtonProps) => {
  const { nextClick = () => null, previousClick = () => null, isBefore = false } = props;
  return (
    <Styled.ButtonWrapper>
      <Styled.NavWeekButton $isPrevious={true} onClick={() => (!isBefore ? previousClick() : null)}>
        <Icon name={ico.arrowLeft} theme={isBefore ? "system" : "blueButton"} />
      </Styled.NavWeekButton>
      <Styled.NavWeekButton $isPrevious={false} onClick={() => nextClick()}>
        <Icon name={ico.arrowRight} theme={"blueButton"} />
      </Styled.NavWeekButton>
    </Styled.ButtonWrapper>
  );
};
