import * as Styled from "./Styles";
import NavButton, { State } from "../NavButton/NavButton";
import UIButton from "../UIButton/UIButton";
import { ico } from "../../assets/images/Icon";
import FormField from "../FormField/FormField";
import ResponseTemplate from "../Survey/ResponseTemplate/ResponseTemplate";
import { ButtonType } from "../SurveyButton/SurveyButton";
import * as Data from "../Survey/SurveyQuestions/SurveyData";
import { useState } from "react";
import usePageStore from "../../callbacks/usePageData";
import AcceptTerms from "../Terms/Terms";

type ContactInfo = {
  name?: string;
  company?: string;
  email?: string;
  phone?: string;
  role?: boolean[];
  accepted?: boolean;
};

const initialData = {
  name: "",
  phone: "",
  email: "",
  company: "",
  role: null,
  accepted: false,
};

const initialValidation = {
  name: false,
  phone: false,
  email: false,
  company: false,
  role: false,
  accepted: false,
};

const initialValidationValues = {
  name: true,
  phone: true,
  email: true,
  company: true,
  role: true,
  accepted: false,
};

const defaultInfo = {
  name: "Dwight Schrute",
  phone: "(617) 384-5528",
  email: "dwights@theoffice.com",
  company: "Dunder Mifflin Paper Company, Inc.",
  role: [false, false, false, false, false, false, false, true, false, false],
  accepted: true,
};

const allValid = {
  name: true,
  phone: true,
  email: true,
  company: true,
  role: true,
  accepted: true,
};

const info = {
  title: "We don't share your contact information",
  text: "At G-P we respect your privacy. We won't share your contact information with third parties without you explicity consent.\n\nWe'll use your contact information to reach out to you should the need arise and to send a copy of your quote to the email address you provide.",
};

const subTitle = {
  quote: "We'll use your contact information to send you a copy of your quote via email.",
  contact: "We'll use this should we need to contact you.",
};

interface Props {
  values?: ContactInfo;
  onChange?: (values: any[]) => void;
  navigate?: (direction: number) => void;
  didValidate?: (valid: boolean) => void;
  clickInfo?: (info: Info) => void;
  context?: "quote" | "contact";
}

export default function ContactForm(props: Props) {
  const {
    onChange = () => null,
    navigate = () => null,
    didValidate = () => null,
    clickInfo = () => null,
    context = "quote",
    values = null,
  } = props;
  const [valid, setValid] = useState(values ? true : false);
  const [validation, setValidation] = usePageStore(
    values ? initialValidationValues : initialValidation
  );
  const [data, setData] = usePageStore(values ? values : initialData);

  const handleDidValidate = (fieldname: string, state: any) => {
    let isvalid = true;
    if (!state) isvalid = false;
    Object.keys(validation).forEach((key) => {
      if (key !== fieldname) {
        if (!validation[key]) isvalid = false;
      }
    });
    setValidation({ [fieldname]: state });
    setValid(isvalid);
    didValidate(isvalid);
  };

  const handleChange = (fieldname: string, value: any) => {
    setData({ [fieldname]: value });
    let updatedValues = data;
    updatedValues[fieldname] = value;
    onChange(updatedValues);
    console.log(data);
  };

  const handleAutoFill = () => {
    setData({ ...defaultInfo });
    setValidation({ ...allValid });
    onChange(defaultInfo as any);
    setValid(true);
    didValidate(true);
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Your contact information</Styled.Title>
      <Styled.Description>
        {subTitle[context]}
        <MoreInfoButton info={info} showInfo={(info) => clickInfo(info)} />
      </Styled.Description>
      <Styled.FormWrapper>
        <FormField
          label="Name"
          fieldname="name"
          type={"text"}
          value={data.name}
          onChange={(value) => handleChange("name", value)}
          didValidate={(state) => handleDidValidate("name", state)}
          errormessage={"Please enter your full name."}
          onFocus={() => handleAutoFill()}
        />
        <FormField
          label="Phone"
          fieldname="phone"
          type={"phone"}
          value={data.phone}
          onChange={(value) => handleChange("phone", value)}
          didValidate={(state) => handleDidValidate("phone", state)}
          errormessage={"Please enter a valid phone number."}
        />
        <FormField
          label="Company name"
          fieldname="company_name"
          type={"text"}
          value={data.company}
          onChange={(value) => handleChange("company", value)}
          didValidate={(state) => handleDidValidate("company", state)}
          errormessage={"Please enter your company's name."}
        />
        <FormField
          label="Company Email"
          fieldname="company_email"
          type={"email"}
          value={data.email}
          onChange={(value) => handleChange("email", value)}
          didValidate={(state) => handleDidValidate("email", state)}
          errormessage={"Please enter a valid company email."}
        />
        <ResponseTemplate
          subtitle={"Your Role"}
          options={Data.roles}
          values={data.role}
          multiselect={true}
          fieldname={"priorities"}
          buttontype={ButtonType.default}
          size={null}
          onChange={(n: string, v: boolean[]) => handleChange("role", v)}
          didValidate={(state) => handleDidValidate("role", state)}
        />
        <Styled.AcceptTerms>
          <AcceptTerms
            accepted={data.accepted}
            onChange={(state: boolean) => {
              handleChange("accepted", state);
              handleDidValidate("accepted", state);
            }}
          />
        </Styled.AcceptTerms>
      </Styled.FormWrapper>
      <Navigation theme={"white"} isValid={valid} navigate={(i) => navigate(i)} />
    </Styled.QuestionWrapper>
  );
}

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | string;
  navigate?: (direction: number) => void;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { theme = "white", navigate = () => null, isValid = false } = props;
  return (
    <Styled.NavButtons>
      <NavButton label={"Back"} theme={theme} onClick={() => navigate(-1)} />
      <NavButton
        theme={theme}
        state={isValid ? State.active : State.disabled}
        showicon={false}
        label={"Next"}
        length={200}
        onClick={() => (isValid ? navigate(1) : null)}
      />
    </Styled.NavButtons>
  );
};

/**
 * More Info Button
 */
type Info = {
  title?: string;
  text?: string;
};
interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};
