import StartButton from "../StartButton/StartButton";
import * as Styled from "./Styles";
import ContactFormShort from "../ContactFormShort/ContactFormShort";
import AcceptTermsMarketing from "../Terms/TermsMarketing";

export default function StartOptions() {
  return (
    <Styled.Wrapper>
      <ContactFormShort />
      <Styled.Title>How would you like to get started?</Styled.Title>
      <Styled.StartOptions>
        <StartButton type={"quote"} />
        <StartButton type={"contact"} />
      </Styled.StartOptions>
      <Styled.AcceptTerms>
        <AcceptTermsMarketing accepted={true} />
      </Styled.AcceptTerms>
    </Styled.Wrapper>
  );
}
