import * as Styled from "./Styles";

interface Props {
  label?: string;
  width?: string | number;
  style?: any;
  onClick?: () => void;
}
export function TertiaryButton(props: Props) {
  const { label = "label", onClick = () => null, width = "auto", style = {} } = props;
  const setWidth = () => {
    if (typeof width === "number") return width + "px";
    return width;
  };
  return (
    <Styled.Wrapper style={{ ...style, width: setWidth() }} onClick={() => onClick()}>
      {label}
    </Styled.Wrapper>
  );
}
