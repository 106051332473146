import { AnimatePresence } from "framer-motion";
import AcountInfo from "./steps/AccountInfo/AccountInfo";
import * as Styled from "./Styles";
import { useEffect, useRef, useState } from "react";
import useResizeObserver from "../../callbacks/useResizeObserver";
import { useSignUp } from "../../contexts/SignUp";
import { Direction } from "../EnumsAndTypes/EnumsAndTypes";
import { TermsOfService } from "./steps/TermsOfService/TermsOfService";
import CreditCardInfo from "./steps/CreditCardInfo/CreditCardInfo";
import { CreatingAccount } from "./steps/CreatingAccount/CreatingAccount";
import { Confirmation } from "./steps/Confirmation/Confirmation";

export function SignUp() {
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const { pageIndex } = useSignUp();
  const [direction, setDirection] = useState(Direction.forward);
  const [isAnimating, setIsAnimating] = useState(false);
  const [page, setPage] = useState(pageIndex);

  useEffect(() => {
    if (pageIndex === page) return;
    setDirection(pageIndex >= page ? 1 : -1);
    setPage(pageIndex);
  }, [pageIndex, page]);

  // values for the horizontal pagging animation
  const amt = (): number => {
    return size?.height ? size.height : 1000;
  };

  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 1 };
    },
  };

  return (
    <Styled.OptionsContainer ref={ref}>
      <AnimatePresence
        initial={true}
        custom={direction}
        onExitComplete={() => setIsAnimating(false)}
      >
        <Styled.Content
          variants={scrollVariants}
          custom={direction}
          transition={{ ease: "easeInOut", duration: 0.85 }}
          initial={"enter"}
          animate={"animate"}
          exit={"exit"}
          key={"page_" + page}
          onAnimationStart={() => setIsAnimating(true)}
          style={{ overflowY: isAnimating ? "hidden" : "auto" }}
        >
          {page === 0 && <AcountInfo />}
          {page === 1 && <TermsOfService />}
          {page === 2 && <CreditCardInfo />}
          {page === 3 && <CreatingAccount />}
          {page === 4 && <Confirmation />}
        </Styled.Content>
      </AnimatePresence>
    </Styled.OptionsContainer>
  );
}
