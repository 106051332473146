import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const variants = {
  disabled: {
    borderColor: tokens.color.grays100,
    color: tokens.color.graysTextDisabled,
    backgroundColor: tokens.color.grays100,
    cursor: "default",
  },
  active: {
    borderColor: tokens.color.grays400,
    color: tokens.color.primaryGPBlue,
    backgroundColor: tokens.color.graysWhite100,
    cursor: "pointer",
  },
  selected: {
    borderColor: tokens.color.graysTextPrimary,
    color: tokens.color.graysWhite,
    backgroundColor: tokens.color.graysTextPrimary,
    cursor: "pointer",
  },
  hovered: { scale: 1.04 },
  pressed: { scale: 0.98 },
};

export const TimeSlotButton = styled(motion.div)`
  ${tokens.type.pMedium};
  color: ${tokens.color.primaryGPBlue};
  tab-index: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 12px 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 8px;
  user-select: none;
  flex: 1;
  white-space: nowrap;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 105px;
`;

export const Confirmation = styled.div<{ $show: boolean }>`
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100;
  background-color: ${tokens.color.warning200};
  box-sizing: border-box;
  padding: 8px;
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
`;

export const Carret = styled.div<{ $show: boolean }>`
  position: absolute;
  z-index: 101;
  top: -14px;
  left: 50%;
  transform: translate(-50%, 0%) rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: ${tokens.color.warning200};
  box-sizing: border-box;
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 0px;
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;
