import { useRef, useEffect, useState } from "react";
import useResizeObserver from "../../callbacks/useResizeObserver";
import * as Styled from "./Styles.ts";
import { motion } from "../../pages/tokens.ts";

type ItemData = {
  logoUrl?: string;
  comment?: string;
  photoUrl?: string;
  name?: string;
  title?: string;
  company?: string;
};

interface Props {
  content?: ItemData;
  theme?: "light" | "dark";
  sizeChange?: (size: any) => void;
}

export default function Testimonial(props: Props) {
  // default props
  const { content = defaultData, sizeChange = () => null, theme = "dark" } = props;

  // we need to tell paren to adjust to childs height as test cards
  // will need to be aabsolutely positioned
  const ref = useRef(null);
  const size = useResizeObserver(ref);
  const [currentSize, setCurrentSize] = useState({ height: 0, width: 0 });

  // message parent that height has updated if height changes
  useEffect(() => {
    if (size.height && currentSize.height !== size.height) {
      sizeChange(size);
      setCurrentSize(size);
    }
  }, [size, sizeChange, currentSize.height]);

  return (
    <Styled.Testimonial
      ref={ref}
      whileHover={Styled.variants.hover}
      initial={Styled.variants.default}
      transition={motion.spring}
    >
      <Styled.Comment>{content.comment}</Styled.Comment>
      <Styled.Company $theme={theme}>- {content.company}</Styled.Company>
    </Styled.Testimonial>
  );
}

const defaultData = {
  comment: "This is a comment",
  company: "This is a company name",
};
