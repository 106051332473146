import { ico } from "../../assets/images/Icon";
import Logo from "../../assets/images/Logo";
import * as Styled from "./Styles";
import UIButton from "../UIButton/UIButton";
import { useNavigate } from "react-router-dom";
import { useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import { motion } from "../../pages/tokens";
import { useGIA } from "../../contexts/GIAContext";

interface Props {
  theme?: "white" | "blue" | "orange" | string;
  context?: "home" | "quote" | "contact";
}

export default function PageHeader(props: Props) {
  const { theme = "white", context = "home" } = props;
  const { connectLiveAgent } = useGIA();
  const navigate = useNavigate();
  const controls = useAnimationControls();
  useEffect(() => {
    controls.start(Styled.themeColors[theme]);
  }, [theme, controls]);

  const showButton = (buttonType: string) => {
    if (context === "home" && buttonType === "quit") return true;
    if (context !== "home" && buttonType === "back") return true;
    if (context === "quote" && buttonType === "talk") return true;
    if (context === "contact" && buttonType === "call") return true;
    return false;
  };

  return (
    <Styled.Container
      $theme={theme}
      $context={context}
      variants={Styled.themeColors}
      initial={Styled.themeColors[theme]}
      animate={controls}
      transition={{ ...motion.themeChange }}
    >
      <Styled.LeftWrapper $show={context !== "home"}>
        <Styled.ButtonBack $show={showButton("back")}>
          <UIButton
            icon={ico.arrowLeft}
            theme={theme}
            label={"Back"}
            size={"regular"}
            type={"primary"}
            navigate={-1}
          />
        </Styled.ButtonBack>
        <Styled.ButtonBackIcon $show={showButton("back")}>
          <UIButton
            icon={ico.arrowLeft}
            theme={theme}
            size={"regular"}
            type={"unframed"}
            navigate={-1}
          />
          <Logo theme={theme} onClick={() => navigate(-1)} />
        </Styled.ButtonBackIcon>
      </Styled.LeftWrapper>
      <Styled.Logo $context={context}>
        <Logo theme={theme} />
      </Styled.Logo>
      <Styled.RightWrapper>
        <Styled.ButtonQuit $show={showButton("quit")}>
          <UIButton icon={ico.x} size={"large"} type={"unframed"} navigate={-1} theme={theme} />
        </Styled.ButtonQuit>
        <Styled.ButtonTalk $show={showButton("talk")}>
          <UIButton
            showicon={false}
            label={"Talk to an expert"}
            theme={theme}
            type={"primary"}
            onClick={() => connectLiveAgent()}
          />
        </Styled.ButtonTalk>
        <Styled.ButtonCall $show={showButton("call")}>
          <UIButton
            showicon={false}
            label={"Call +1 (888)-855-5328"}
            theme={theme}
            type={"primary"}
          />
        </Styled.ButtonCall>
      </Styled.RightWrapper>
    </Styled.Container>
  );
}

/*
 */
