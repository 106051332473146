import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const themeStyles = {
  white: {
    primary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
      },
      pressed: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
        scale: 1.04,
      },
    },
    secondary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
      },
      pressed: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
        scale: 1.04,
      },
    },
  },
  blue: {
    primary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
      },
      pressed: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.primaryGPBlue,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.primaryGPBlue,
        scale: 1.04,
      },
    },
    secondary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
      },
      pressed: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.primaryGPBlue,
        borderColor: tokens.color.primaryGPBlue,
        scale: 1.04,
      },
    },
  },
  orange: {
    primary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.graysTextPrimary,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.graysTextPrimary,
      },
      pressed: {
        backgroundColor: tokens.color.graysTextPrimary,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.graysTextPrimary,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.graysTextPrimary,
        color: tokens.color.graysWhite,
        borderColor: tokens.color.graysTextPrimary,
        scale: 1.04,
      },
    },
    secondary: {
      disabled: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextDisabled,
        borderColor: tokens.color.grays300,
      },
      active: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextPrimary,
        borderColor: tokens.color.graysTextPrimary,
      },
      pressed: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextPrimary,
        borderColor: tokens.color.graysTextPrimary,
        scale: 0.98,
      },
      hover: {
        backgroundColor: tokens.color.graysWhite,
        color: tokens.color.graysTextPrimary,
        borderColor: tokens.color.graysTextPrimary,
        scale: 1.04,
      },
    },
  },
};

export const Button = styled(motion.div)<{
  $state: string;
  $theme: string;
  $icononly: boolean;
  $type: string;
  $length: "auto" | number;
}>`
  ${tokens.type.btnRegular};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 52px;
  width: ${(p) =>
    p.$icononly ? "52px" : p.$length !== "auto" ? p.$length + "px" : "auto"};
  padding: ${(p) => (p.$icononly ? "0px 0px" : "0px 24px")};
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => themeStyles[p.$theme][p.$type][p.$state].borderColor};
  background-color: ${(p) =>
    themeStyles[p.$theme][p.$type][p.$state].backgroundColor};
  color: ${(p) => themeStyles[p.$theme][p.$type][p.$state].color};
  border-radius: 100px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
`;
