import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  font-family: "BasierCircle-Medium";
  font-size: 20px;
  text-align: center;
  color: silver;
`;
