import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";
import { motion } from "../../pages/tokens";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export enum State {
  hover = "hovered",
  active = "active",
  disabled = "disabled",
  pressed = "pressed",
}

interface Props {
  label?: string;
  icon?: ico;
  showicon?: boolean;
  theme?: "white" | "blue" | "orange" | string;
  type?: "primary" | "secondary";
  onClick?: () => void;
  state?: State;
  transition?: any;
  animate?: boolean;
  navigate?: string | number;
  length?: "auto" | number;
}

export default function PrimaryButton(props: Props) {
  const {
    label = null,
    icon = ico.carretUp,
    showicon = true,
    state = State.active,
    type = "secondary",
    theme = "blue",
    transition = motion.spring,
    navigate = null,
    length = "auto",
    animate = false,
    onClick = () => null,
  } = props;
  const doNavigation = useNavigate();
  const [btnState, setBtnState] = useState(state);
  useEffect(() => setBtnState(state), [state]);

  const setIcon = () => {
    if (!showicon) return null;
    let iconTheme = theme;
    if (theme === "blue" && type === "secondary") iconTheme = "blueButton";
    return <Icon name={icon} size={24} animate={animate} theme={iconTheme} />;
  };

  const setLabel = () => {
    return label ? label : null;
  };

  const handleClick = () => {
    if (navigate) {
      if (isNaN(+navigate)) doNavigation("/" + navigate);
      doNavigation(-Math.abs(+navigate));
      return;
    }
    onClick();
  };

  const iconOnly = () => {
    if (icon && !label) return true;
    return false;
  };

  const checkDisabled = (variant: any) => {
    if (btnState === State.disabled) return null;
    return variant;
  };

  return (
    <Styled.Button
      $theme={theme}
      $state={btnState}
      $type={type}
      $icononly={iconOnly()}
      $length={length}
      variants={Styled.themeStyles[theme][type]}
      initial={Styled.themeStyles[theme][type][btnState]}
      animate={Styled.themeStyles[theme][type][btnState]}
      transition={transition}
      whileHover={checkDisabled(Styled.themeStyles[theme][type].hover)}
      whileTap={checkDisabled(Styled.themeStyles[theme][type].pressed)}
      onClick={() => handleClick()}
    >
      {setIcon()}
      {setLabel()}
    </Styled.Button>
  );
}
