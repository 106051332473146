import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const NewMarket = {
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "What markets or countries are you looking to expand into?",
      alt: "Countries or markets",
      description:
        "Some countries have unique legal and compliance rules that may require unique service levels.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many employees do you anticipate hiring or relocating to this market initially?",
      alt: "Number of employees",
      description:
        "Depending on the number of employees or people relocating, you may have access to size discounts and benefits.",
      responses: [
        {
          options: Data.numberof,
          fieldname: "employees",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What's your projected timeline?",
      alt: "Timeline",
      description:
        "How quickly you need to gain an operational presence may drive the need for more robust legal, HR and compliance support from our team.",
      responses: [
        {
          options: Data.timeframe,
          fieldname: "timeframe",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title:
        "What are your key priorities when you consider your operational needs to move into this new market?",
      alt: "Priorities",
      description:
        "We'll make sure your proposed configuration and pricing emphasizes supporting those area that you feel are most critical to success.",
      responses: [
        {
          options: Data.priorities,
          fieldname: "priorities",
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};

export default NewMarket;
