import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";

interface MeetingSummary {
  title?: string;
  hasVideo?: boolean;
  description?: string;
}
export default function EventSummary(props: MeetingSummary) {
  const {
    title = "Intros and Q&A",
    description = "Introductions to G-P, general Q&A as well as discussion of the project you have in mind.\n\nNote: While all calls are video enabled you're not required to turn on your camera. Video simply makes sharing documents and other information easier for everyone.",
    hasVideo = true,
  } = props;
  return (
    <Styled.EventInfo>
      <Styled.MeetingDetails>
        <Styled.DetailsItem>
          <Icon name={ico.timerFull} theme={"white"} size={22} />
          30 mins.
        </Styled.DetailsItem>
        <Styled.DetailsItem>
          <Icon name={ico.phoneFull} theme={"white"} size={22} />
          Phone call
        </Styled.DetailsItem>
        <Styled.DetailsItem>
          <Icon name={ico.videoFull} theme={hasVideo ? "white" : "system"} size={22} />
          Video (optional)
        </Styled.DetailsItem>
      </Styled.MeetingDetails>
      <Styled.MeetingTitle>{title}</Styled.MeetingTitle>
      <Styled.MeetingDescription>{description}</Styled.MeetingDescription>
    </Styled.EventInfo>
  );
}
