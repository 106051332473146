export const wrapperStyle = (props: any) => {
  return {
    display: "flex",
    flexDirection: "row" as any,
    justifyContent: "center",
    alignItems: "start",
    flexWrap: "wrap" as any,
    rowGap: 16,
    gap: props.gap,
    overflow: "visible",
  };
};

export const indicatorStyle = (props: any) => {
  return {
    width: props.size,
    height: props.size,
    borderRadius: "100%",
    cursor: "pointer",
    zIndex: 10,
  };
};

export const logoStyle = (props: any) => {
  return {
    display: "flex",
    flexDirection: "row" as any,
    justifyContent: "center",
    alignItems: "center",
    gap: 0,
    overflow: "visible",
    cursor: "pointer",
    zIndex: 10,
  };
};
