//import usePageData from "../../callbacks/usePageData";
import * as Styled from "./Styles";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useEffect, useState, useRef } from "react";
import { color } from "../tokens";
import ModalOverlay from "../../components/ModalOverlay/ModalOverlay";
import CallBack from "../../components/CallBack/CallBack";
import SurveyPagging from "../../components/SurveyPagging/SurveyPagging";
import useResizeObserver from "../../callbacks/useResizeObserver";
import InfoPanel from "../../components/InfoPanel/InfoPanel";
import { AnimatePresence } from "framer-motion";
import { motion as trans } from "../tokens";
import ScheduleMeeting from "../../components/ScheduleMeeting/ScheduleMeeting";
import ContactForm from "../../components/ContactForm/ContactForm";
import ConfirmMeeting from "../../components/ConfirmMeeting/ConfirmMeeting";
import ContactHero from "../../components/ContactHero/ContactHero";
import { GIAFab } from "../../components/GIAFab/GIAFab";
import { useUserInfo } from "../../contexts/UserInfo";

type Info = {
  title: string;
  text: string;
};
const enum Direction {
  forward = 1,
  back = -1,
}
const clearInfo = {
  show: false,
  info: {
    title: null,
    text: null,
  },
};

const Pages = ["Summary", "Select Day and Time", "Contact Info.", "Confirmation"];

export default function Quote() {
  const { userData, setUserData } = useUserInfo();
  const [index, setIndex] = useState(0); // track current index in the stack
  const [direction, setDirection] = useState(1);
  const [infoPanel, setInfoPanel] = useState(clearInfo);
  const [progress, setProgress] = useState(1); // start at one becuase first 2 pages are free to nav
  const [theme, setTheme] = useState("orange");
  const [showModal, setShowModal] = useState(false);
  const ref = useRef(null); // we'll use the ref to attach to the resize ovserver
  const size = useResizeObserver(ref); // we'll use page width/height to bound animation so listen for changes

  /**
   * We'll use this to update content styles, themes, etc. based om the
   * level of progress through the survey
   */
  useEffect(() => {
    setTheme(index > 0 ? "white" : "orange");
    document.body.style.backgroundColor = index > 0 ? color.graysWhite : color.warning200;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.backgroundColor = "#fff";
      document.body.style.overflow = "auto";
    };
  }, [index]);

  // values for the horizontal pagging animation
  const amt = () => {
    return size?.height ? size.height : 1000;
  };
  const scrollVariants = {
    enter: (direction: Direction) => {
      let y = direction === Direction.forward ? amt() : -amt();
      return { y: y, opacity: 0 };
    },
    animate: { y: 0, opacity: 1 },
    exit: (direction: Direction) => {
      let y = direction === Direction.forward ? -amt() : amt();
      return { y: y, opacity: 0 };
    },
  };

  /** Navigates through survey: +/- or by direct push to specific index  */
  const navPage = (i: number, goto: boolean = false) => {
    // update the index to display the cotrrect content
    if (goto) {
      setIndex(i);
      setDirection(i < index ? Direction.back : Direction.forward);
    } else if (index + i >= 0 && i <= 2) {
      // set the aboslute progress for the pagging
      if (i > 0 && index + 1 > progress) setProgress(index + 1);
      setDirection(i < 0 ? Direction.back : Direction.forward);
      setIndex(index + i);
    }
  };

  /** displays info panel or hides it if the same text os already shown */
  const showInfo = (info: Info) => {
    if (!infoPanel.show || (infoPanel.show && infoPanel.info !== info)) {
      setInfoPanel({ show: true, info: info });
    } else {
      setInfoPanel({ show: false, info: info });
    }
  };

  /**
   * Grab the right question compoenent or hero and display it loading all events, data
   * etc. for the actively dislaplyed question as needed.
   */
  const setCurrentPage = () => {
    if (index === 1)
      return (
        <ScheduleMeeting
          navigate={(i: number) => navPage(i)}
          clickInfo={(info: Info) => showInfo(info)}
        />
      );
    if (index === 2) {
      return (
        <ContactForm
          navigate={(i: number) => navPage(i)}
          context={"contact"}
          clickInfo={(info: Info) => showInfo(info)}
          onChange={(v: any) => setUserData({ contactInfo: v })}
          values={userData.contactInfo}
        />
      );
    }
    if (index === 3) return <ConfirmMeeting name={userData.contactInfo.name} />;
    return (
      <ContactHero
        showCallBackModal={(show: boolean) => setShowModal(show)}
        navgiate={(i: number) => navPage(i)}
      />
    );
  };

  //** transtion info for displing the right info panel */
  const viewportVariants = {
    showinfo: { marginRight: 350, borderRight: "1px solid rgba(0,0,0,1)" },
    default: { marginRight: 0, borderRight: "1px solid rgba(0,0,0,0)" },
  };

  return (
    <Styled.Viewport
      variants={viewportVariants}
      initial={"default"}
      transition={{ ...trans.easIn, duration: 0.25 }}
      animate={infoPanel.show ? "showinfo" : "default"}
    >
      <ModalOverlay
        title={"Get a Call Back"}
        subtitle={"We'll call you back within the next few minutes."}
        visible={showModal}
        size={{ width: 400 }}
        cancelClick={() => setShowModal(false)}
      >
        <CallBack
          contactInfo={userData.contactInfo}
          contactChange={(info) => setUserData({ contactInfo: info })}
          doneClick={() => setShowModal(false)}
          cancelClick={() => setShowModal(false)}
        />
      </ModalOverlay>
      <PageHeader theme={theme} context={"contact"} />
      <SurveyPagging
        pages={Pages}
        progress={progress}
        selected={index}
        onChange={(i) => navPage(i, true)}
        right={infoPanel.show ? 374 : 40}
      />
      <InfoPanel
        title={infoPanel.info.title}
        text={infoPanel.info.text}
        didClose={(show, info) => setInfoPanel({ show: show, info: info })}
      />
      <Styled.ContactPage ref={ref}>
        <AnimatePresence initial={false} custom={direction}>
          <Styled.Content
            custom={direction}
            transition={{ ...trans.easIn, duration: 0.85 }}
            variants={scrollVariants}
            initial={"enter"}
            animate={"animate"}
            exit={"exit"}
            key={"page_" + index}
          >
            {setCurrentPage()}
          </Styled.Content>
        </AnimatePresence>
      </Styled.ContactPage>
      <GIAFab />
    </Styled.Viewport>
  );
}
