import { createContext, useContext, useState } from "react";
import { initialMessages, shortIntro } from "../components/GIADrawer/GIAData";
import {
  ChatMessage,
  LiveConnection,
  MessageSender,
  NextAction,
} from "../components/EnumsAndTypes/EnumsAndTypes";
import { fetchChatGPTResponse } from "../components/ChatGPT/ChatGPT";
import { useNavigate } from "react-router-dom";

const GIAContext = createContext(null);

const greetingMessage: Array<ChatMessage> = [
  {
    message: {
      text: "Hi Janice 👋. I'm Camila from G-P. How can I help you today?",
    },
    sender: MessageSender.GIA,
  },
  {
    message: {
      text: "FYI, if you'd like to connect with audio we can talk over the phone or we can connect directly via internet.",
    },
    actions: [
      { name: "Connect via phone call", type: "audio" },
      { name: "Connect via internet audio", type: "audio" },
    ],
    sender: MessageSender.GIA,
  },
];

interface Props {
  children?: any;
}

function GIAProvider(props: Props) {
  const { children } = props;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<GIANotification[]>([]);
  const [showGIAMessages, setShowGIAMessages] = useState<boolean>(false);
  const [chatGTPThinking, setChatGTPThinking] = useState(false);
  const [messages, setMessages] = useState(null);
  const [previousMessages, setPreviousMessages] = useState(null);
  const [lastMessage, setLastMessage] = useState<ChatMessage>(null);
  const [connected, setConnected] = useState(LiveConnection.Disconnected);
  const [didPush, setDidPush] = useState(false);

  // useEffect(() => {
  //   //if (showGIAMessages && !messages) setMessages(initialMessages);
  // }, [showGIAMessages, messages]);

  const handleSendMessage = async (newMessage: ChatMessage) => {
    //create array of old messages adding new one
    const newMessages = [...messages, newMessage];
    // dislplay the new message in the chat UI
    setMessages(newMessages);
    // set thinking state will getting response from GTP
    setChatGTPThinking(true);
    // send the new message and other messages to chatGPT and wait response
    const { message } = await fetchChatGPTResponse(newMessages);
    // process the mesage into the Chat UI
    const error = "Hmm... Something went wrong. What was that you asked?";
    const responseMessage = createNewMessage(message ? message.content : error);
    // set the new message list and display it
    setChatGTPThinking(false);
    const messagesWithResponse = [...newMessages, responseMessage];
    setMessages(messagesWithResponse);
    if (message.next) handleChatFunction(message.next, messagesWithResponse);
  };

  function handleChatFunction(nextAction: NextAction, messageList: ChatMessage[]) {
    let msg: ChatMessage = null;
    let redirect: string = null;
    switch (nextAction) {
      case NextAction.signUp:
        msg = createNewMessage("Would you like to create an account now?");
        break;
      case NextAction.createAccount:
        redirect = "/account";
        msg = createNewMessage("Ok. Done.");
        break;
    }
    if (redirect) {
      if (msg) {
        const newMessages = [...messageList, msg];
        setMessages(newMessages);
      }
      setTimeout(() => {
        setShowGIAMessages(false);
        navigate(redirect);
      }, 1500);

      return;
    }
    if (msg) {
      setChatGTPThinking(true);
      setTimeout(() => {
        setChatGTPThinking(false);
        const newMessages = [...messageList, msg];
        setMessages(newMessages);
      }, 1500);
      return;
    }
  }

  function createNewMessage(message: string = ""): ChatMessage {
    const newMessage: ChatMessage = {
      message: { text: message },
      sender: MessageSender.GIA,
      display: true,
    };
    return newMessage;
  }

  function pushGIA(message: string = "") {
    if (didPush) return;
    const newMessage = createNewMessage(message);
    let newMessages: ChatMessage[] = null;
    setShowGIAMessages(true);
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) newMessages = [...shortIntro, newMessage];
      else newMessages = [...messages, newMessage];
      setMessages(newMessages);
    }, 1500);
    setDidPush(true);
  }

  function introduceGIA() {
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      setMessages(initialMessages);
    }, 1500);
  }

  const connectLiveAgent = () => {
    let timer = null;
    setPreviousMessages(messages);
    setShowGIAMessages(true);
    if (connected === LiveConnection.Disconnected) {
      setConnected(LiveConnection.Connecting);
      setChatGTPThinking(true);
      const connectMsg = createNewMessage("Connecting you to a live agent");
      setMessages([connectMsg]);
      timer = setTimeout(() => {
        setMessages(greetingMessage);
        setConnected(LiveConnection.Connected);
        setChatGTPThinking(false);
      }, 4000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  };

  const disconnectLiveAgent = () => {
    let timer = null;
    if (connected === LiveConnection.Connected) {
      setConnected(LiveConnection.Disconnecting);
      timer = setTimeout(() => {
        if (previousMessages) setMessages(previousMessages);
        else setMessages(initialMessages);
        setConnected(LiveConnection.Disconnected);
      }, 2000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  };

  return (
    <GIAContext.Provider
      value={{
        notifications,
        showGIAMessages,
        messages,
        chatGTPThinking,
        lastMessage,
        connected,
        previousMessages,
        didPush,
        setPreviousMessages,
        setConnected,
        setChatGTPThinking,
        setMessages,
        setNotifications,
        setShowGIAMessages,
        handleSendMessage,
        setLastMessage,
        createNewMessage,
        introduceGIA,
        disconnectLiveAgent,
        connectLiveAgent,
        pushGIA,
      }}
    >
      {children}
    </GIAContext.Provider>
  );
}

function useGIA() {
  const context = useContext(GIAContext);
  return context;
}

export { GIAProvider, useGIA };

export type GIANotification = {
  title?: string;
  text?: string;
};
