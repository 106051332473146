import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  ${tokens.type.pSmall};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
`;

export const Progress = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  box-sizing: border-box;
  gap: 0px;
  width: 100px;
  height: 100px;
`;

export const CheckWrapper = styled.div<{ $size: number }>`
  position: absolute;
  opacity: 0;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  scale: 1;
`;

export const CircleWrapper = styled.div<{ $size: number }>`
  position: absolute;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 32px 32px 32px;
  gap: 16px;
  width: 100%;
  flex: 1 0 100%;
  align-self: stretch;
  justify-content: center;
  border-bottom: 1px solid ${tokens.color.grays300};
  border-top: 1px solid ${tokens.color.grays300};
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px 32px;
  gap: 8px;
  justify-content: center;
  width: 100%;
  flex: 0 1 1;
`;

export const Message = styled.div`
  ${tokens.type.pSmall};
  line-height: 1.5em;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px 32px;
  margin-bottom: 8px;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${tokens.color.grays300};
  border-radius: 8px;
  background-color: ${tokens.color.grays200};
  color: ${tokens.color.graysTextPrimary};
`;
