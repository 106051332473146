import InputField from "../InputField/InputField";
import TextArea from "../TextArea/TextArea";
import * as Styled from "./Styles";
import { useState } from "react";

type Size = {
  height?: number | string;
  width?: number | string;
};

interface Props {
  label?: string;
  value?: string;
  errormessage?: string;
  blurred?: boolean;
  control?: "input" | "textarea";
  type?: "text" | "phone" | "email";
  isValid?: boolean;
  fieldname?: string;
  required?: boolean;
  theme?: "unframed" | "framed" | "white";
  onChange?: (value: any) => void;
  didValidate?: (valid: boolean) => void;
  onFocus?: () => void;
  placeholder?: string;
  size?: Size;
  fill?: boolean;
}
export default function FormField(props: Props) {
  const {
    label = "Label",
    value = "",
    isValid = true,
    control = "input",
    errormessage = "This is an error message",
    blurred = true,
    theme = "framed",
    type = "text",
    fieldname = label,
    required = true,
    placeholder = "",
    onChange = () => null,
    didValidate = () => null,
    onFocus = () => null,
    size = null,
    fill = false,
  } = props;
  const [text, setText] = useState(value);
  const [valid, setValid] = useState(isValid);
  const [isBlurred, setIsBlurred] = useState(blurred);

  const handleChange = (value: string) => {
    setText(value);
    onChange(value);
  };

  const handleValidation = (state: boolean) => {
    setValid(state);
    didValidate(state);
  };

  const renderInput = () => {
    return (
      <InputField
        placeholder={placeholder}
        theme={theme}
        value={value}
        type={type}
        name={fieldname}
        isValid={isValid}
        state={"blurred"}
        required={required}
        onChange={(value: string) => handleChange(value)}
        didValidate={(isvalid) => handleValidation(isvalid)}
        didBlur={(state) => setIsBlurred(state)}
        onFocus={() => onFocus()}
      />
    );
  };

  const renderTextArea = () => {
    return (
      <TextArea
        placeholder={placeholder}
        size={size}
        value={value}
        name={fieldname}
        isValid={isValid}
        state={"blurred"}
        required={required}
        didValidate={(isvalid) => handleValidation(isvalid)}
        onChange={(value: string) => handleChange(value)}
      />
    );
  };

  return (
    <Styled.FieldContainer $fill={fill}>
      <Styled.FieldName>{label}</Styled.FieldName>
      {control === "input" ? renderInput() : renderTextArea()}
      <Styled.ErrorMessage $show={isBlurred && !valid && text !== ""}>
        {errormessage}
      </Styled.ErrorMessage>
    </Styled.FieldContainer>
  );
}
