import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import imgDoneLight from "../../assets/images/done_dark@16_light.svg";
import imgDoneDark from "../../assets/images/done_dark@16_dark.svg";

const heroThemes = {
  blue: {
    text: tokens.color.graysWhite,
    bg: tokens.color.primaryGPBlue,
    check: imgDoneLight,
  },
  orange: {
    text: tokens.color.graysTextPrimary,
    bg: tokens.color.warning200,
    check: imgDoneDark,
  },
};

export const HeroWrapper = styled.div<{ $heroTheme: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
  flex: 1 0 auto;
  color: ${(props) => heroThemes[props.$heroTheme].text};
`;

export const HeroBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HeroBodyText = styled.p`
  ${tokens.type.pRegular};
  margin: 0px;
  padding: 0px;
`;

export const HeroTop = styled.div<{ $heroTheme: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  flex: 1 0 auto;
  background-color: ${(props) => heroThemes[props.$heroTheme].bg};
`;

export const HeroBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tokens.color.graysWhite};
  padding: 74px 32px;
`;

export const HeroContent = styled.div<{ $heroContext: string }>`
  display: flex;
  flex-direction: ${(p) => (p.$heroContext === "quote" ? "row" : "row-reverse")};
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: 0px 32px;
  gap: 32px;
`;

export const List = styled.ul<{ $heroContext: string }>`
  ${tokens.type.pRegular};
  margin: 0px;
  padding: 0px;
  opacity: ${(p) => (p.$heroContext === "quote" ? "0.8" : "1")};
`;

export const ListItem = styled.li<{ $heroTheme: string }>`
  ${tokens.type.pRegular};
  list-style-position: outside;
  list-style-image: url(${(props) => heroThemes[props.$heroTheme].check});
  list-style-type: circle;
  margin-left: 20px;
  margin-top: 8px;
  line-height: 1.2em;
  padding: 0px;
`;

export const HeroImage = styled.div<{ $heroContext: string }>`
  display: flex;
  overflow: visible;
  justify-content: ${(p) => (p.$heroContext === "quote" ? "flex-end" : "flex-start")};
  align-items: center;
  min-width: 0px;
  max-width: 250px;
  height: 427px;
  flex: 1 0 0;
`;

export const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: auto;
  max-width: 507px;
  min-width: 432px;
  flex: 1 0 0;
`;

export const H4 = styled.h4`
  ${tokens.type.sectionTitle};
  margin: 0px;
  padding: 0px;
  opacity: 0.75;
`;

export const H1 = styled.h1`
  ${tokens.type.h1};
  margin: 0px;
  padding: 0px;
`;
