import styled from "styled-components";
import { motion } from "framer-motion";

export const Overlay = styled(motion.div)<{ $opacity: number }>`
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, ${(p) => p.$opacity});
`;
