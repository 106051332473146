import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const variants = {
  framed: {
    blurred: { borderColor: tokens.color.grays100 },
    focused: { borderColor: tokens.color.primaryGPBlue },
    disabled: { borderColor: tokens.color.grays100 },
    invalid: { borderColor: tokens.color.error },
  },
  unframed: {
    blurred: { borderColor: tokens.color.grays100 },
    focused: { borderColor: tokens.color.primaryGPBlue },
    disabled: { borderColor: tokens.color.grays100 },
    invalid: { borderColor: tokens.color.error },
  },
  white: {
    blurred: { borderColor: tokens.color.grays100 },
    focused: { borderColor: tokens.color.primaryGPBlue },
    disabled: { borderColor: tokens.color.grays100 },
    invalid: { borderColor: tokens.color.error },
  },
  clear: {
    blurred: { borderColor: "transparent" },
    focused: { borderColor: "transparent" },
    disabled: { borderColor: "transparent" },
    invalid: { borderColor: "transparent" },
  },
};

const setPadding = (theme: string, showicon: boolean) => {
  const framed = theme === "framed" || theme === "white";
  if (framed && showicon) return "padding: 0px 8px 0px 8px";
  if (framed && !showicon) return "padding: 0px 8px 0px 16px";
  return "padding: 0 8px";
};

const setBG = (theme: string) => {
  if (theme === "unframed") return "transparent";
  if (theme === "framed") return tokens.color.grays100;
  if (theme === "clear") return "transparent";
  return tokens.color.graysWhite;
};

const setWidth = (width: string | number, fill: boolean) => {
  if (fill) return "auto";
  if (typeof width === "number") return width + "px";
  return width;
};

const setFill = (fill: boolean) => {
  if (fill) return "flex: 1;";
  return null;
};

export const FieldWrapper = styled(motion.div)<{
  $showicon: boolean;
  $theme: string;
  $width: number | string;
  $fill: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  min-height: 48px;
  ${(p) => setFill(p.$fill)}
  width: ${(p) => setWidth(p.$width, p.$fill)};
  gap: 8px;
  ${(p) => setPadding(p.$theme, p.$showicon)};
  border-radius: ${(p) => (p.$theme === "unframed" ? "0px" : "8px")};
  background-color: ${(p) => setBG(p.$theme)};
  border: ${(p) => (p.$theme === "unframed" ? "0px" : "1px")} solid ${tokens.color.grays100};
`;

export const RightIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LeftIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: default;
`;

export const ErrorIcon = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputField = styled.input<{ $password?: boolean }>`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextTertiary};
  letter-spacing: ${(p) => (p.$password ? "0.2em" : "0")};
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  &::placeholder {
    color: ${tokens.color.graysTextDisabled};
  }
`;
