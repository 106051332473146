import { AnimatePresence, useAnimate } from "framer-motion";
import { useGIA } from "../../contexts/GIAContext";
import * as Styled from "./Styles";
import GIAIcon from "../../assets/images/GIAIcon";
import { useEffect, useState } from "react";
import { initialMessages } from "../GIADrawer/GIAData";

interface Props {}

export function GIAFab(props: Props) {
  const { notifications, showGIAMessages, setShowGIAMessages, messages, introduceGIA } = useGIA();
  const [fab, animateFab] = useAnimate();
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    if (!fab?.current) return;
    if (notifications.length > 0 && !hovered && !showGIAMessages) {
      animateFab(
        fab.current,
        { scale: [1, 1.2, 1] },
        { repeat: Infinity, repeatType: "mirror", duration: 0.25, repeatDelay: 5, delay: 2 }
      );
      return;
    }
    if (!showGIAMessages && !hovered) {
      animateFab(fab.current, { y: 0 }, { ease: "easeInOut", duration: 0.25, delay: 0.2 });
      animateFab(fab.current, { scale: 1, rotate: 0 }, { ease: "easeInOut", duration: 0.5 });
      return;
    }
    if (showGIAMessages) {
      animateFab(fab.current, { scale: 1, rotate: 270 }, { ease: "easeInOut", duration: 0.5 });
      animateFab(fab.current, { y: 100 }, { ease: "easeInOut", duration: 0.25, delay: 0.2 });
      return;
    }
  }, [fab, animateFab, notifications, hovered, showGIAMessages]);

  const handleShowGIAMessages = () => {
    setShowGIAMessages(true);
    if (!messages) introduceGIA();
    animateFab(fab.current, { scale: 1, rotate: 270 }, { ease: "easeInOut", duration: 0.5 });
    animateFab(fab.current, { y: 100 }, { ease: "easeInOut", duration: 0.25, delay: 0.2 });
  };

  return (
    <AnimatePresence initial={false}>
      <Styled.FABContainer
        ref={fab}
        initial={{ rotate: 0, y: 100 }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => handleShowGIAMessages()}
        style={{ cursor: "pointer" }}
      >
        {notifications.length > 0 && <Styled.Badge>{notifications.length}</Styled.Badge>}
        <GIAIcon />
      </Styled.FABContainer>
    </AnimatePresence>
  );
}
