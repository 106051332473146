import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const colorThemes = {
  default: {
    strokeColor: tokens.color.graysTextPrimary,
    backgroundColor: tokens.color.graysWhite,
    borderColor: tokens.color.grays400,
    stroke: 2,
  },
  checked: {
    strokeColor: tokens.color.graysWhite,
    backgroundColor: tokens.color.primaryGPBlue,
    borderColor: tokens.color.primaryGPBlue,
    stroke: 2,
  },
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
