import * as Styled from "./Styles";
import { useNavigate } from "react-router-dom";
import * as tokens from "../../pages/tokens";

interface Props {
  type?: "quote" | "contact";
  transition?: any;
  hoverScale?: number;
  pressScale?: number;
  text?: string;
}

export default function StartButton(props: Props) {
  const {
    type = "quote",
    transition = tokens.motion.spring,
    hoverScale = 1.04,
    pressScale = 0.98,
    text = null,
  } = props;
  const navigate = useNavigate();

  const setText = () => {
    const quote = "Configure and price your solution in less than five minutes";
    const contact = "Talk with one of our global experts about your needs";
    if (!text) {
      if (type === "quote") return quote;
      return contact;
    }
    return text;
  };

  const doNav = () => {
    // !!--update==!! nav refences to the right pages
    if (type === "quote") {
      navigate("/quote");
      return;
    }
    if (type === "contact") {
      navigate("/contact");
      return;
    }
    navigate("/get-started");
    return;
  };

  return (
    <Styled.MotionButton
      transition={transition}
      whileHover={{
        scale: hoverScale,
        boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.05)",
      }}
      whileTap={{
        scale: pressScale,
      }}
      onClick={() => doNav()}
    >
      <Styled.Image type={type} />
      <Styled.TextWrapper>
        <Styled.Text>
          {setText()}
          <Styled.Glyph>a</Styled.Glyph>
        </Styled.Text>
      </Styled.TextWrapper>
    </Styled.MotionButton>
  );
}
