import styled from "styled-components";
//import * as tokens from "../../pages/tokens";

export const QuestionWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  flex: 1 0 0;
  gap: 0px;
`;
