interface Props {
  height?: number;
}
export default function Doc(props: Props) {
  const { height = 39 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 31 39">
      <path
        d="M 1 36 L 1 3 C 1 1.895 1.895 1 3 1 L 17.384 1 C 19.041 1 20.384 2.343 20.384 4 L 20.384 8.361 C 20.384 9.466 21.279 10.361 22.384 10.361 L 27 10.361 C 28.657 10.361 30 11.704 30 13.361 L 30 36 C 30 37.105 29.105 38 28 38 L 3 38 C 1.895 38 1 37.105 1 36 Z"
        fill="rgb(233, 233, 224)"
        stroke="rgb(233, 233, 224)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
      <path
        d="M 20 9 L 20 2.5 L 28.5 11 L 22 11 C 20.895 11 20 10.105 20 9 Z"
        fill="rgb(217, 215, 202)"
        stroke="rgb(217, 215, 202)"
        strokeMiterlimit="10"
        strokeDasharray=""
      ></path>
    </svg>
  );
}
