import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";
import { useState } from "react";
import Flag, { Country } from "../../assets/images/flags/Flag";
import { color } from "../../pages/tokens";

export enum ButtonType {
  default,
  multiline,
  country,
}

export enum State {
  disabled = "disabled",
  active = "active",
  hover = "hover",
  selected = "selected",
}

type Size = {
  width?: number | string;
  height?: number | string;
};

type Info = {
  title: string;
  text: string;
};

type ButtonInfo = {
  text?: string[2] | string;
};

interface Props {
  type?: ButtonType;
  text?: ButtonInfo;
  state?: State;
  selected?: boolean;
  info?: Info;
  theme?: "white" | "blue" | "orange" | string;
  size?: Size;
  pad?: number;
  country?: Country | string;
  onChange?: (selected: boolean) => void;
  clickInfo?: (info: Info) => void;
}

export default function SurveyButton(props: Props) {
  const {
    type = ButtonType.default,
    text = "Button Label",
    selected = false,
    info = null,
    pad = null,
    theme = "blue",
    size = { width: 200, height: 100 },
    country = Country.USA,
    clickInfo = () => null,
    onChange = () => null,
  } = props;
  const [showInfo, setShowInfo] = useState(false);

  const handleClick = () => {
    onChange(!selected);
  };

  const setText = () => {
    if (typeof text === "string") return text;
    return text[0];
  };

  const setDescription = () => {
    if (typeof text === "string") return null;
    return text[1];
  };

  const DefaultBtn = () => {
    return (
      <Styled.Default
        variants={Styled.variants[theme]}
        initial={Styled.variants[theme].active}
        whileHover={{ scale: 1.04 }}
        whileTap={{ scale: 0.98 }}
        animate={selected ? Styled.variants[theme].selected : Styled.variants[theme].active}
        onClick={() => handleClick()}
      >
        {setText()}
      </Styled.Default>
    );
  };

  const MultilineBtn = () => {
    return (
      <Styled.MultiLine
        $size={size}
        variants={Styled.variants[theme]}
        initial={Styled.variants[theme].active}
        whileHover={{ scale: 1.04 }}
        whileTap={{ scale: 0.98 }}
        animate={selected ? Styled.variants[theme].selected : Styled.variants[theme].active}
        onMouseOver={() => setShowInfo(true)}
        onMouseOut={() => setShowInfo(false)}
      >
        <Styled.Info $show={info && showInfo} onClick={() => clickInfo(info)}>
          <Icon name={ico.info} theme={selected ? "blue" : "blueButton"} />
        </Styled.Info>
        <Styled.MultiLineWrapper onClick={() => handleClick()}>
          {setText()}
          <Styled.MultiLineSubtext
            initial={{ color: color.graysTextDisabled }}
            animate={
              selected
                ? { color: color.graysWhite, opacity: 0.7 }
                : { color: color.graysTextDisabled, opacity: 1 }
            }
          >
            {setDescription()}
          </Styled.MultiLineSubtext>
          <p></p>
        </Styled.MultiLineWrapper>
      </Styled.MultiLine>
    );
  };

  const CountryBtn = () => {
    return (
      <Styled.Country
        $pad={pad}
        variants={Styled.variants[theme]}
        initial={Styled.variants[theme].active}
        whileHover={{ scale: 1.04 }}
        whileTap={{ scale: 0.98 }}
        animate={selected ? Styled.variants[theme].selected : Styled.variants[theme].active}
        onClick={() => handleClick()}
      >
        <Styled.Flag $on={selected}>
          <Flag country={country in Country ? Country[country] : Country.USA} />
        </Styled.Flag>
        {country in Country ? Country[country] : text}
      </Styled.Country>
    );
  };

  if (type === ButtonType.default) return DefaultBtn();
  if (type === ButtonType.multiline) return MultilineBtn();
  if (type === ButtonType.country) return CountryBtn();
}
