import { useEffect, useState } from "react";
import * as Styled from "./Styles";
import SurveyButton, { ButtonType } from "../SurveyButton/SurveyButton";
import { Country } from "../../assets/images/flags/Flag";

type Info = {
  title: string;
  text: string;
};

type Size = {
  width?: number | string;
  height?: number | string;
};

type ButtonInfo = {
  text?: string[2] | string;
};

type Option = {
  text?: ButtonInfo;
  info?: Info;
  country?: Country;
};

interface Props {
  options?: Option[] | any;
  selected?: boolean[];
  multiselect?: boolean;
  type?: ButtonType;
  size?: Size;
  gap?: number;
  pad?: number;
  onChange?: (values: any[]) => void;
  clickInfo?: (info: Info) => void;
}

export default function SurveyButtonList(props: Props) {
  const {
    options = null,
    selected = null,
    multiselect = true,
    type = ButtonType.default,
    size = { width: 100, height: 100 },
    gap = null,
    pad = null,
    onChange = () => null,
    clickInfo = () => null,
  } = props;
  const [items, setItems] = useState(options);
  const [values, setValues] = useState(selected);
  useEffect(() => setItems(options), [options]);

  // initialize all selections to null
  useEffect(() => {
    items && !selected
      ? setValues(new Array(items.length - 1).fill(null))
      : setValues(selected);
  }, [selected, items]);

  const handleSelection = (i: number) => {
    let temp: any;
    multiselect
      ? (temp = [...values])
      : (temp = new Array(items.length - 1).fill(null));
    temp[i] = !values[i];
    setValues(temp);
    onChange(temp);
  };

  return (
    <Styled.ButtonList $gap={gap}>
      {items &&
        items.map((item, i) => {
          return (
            <SurveyButton
              key={"survey_button_" + i}
              size={size}
              type={type}
              text={item.text}
              info={item.info}
              pad={pad}
              country={item.country}
              selected={values ? values[i] : false}
              onChange={() => handleSelection(i)}
              clickInfo={() => clickInfo(item.info)}
            />
          );
        })}
    </Styled.ButtonList>
  );
}
