import { useEffect, useState } from "react";
// import this pages styled componets
import * as Styled from "./Styles";
import { color, motion as trans } from "../tokens";
// import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Carousel from "../../components/Carousel/Carousel";
import Testimonial from "../../components/Testimonial/Testimonial";
import PageIndicator from "../../components/PageIndicator/PageIndicator";
// import data objects
import { Testimonials } from "../../assets/content/Testimonials/Testimonials";
import { GIAFab } from "../../components/GIAFab/GIAFab";
import { ValuePropCreateAccount } from "../../assets/content/ValueProps/ValuePropCreateAccount";
import { useGIA } from "../../contexts/GIAContext";
import InfoPanel from "../../components/InfoPanel/InfoPanel";
import { SignUpProvider } from "../../contexts/SignUp";
import { SignUp } from "../../components/SignUp/SignUp";

const clearInfo = {
  show: false,
  info: {
    title: null,
    text: null,
  },
};

export default function CreateAccount() {
  const { showGIAMessages, setShowGIAMessages } = useGIA();
  const [infoPanel, setInfoPanel] = useState(clearInfo);

  useEffect(() => {
    document.body.style.backgroundColor = color.graysWhite;
  }, []);

  useEffect(() => {
    if (showGIAMessages) setInfoPanel({ show: true, info: { title: "title", text: "text" } });
    else setInfoPanel({ show: false, info: { title: "title", text: "text" } });
  }, [showGIAMessages]);

  //** transtion info for displing the right info panel */
  const viewportVariants = {
    showinfo: { marginRight: 349, borderRight: "1px solid rgba(0,0,0,1)" },
    default: { marginRight: 0, borderRight: "1px solid rgba(0,0,0,0)" },
  };

  return (
    <SignUpProvider>
      <Styled.ViewPort
        variants={viewportVariants}
        initial={"showInfo"}
        transition={{ ...trans.easIn, duration: 0.25 }}
        animate={infoPanel.show ? "showinfo" : "default"}
      >
        <InfoPanel
          title={infoPanel.info.title}
          text={infoPanel.info.text}
          visible={infoPanel.show}
          didClose={(show, info) => {
            setInfoPanel({ show: show, info: info });
            setShowGIAMessages(false);
          }}
        />
        <PageHeader />
        <Styled.Page>
          <Styled.ValuePropContainer $textColor={"primaryText"}>
            <Styled.ValuePropWrapper>
              <ValuePropCreateAccount />
              <Carousel
                itemTemplate={<Testimonial />}
                paggingTemplate={<PageIndicator />}
                collection={Testimonials}
                paggingType={"logo"}
                theme={"light"}
              />
            </Styled.ValuePropWrapper>
          </Styled.ValuePropContainer>
          <SignUp />
        </Styled.Page>
        <GIAFab />
      </Styled.ViewPort>
    </SignUpProvider>
  );
}
/*
 <Styled.OptionsContainer>
  <AcountInfo />
</Styled.OptionsContainer>
*/
