import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const themeStyles = {
  primary: {
    disabled: {
      backgroundColor: tokens.color.graysWhite,
      color: tokens.color.graysTextDisabled,
      borderColor: tokens.color.grays200,
    },
    active: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
    },
    pressed: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.primaryGPBlue,
      borderColor: tokens.color.grays400,
      scale: 0.98,
    },
    hover: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
      scale: 1.0,
    },
  },
  secondary: {
    disabled: {
      backgroundColor: tokens.color.graysWhite,
      color: tokens.color.graysTextDisabled,
      borderColor: tokens.color.grays200,
    },
    active: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
    },
    pressed: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.primaryGPBlue,
      borderColor: tokens.color.grays400,
      scale: 0.98,
    },
    hover: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
      scale: 1.0,
    },
  },
  tertiary: {
    disabled: {
      backgroundColor: tokens.color.graysWhite,
      color: tokens.color.graysTextDisabled,
      borderColor: tokens.color.grays200,
    },
    active: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
    },
    pressed: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.primaryGPBlue,
      borderColor: tokens.color.grays400,
      scale: 0.98,
    },
    hover: {
      backgroundColor: tokens.color.grays400,
      color: tokens.color.graysSystem,
      borderColor: tokens.color.grays400,
      scale: 1.0,
    },
  },
};

const setPadding = (icononly: boolean, label: string, icon: boolean) => {
  if (!icon && label !== "") return "6px 20px 4px 14px";
  return "4px 20px 4px 20px";
};

export const Button = styled(motion.div)<{
  $state: string;
  $theme: string;
  $icononly: boolean;
  $label: string;
  $icon: boolean;
  $length: "auto" | number;
}>`
  ${tokens.type.btnRegular}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 46px;
  width: ${(p) => (p.$icononly ? "72px" : p.$length !== "auto" ? p.$length + "px" : "auto")};
  padding: ${(p) => setPadding(p.$icononly, p.$label, p.$icon)};
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: ${(p) => (p.$state === "disabled" ? "default" : "pointer")};
`;
