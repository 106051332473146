const MandA = {
  questions: [
    {
      template: "exitflow",
      title: "Let's talk",
      alt: "Mergers and Acquisitions",
      description:
        "Managing through mergers & acquisitions often has complex requirements. Let’s talk so we can better understand your unique requirements and propose a solution that fits your needs.",
      responses: null,
      required: "none",
    },
  ],
};

export default MandA;
