import Core from "./QuestionBlock/Core";
import NewMarket from "./QuestionBlock/NewMarket";
import Wrap from "./QuestionBlock/Wrap";
import Convert from "./QuestionBlock/Convert";
import Switch from "./QuestionBlock/Switch";
import Contractor from "./QuestionBlock/Contractor";
import Relo from "./QuestionBlock/Relo";
import Project from "./QuestionBlock/Project";
import MandA from "./QuestionBlock/MandA";
import WindDown from "./QuestionBlock/Winddown";
import Other from "./QuestionBlock/Other";

export const ResponseStore = {
  companysize: null,
  industry: null,
  scenarios: null,
  countries: null,
  countriesfrom: null,
  employees: null,
  contractors: null,
  recruitoptions: null,
  timeframe: null,
  priorities: null,
  reasonstoswitch: null,
  hiretype: null,
  relotype: null,
  contactinfo: null,
  transitionreasons: null,
  challenges: null,
  quotestate: false,
};

const QuestionTree = {
  wrap: Wrap,
  core: Core,
  newmarket: NewMarket,
  convert: Convert,
  switch: Switch,
  contractor: Contractor,
  relo: Relo,
  project: Project,
  manda: MandA,
  winddown: WindDown,
  other: Other,
};

export default QuestionTree;
