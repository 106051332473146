import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const WindDown = {
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "In which country or countries is your subsidiary located?",
      alt: "Countries",
      description:
        "Some countries have unique legal and compliance requirements that may require specific attention and services.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many employees are you planning to transition from your subsidiary to an EOR?",
      alt: "How many employees",
      description:
        "Depending on the number of employees or contractors you need, you may have access to size discounts and benefits.",
      responses: [
        {
          options: Data.numberof,
          fieldname: "employees",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What's your projected transition timeline?",
      alt: "Timeline",
      description:
        "How quickly you need to wind down will drive specific legal, HR and compliance support to help expedite the process.",
      responses: [
        {
          options: Data.timeframe,
          fieldname: "timeframe",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What are your primary business objectives with this transition?",
      alt: "Business Objectives",
      description:
        "Understanding what you're primarily focused on as outcomes of the transition will better help match capabilities and services we offer.",
      responses: [
        {
          options: Data.transitionreasons,
          fieldname: "transitionreasons",
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title:
        "What are your priorities and areas of focus when considering a partner for this transition?",
      alt: "Priorities",
      description:
        "Understanding what you're primarily focused on as outcomes of the transition will better help match capabilities and services we offer.",
      responses: [
        {
          options: Data.priorities,
          fieldname: "priorities",
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};

export default WindDown;
