import styled from "styled-components";
import * as tokens from "../../../pages/tokens";

export const MoreButton = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 16px 0px 16px 0px;
  padding: 0px;
`;

export const SubTitle = styled.p<{ $showtit: boolean }>`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextPrimary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  display: ${(p) => (p.$showtit ? "inherit" : "none")};
`;

export const Description = styled.p<{ $showdes: boolean }>`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextTertiary};
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
  display: ${(p) => (p.$showdes ? "inherit" : "none")};
`;
