import { useState } from "react";

// basically a helper callback/hook to faciltate using and setting
// data with multiple data items at the "page" level of a react app
export default function usePageStore(dataStore) {
    const [data, setData] = useState(dataStore)
    // update function
    const setValue = (object) => {
        let newData = { ...data }
        const keys = Object.keys(object)
        keys.forEach(key => {
            if (newData[key] !== undefined) { newData[key] = object[key] }
        })
        setData(newData)
    }
    return [ data, setValue ]
}