const Wrap = {
  questions: [
    {
      template: "contacts",
      title: "Contacts",
      alt: "Contacts",
      responses: null,
      required: "none",
    },
    {
      template: "quote",
      title: "Quote",
      alt: "Quote",
      responses: null,
      required: "none",
    },
  ],
};

export default Wrap;
