import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const OptionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 0;
  padding: 48px 24px 64px 24px;
  background-color: ${tokens.color.graysWhite};
  color: ${tokens.color.graysTextPrimary};
  z-index: 10;
  overflow: hidden;
  @media screen and ${tokens.device.tabletUp} {
    flex: 1;
    align-self: stretch;
    padding: 80px 32px;
  }
  @media screen and ${tokens.device.fixSmallDesktop} {
  }
  @media screen and ${tokens.device.largeUp} {
    align-items: flex-start;
  }
`;

export const Content = styled(motion.div)`
  position: absolute;
  boxsizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;
