import * as Styled from "./Styles";
import ProgressCheck from "../ProgressCheck/ProgressCheck";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";

interface Props {
  name?: string;
}

export default function ConfirmMeeting(props: Props) {
  const { name = null } = props;
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const setFirstName = () => {
    if (!name) return "You're ";
    const firstname = name.split(" ")[0];
    return firstname.charAt(0).toUpperCase() + firstname.slice(1) + ", you're ";
  };

  const renderMessage = () => {
    return (
      <Styled.Confirmation>
        <Styled.Title>{setFirstName()}all set.</Styled.Title>
        <Styled.Message>
          We look forward to talking with you Monday, the 16th of Sept. at 1pm. We've also sent you
          an email with meeting details and the dial-in information.
        </Styled.Message>
        <PrimaryButton
          theme={"orange"}
          showicon={false}
          type={"secondary"}
          length={150}
          label={"Done"}
          onClick={() => navigate("/get-started")}
        />
      </Styled.Confirmation>
    );
  };

  const variants = {
    enter: { opacity: 0, y: -100, height: 0 },
    animate: { opacity: 1, y: 0, height: "auto" },
    exit: { opacity: 0, y: 100 },
  };

  return (
    <Styled.Wrapper
      transition={{ ease: "easeInOut", duration: 0.25 }}
      initial={{ height: 100 }}
      animate={index === 1 ? { height: 350 } : { height: 100 }}
    >
      <ProgressCheck size={100} stroke={0.5} progressEnd={() => setIndex(1)} playing={true} />
      <AnimatePresence initial={false}>
        {index === 1 && (
          <Styled.Content
            key={"confirm_" + index}
            transition={{ ease: "easeInOut", duration: 0.25 }}
            variants={variants}
            initial={"enter"}
            animate={"animate"}
            exit={"exit"}
          >
            {index === 1 && renderMessage()}
          </Styled.Content>
        )}
      </AnimatePresence>
    </Styled.Wrapper>
  );
}
