import * as Styled from "./Styles";
import FormField from "../FormField/FormField";
import usePageStore from "../../callbacks/usePageData";

type ContactInfo = {
  first?: string;
  last?: string;
  email?: string;
  phone?: string;
  accepted: boolean;
};

const initialData = {
  first: "",
  last: "",
  email: "",
  role: null,
  accepted: true,
};

const initialValidation = {
  first: false,
  phone: false,
  email: false,
  last: false,
  accepted: true,
};

const defaultInfo = {
  first: "Dwight",
  last: " Schrute",
  phone: "(617) 384-5528",
  email: "dwights@theoffice.com",
  accepted: true,
};

const allValid = {
  first: true,
  last: true,
  phone: true,
  email: true,
  accepted: true,
};

interface Props {
  values?: ContactInfo;
  onChange?: (values: any[]) => void;
  didValidate?: (valid: boolean) => void;
}

export default function ContactFormShort(props: Props) {
  const {
    onChange = () => null,
    didValidate = () => null,
    values = null,
  } = props;
  const [validation, setValidation] = usePageStore(initialValidation);
  const [data, setData] = usePageStore(values ? values : initialData);

  const handleDidValidate = (fieldname: string, state: any) => {
    const updates = { ...validation, [fieldname]: state };
    let isValid = true;
    for (const key of Object.keys(updates)) {
      if (updates[key] === false) isValid = false;
    }
    setValidation(updates);
    didValidate(isValid);
  };

  const handleChange = (fieldname: string, value: any) => {
    setData({ [fieldname]: value });
    let updatedValues = data;
    updatedValues[fieldname] = value;
    onChange(updatedValues);
  };

  const handleAutoFill = () => {
    setData({ ...defaultInfo });
    setValidation({ ...allValid });
    onChange(defaultInfo as any);
    didValidate(true);
  };

  return (
    <Styled.FormWrapper>
      <FormField
        label="Frist name"
        fieldname="first"
        type={"text"}
        value={data.first}
        onChange={(value) => handleChange("first", value)}
        didValidate={(state) => handleDidValidate("first", state)}
        errormessage={"Please complete this required field."}
        onFocus={() => handleAutoFill()}
      />
      <FormField
        label="Last name"
        fieldname="last"
        type={"text"}
        value={data.last}
        onChange={(value) => handleChange("first", value)}
        didValidate={(state) => handleDidValidate("first", state)}
        errormessage={"Please complete this required field."}
      />
      <FormField
        label="Business email"
        fieldname="email"
        type={"text"}
        value={data.email}
        onChange={(value) => handleChange("email", value)}
        didValidate={(state) => handleDidValidate("email", state)}
        errormessage={"Please complete this required field."}
      />
      <FormField
        label="Phone number"
        fieldname="phone"
        type={"text"}
        value={data.phone}
        onChange={(value) => handleChange("phone", value)}
        didValidate={(state) => handleDidValidate("phone", state)}
        errormessage={"Please complete this required field."}
      />
    </Styled.FormWrapper>
  );
}
