import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes.ts";
import * as ChatFunctions from "./ChatFunctions";

// *********
// base settings for chtgpt
// *********
const API_KEY = "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";
export const GPTDefaults = {
  temperature: 0.2, // level of focus of responses (randomness)
  n: 1, // number of response options to return
  function_call: "auto", // force function / user calls set to "none"
  method: "POST",
  model: "gpt-4",
  headers: {
    Authorization: "Bearer " + API_KEY,
    "Content-Type": "application/json",
  },
  context: `Be conversational. Be CONCISE in all your reposnses please! You're name is GIA (Global Inteligence Agent). You work for Globalization Partners (GP), a company that helps customers take their business operations global through employer of record related services. Refer to Globalization Partners as we. When referring to EOR, globalization partners IS the EOR. Always refr to GP in the first person and avoid using epxressions that refer to EOr companies generally always refering to Globalization Partners specifically". Don't make rerences to other solution providers, only to Globalization Partners (GP). Your main function is to guide users on how to get started with employer of record solutions and to provide helpful information when asked. You're both introducing the conpcet of EOR to users that are new as well as helping existing customers understand the nuances of working with an EOR and doidng business in different countries of the world. Don't make decisions for users. Don't make assumptions about what they are looking for. Ask for clarification if a user request is ambiguous. When you respod, if there's a list of benefits, or options, pick the top three and ask the user if they would like you to present more.`,
};

// *********
// convert our message format into chatGPT's message format
// *********
export function mapToChatGPTFormat(messages: ChatMessage[]) {
  let chatGPTMessages = messages.map((message: ChatMessage, i: number) => {
    // if message is a function need to add function name to the message object
    if (message.sender === MessageSender.Function) {
      return {
        role: "function",
        name: message.function,
        content: message.message.text,
      };
    }
    // otherwsie return simple message object
    return {
      role: message.sender === MessageSender.GIA ? "assistant" : "user",
      content: message.message.text,
    };
  });
  return chatGPTMessages;
}

// *********
// format the response from chat GPT
// *********
export function formatResponse(response: any, isFunction: boolean, id: any) {
  let fResponse = null;
  if (isFunction) fResponse = ChatFunctions[response.name](response.arguments);
  const newMessage = {
    id: id,
    content: isFunction ? fResponse.content : response,
    author: isFunction ? fResponse.role : MessageSender.GIA,
    authorname: "GIA",
    next: isFunction ? fResponse.next : null,
    function_name: isFunction ? response.name : null,
  };
  //console.log(response.name);
  return { message: newMessage, type: isFunction ? response.name : null };
}

// *********
// send messages to chatGPT and get the response
// *********
export async function fetchChatGPTResponse(
  messages: ChatMessage[],
  model: string = GPTDefaults.model,
  context: string = GPTDefaults.context,
  temp: number = GPTDefaults.temperature,
  function_call: string = GPTDefaults.function_call
) {
  // will store the final response form chat GPT
  let chatGPTResponse = null;

  // need a system message to set context for how GPT responds
  const systemMessage = {
    role: "system",
    content: context,
  };

  // pull together the OpenAI completions API body parameters
  const requestBody = {
    model: model, // pass in the GPT completions model to use
    messages: [systemMessage, ...mapToChatGPTFormat(messages)], // pass in the message history
    temperature: temp, // 0-2: higher values make the output more random
    functions: ChatFunctions.functionList, // call functions
    function_call: function_call, // auto is default, but we'll be explicit
    n: GPTDefaults.n, // number of responses to return
  };

  //console.log(requestBody);

  // use the chatgpt api URL for completions to send the data and request response
  await fetch("https://api.openai.com/v1/chat/completions", {
    method: GPTDefaults.method,
    headers: GPTDefaults.headers,
    body: JSON.stringify(requestBody),
  })
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      let isFunction = false;
      let response = data.choices[0].message.content;
      if (data.choices[0].message.function_call) {
        isFunction = true; // GPT chose function call
        response = data.choices[0].message.function_call;
      }
      chatGPTResponse = formatResponse(response, isFunction, messages.length);
    })
    .catch((err) => {
      console.log(err.message); // something bad happened write to console
      chatGPTResponse = { message: null, type: null };
    });

  // return the final response
  return chatGPTResponse;
}
