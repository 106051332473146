import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  ${tokens.type.pRegular};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 24px;
  gap: 24px;
`;
export const Line = styled.div`
  border-top: 1px solid ${tokens.color.graysTextPrimary};
  width: 50px;
  height: 1px;
`;
