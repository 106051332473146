import NoPage from "./pages/NoPage/NoPage.tsx";
import Preview from "./pages/Preview/Preview.tsx";
import GetStarted from "./pages/GetStarted/GetStarted.tsx";
import { Routes, Route } from "react-router-dom";
import Quote from "./pages/Quote/Quote.tsx";
import Contact from "./pages/Contact/Contact.tsx";
import Homepage from "./pages/Home/Home.tsx";
import QuoteDetails from "./pages/QuoteDetails/QuoteDetails.tsx";
import { GIAProvider } from "./contexts/GIAContext.tsx";
import CreateAccount from "./pages/CreateAccount/CreateAccount.tsx";
import { USerInfoProvider } from "./contexts/UserInfo.tsx";

function App() {
  return (
    <USerInfoProvider>
      <GIAProvider>
        <Routes>
          <Route path="/" element={<GetStarted />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/account" element={<CreateAccount />} />
          <Route path="/quote-details" element={<QuoteDetails />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </GIAProvider>
    </USerInfoProvider>
  );
}

export default App;
