//** GIA Chat */
export const enum MessageSender {
  GIA = 0,
  Info = 1,
  System = 2,
  Function = 3,
  User = 4,
}

export type ChatMessage = {
  isResponding?: boolean;
  message?: { title?: string; text: string };
  sender?: MessageSender;
  display?: boolean;
  files?: MessageFile[];
  actions?: MessageAction[];
  function?: string;
  next?: string;
};

export const enum NextAction {
  signUp = "sign up",
  createAccount = "create account",
}

export type MessageFile = {
  name?: string;
  size?: string;
  type?: string;
  image?: any;
};

export type MessageAction = {
  name?: string;
  type?: "nav" | "other" | "link" | "audio" | string;
};

export const enum LiveConnection {
  Connected,
  Disconnected,
  Connecting,
  Disconnecting,
}

export const enum Direction {
  forward = 1,
  back = -1,
}
