import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  ${tokens.type.pSmall};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  width: 100%;
  height: 500px;
`;

export const Separator = styled.div`
  border-right: 1px solid ${tokens.color.graysWhite};
  opacity: 0.3;
  width: 1;
  height: 100%;
  margin-left: 6px;
`;

export const SelectedButton = styled.div`
  ${tokens.type.pSmall};
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 10px 8px 10px 16px;
  background-color: ${tokens.color.primaryGPBlue};
  color: ${tokens.color.graysWhite};
  border-radius: 8px;
  border: 1px solid ${tokens.color.primaryGPBlue};
  cursor: pointer;
  gap: 4px;
`;

export const CountryList = styled.div`
  ${tokens.type.pSmall};
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  row-grap: 8px;
`;

export const SelectedBox = styled.div<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "flex" : "none")};
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0;
  border-radius: 8px;
  gap: 8px;
  padding: 0px 0px 16px 0px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 32px;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${tokens.color.grays300};
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 32px;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-bottom: 1px solid ${tokens.color.grays300};
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 16px 32px;
  gap: 8px;
  justify-content: center;
  width: 100%;
`;
