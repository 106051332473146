import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

type Size = {
  height?: number | string;
  width?: number | string;
};

const setSizeValue = (type: string, value: Size) => {
  if (!value) return "auto";
  if (isNaN(+value[type])) return value;
  return value[type] + "px";
};

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const ModalFrame = styled(motion.div)<{ $size: Size }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${(p) => setSizeValue("width", p.$size)};
  height: ${(p) => setSizeValue("height", p.$size)};
  border-radius: 16px;
  box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.1);
  background-color: ${tokens.color.grays100};
  pointer-events: auto;
  overflow: hidden;
`;

export const Header = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${tokens.color.graysTextPrimary};
  margin: 0;
  width: 100%;
  padding: 32px 32px 16px 32px;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  margin: 0;
  width: 100%;
  flex: 1;
  max-height: 550px;
  overflow: hidden;
  padding: 0;
`;

export const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin: 0;
  width: 100%;
  padding: 16px 32px;
`;

export const Title = styled.p`
  ${tokens.type.pRegular};
  ${tokens.type.pBoldFace};
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const SubTitle = styled.p<{ $show: boolean }>`
  display: ${(p) => (p.$show ? "inherit" : "none")};
  ${tokens.type.pSmall};
  color: ${tokens.color.graysTextTertiary};
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
  opacity: 0.2;
`;
