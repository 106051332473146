const Other = {
  questions: [
    {
      template: "exitflow",
      title: "Let's talk",
      alt: "Other scenarios",
      description:
        "Understanding your unique requirements will help us provide a solution configuration and quote that best matches what you need. Let's get you connected with our global experts.",
      responses: null,
      required: "none",
    },
  ],
};

export default Other;
/*
const Other = {
  questions: [
    {
      title:
        "Can you briefly describe the specific challenge or requirement you're facing?",
      alt: "Challenges or requirements",
      description:
        "Your unique problem or challenge will help determine the ideal solution.",
      template: "question",
      responses: [
        {
          fieldname: "challenges",
          control: "textarea",
          size: { height: 60, width: "80%" },
          placeholder: "List out challenges",
        },
      ],
      required: "all",
    },
    {
      title:
        "In which country or countries is this challenge or requirement based?",
      alt: "Countries or markets",
      description:
        "Some countries have unique legal and compliance requirements that may require unique service levels.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};
*/
