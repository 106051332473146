import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";
import { motion } from "../../pages/tokens";
import { useNavigate } from "react-router-dom";
import { useAnimationControls } from "framer-motion";
import { useEffect } from "react";

interface Props {
  label?: string;
  icon?: ico;
  showicon?: boolean;
  type?: "unframed" | "primary" | "secondary" | "tertiary" | "link";
  size?: "small" | "regular" | "medium" | "large" | "xlarge";
  theme?: "white" | "blue" | "orange" | string;
  onClick?: () => void;
  transition?: any;
  navigate?: string | number;
}

export default function UIButton(props: Props) {
  const {
    label = null,
    icon = ico.arrowLeft,
    showicon = true,
    size = "regular",
    type = "unframed",
    theme = "blue",
    navigate = null,
    onClick = () => null,
  } = props;
  const doNavigation = useNavigate();
  const controls = useAnimationControls();

  // for liks we need to reverse blue theme to output blue color and not white
  const setTheme = () => {
    if (theme === "blue" && type === "link") return "blueLink";
    if (theme === "blueLink") return "blueButton";
    return theme;
  };

  useEffect(() => {
    let themeToSet = theme;
    if (theme === "blue" && type === "link") themeToSet = "blueLink";
    controls.start(themeToSet);
  }, [theme, controls, type]);

  const setIcon = () => {
    if (!showicon) return null;
    return (
      <Icon
        name={icon}
        size={Styled.iconSizes[size].size}
        stroke={Styled.iconSizes[size].stroke}
        theme={setTheme()}
      />
    );
  };

  const setLabel = () => {
    return label ? label : null;
  };

  const handleClick = () => {
    if (navigate) {
      if (isNaN(+navigate)) doNavigation("/" + navigate);
      doNavigation(-Math.abs(+navigate));
      return;
    }
    onClick();
  };

  return (
    <Styled.Button
      size={size}
      theme={theme}
      type={type}
      variants={Styled.themeColors}
      initial={Styled.themeColors[setTheme()]}
      transition={{ ...motion.themeChange }}
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => handleClick()}
      animate={controls}
    >
      {setIcon()}
      {setLabel()}
    </Styled.Button>
  );
}
