import * as Styled from "./Styles";
import CheckBox from "../CheckBox/CheckBox";
import { useEffect, useState } from "react";

interface Props {
  accepted?: boolean;
  termsLink?: string;
  privacyLink?: string;
  variant?: "full" | "privacy";
  onChange?: (accepted: boolean) => void;
}

export default function AcceptTerms(props: Props) {
  const {
    accepted = false,
    termsLink = "https://www.globalization-partners.com/terms-conditions/",
    privacyLink = "https://www.globalization-partners.com/privacy-policy/",
    variant = "full",
    onChange = () => null,
  } = props;
  const [isAccepted, setIsAccepted] = useState(accepted);
  useEffect(() => setIsAccepted(accepted), [accepted]);
  const handleChange = (state: boolean) => {
    setIsAccepted(state);
    onChange(state);
  };

  return (
    <Styled.Wrapper>
      <CheckBox checked={isAccepted} onChange={(state) => handleChange(state)} />
      <div style={{ display: "inline" }}>
        <Styled.Text onClick={() => handleChange(!isAccepted)}>I agree to G-P's</Styled.Text>
        <span style={{ display: variant === "full" ? "inline" : "none" }}>
          <Styled.Link href={termsLink} target={"_blank"}>
            Terms of Service
          </Styled.Link>
          <Styled.Text onClick={() => handleChange(!isAccepted)}>and</Styled.Text>
        </span>
        <Styled.Link href={privacyLink} target={"_blank"}>
          Privacy Policy
        </Styled.Link>
      </div>
    </Styled.Wrapper>
  );
}
