import { createContext, useContext } from "react";
import usePageStore from "../callbacks/usePageData";

const UserInfoContext = createContext(null);

interface Props {
  children?: any;
}

function USerInfoProvider(props: Props) {
  const { children } = props;
  const [userData, setUserData] = usePageStore({ contactInfo: null });

  return (
    <UserInfoContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserInfoContext.Provider>
  );
}

function useUserInfo() {
  const context = useContext(UserInfoContext);
  return context;
}

export { USerInfoProvider, useUserInfo };
