import * as Styled from "./Styles";
import CheckBox from "../CheckBox/CheckBox";
import { useEffect, useState } from "react";

interface Props {
  accepted?: boolean;
  onChange?: (accepted: boolean) => void;
}

export default function AcceptTermsMarketing(props: Props) {
  const { accepted = false, onChange = () => null } = props;
  const [isAccepted, setIsAccepted] = useState(accepted);
  useEffect(() => setIsAccepted(accepted), [accepted]);
  const handleChange = (state: boolean) => {
    setIsAccepted(state);
    onChange(state);
  };

  return (
    <Styled.Wrapper>
      <CheckBox
        checked={isAccepted}
        onChange={(state) => handleChange(state)}
      />
      <div style={{ display: "inline" }}>
        <Styled.Text onClick={() => handleChange(!isAccepted)}>
          Yes, I'd like to receive occasional news and tips on how to expand my
          business internationally.
        </Styled.Text>
      </div>
    </Styled.Wrapper>
  );
}
