import InputField from "../InputField/InputField";
import { ButtonType } from "../SurveyButton/SurveyButton";
import * as Countries from "../Survey/SurveyQuestions/CountryList";
import { useState } from "react";
import SurveyButton from "../SurveyButton/SurveyButton";
import * as Styled from "./Styles";
import Icon, { ico } from "../../assets/images/Icon";
import ModalButton from "../ModalButton/ModalButton";

const countries = Countries.AllCountries.map((country, i) => {
  return country.text;
});

interface Props {
  doneClick?: (countried: string[]) => void;
  cancelClick?: () => void;
}

export default function FindCountry(props: Props) {
  const { doneClick = () => null, cancelClick = () => null } = props;
  const [results, setResults] = useState(countries);
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState("");

  const removeSelection = (name: string) => {
    const exists = selected && selected.includes(name);
    if (!exists) return;
    const selections = [...selected];
    const index = selections.indexOf(name);
    selections.splice(index, 1);
    setSelected(selections);
    setFilter("");
  };

  const handleSelection = (name: string) => {
    const exists = selected && selected.includes(name);
    if (exists) {
      removeSelection(name);
      return;
    }
    const selections = selected ? [...selected] : [];
    selections.push(name);
    setSelected(selections);
    setResults(countries);
    setFilter("");
  };

  const handleFilterChange = (value: string) => {
    const filtered = Countries.AllCountries.map((item, i) => {
      let country = item.text.toLowerCase();
      let alt = item.alt ? item.alt.toLowerCase() : null;
      let match = value.toLowerCase();
      return country.includes(match) || alt?.includes(match) ? item.text : null;
    });
    setFilter(value);
    setResults(filtered);
  };

  const displaySelected = () => {
    if (selected && selected.length > 0) {
      return selected.map((country: string, i: number) => {
        return (
          <CountryButton
            key={country + "_" + i}
            name={country}
            didRemove={(name) => removeSelection(name)}
          />
        );
      });
    }
  };

  const listCountries = () => {
    return countries.map((country: any, i: number) => {
      return (
        results.includes(country) && (
          <SurveyButton
            key={i}
            type={ButtonType.default}
            text={country}
            onChange={() => handleSelection(country)}
            theme={"modal"}
            selected={selected?.includes(country)}
          />
        )
      );
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <InputField
          value={filter}
          leftIcon={true}
          required={false}
          placeholder={"enter a country name"}
          theme={"unframed"}
          onChange={(value) => handleFilterChange(value)}
        />
        <Styled.SelectedBox $show={selected && selected.length > 0}>
          {displaySelected()}
        </Styled.SelectedBox>
      </Styled.Header>
      <Styled.ListWrapper style={{ height: "100%" }}>
        <Styled.CountryList>{listCountries()}</Styled.CountryList>
      </Styled.ListWrapper>
      <Styled.ButtonBar>
        <ModalButton label={"Cancel"} showicon={false} length={150} onClick={() => cancelClick()} />
        <ModalButton
          label={"Done"}
          showicon={false}
          length={150}
          onClick={() => doneClick(selected)}
        />
      </Styled.ButtonBar>
    </Styled.Wrapper>
  );
}

interface Props {
  name?: string;
  didRemove?: (name: string) => void;
}
const CountryButton = (props: Props) => {
  const { name = "country", didRemove = () => null } = props;
  return (
    <Styled.SelectedButton>
      {name}
      <Styled.Separator />
      <Icon name={ico.x} size={24} onClick={() => didRemove(name)} theme={"blue"} />
    </Styled.SelectedButton>
  );
};
