import { ico } from "../../../../assets/images/Icon";
import { useGIA } from "../../../../contexts/GIAContext";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";
import { shortIntro } from "../../../GIADrawer/GIAData";
import NavButton from "../../../NavButton/NavButton";
import UIButton from "../../../UIButton/UIButton";
import * as Styled from "./Styles";
import { useUserInfo } from "../../../../contexts/UserInfo";
import { useNavigate } from "react-router-dom";
import { TertiaryButton } from "../../../TertiaryButton/TertiaryButton";

type Info = {
  title: string;
  text: string;
};

const info = {
  title: "G-P's Terms of service",
  text: "Carefully read through our terms of service to understan important details on how EOR with G-P works and what is epxected from bpth parties. This is important legal information you should consider carefully.",
};

export function Confirmation() {
  const { showGIAMessages, setShowGIAMessages, lastMessage, setLastMessage } = useGIA();
  const { setChatGTPThinking, messages, setMessages } = useGIA();
  const { userData } = useUserInfo();

  /** displays info panel based on the selected information icon */
  const showInfo = (info: Info) => {
    if (!showGIAMessages) setShowGIAMessages(true);
    const newMessage: ChatMessage = {
      isResponding: false,
      message: { title: info.title, text: info.text },
      sender: MessageSender.GIA,
    };
    if (lastMessage && lastMessage.message.text === newMessage.message.text) return;
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) setMessages([...shortIntro, newMessage]);
      else setMessages([...messages, newMessage]);
      setLastMessage(newMessage);
    }, 1000);
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Account created & next steps</Styled.Title>
      <Styled.Description>
        {`You're G-P account has been created and an activation email has been sent to ${userData.contactInfo?.email}.`}
        <MoreInfoButton info={info} showInfo={(info) => showInfo(info)} />
      </Styled.Description>
      <Styled.BodyText>
        What happens next?
        <ul>
          <li>
            Add your first professional: if you already have a professional in mind, enter their
            information to begin the hiring and onboarding process.
            <TertiaryButton
              style={{ marginTop: 8 }}
              width={"min-content"}
              label={"Add Professional(s)"}
            />
          </li>
          <li>
            Check your email: follow the link we sent you to verify your email, activate your
            account and sign in.
            <TertiaryButton
              style={{ marginTop: 8 }}
              width={"min-content"}
              label={"Open email app"}
            />
          </li>
        </ul>
      </Styled.BodyText>
      <Navigation theme={"blue"} />
    </Styled.QuestionWrapper>
  );
}

/**
 * More Info Button
 */

interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | "green" | "red" | string;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { theme = "blue" } = props;
  const navigate = useNavigate();
  return (
    <Styled.NavButtons>
      <NavButton
        theme={theme}
        showicon={true}
        icon={ico.check}
        iconStyle={{ size: 24, stroke: 1.5 }}
        label={"Done"}
        length={200}
        onClick={() => navigate("/get-started")}
      />
    </Styled.NavButtons>
  );
};
