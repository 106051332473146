import { ico } from "../../../../assets/images/Icon";
import { useGIA } from "../../../../contexts/GIAContext";
import { useSignUp } from "../../../../contexts/SignUp";
import { ChatMessage, MessageSender } from "../../../EnumsAndTypes/EnumsAndTypes";
import { shortIntro } from "../../../GIADrawer/GIAData";
import NavButton, { State } from "../../../NavButton/NavButton";
import UIButton from "../../../UIButton/UIButton";
import * as Styled from "./Styles";

type Info = {
  title: string;
  text: string;
};

const info = {
  title: "G-P's Terms of service",
  text: "Carefully read through our terms of service to understan important details on how EOR with G-P works and what is epxected from bpth parties. This is important legal information you should consider carefully.",
};

export function TermsOfService() {
  const { showGIAMessages, setShowGIAMessages, lastMessage, setLastMessage } = useGIA();
  const { setChatGTPThinking, messages, setMessages } = useGIA();
  const { setDidScrollTerms } = useSignUp();

  /** displays info panel based on the selected information icon */
  const showInfo = (info: Info) => {
    if (!showGIAMessages) setShowGIAMessages(true);
    const newMessage: ChatMessage = {
      isResponding: false,
      message: { title: info.title, text: info.text },
      sender: MessageSender.GIA,
    };
    if (lastMessage && lastMessage.message.text === newMessage.message.text) return;
    setChatGTPThinking(true);
    setTimeout(() => {
      setChatGTPThinking(false);
      if (!messages) setMessages([...shortIntro, newMessage]);
      else setMessages([...messages, newMessage]);
      setLastMessage(newMessage);
    }, 1000);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    console.log(el?.scrollTop, el?.scrollHeight, el?.offsetHeight);
    if (el?.scrollTop >= el?.scrollHeight - el?.offsetHeight - 48) {
      setDidScrollTerms(true);
    }
  };

  return (
    <Styled.QuestionWrapper>
      <Styled.Title>Review and accept terms of service</Styled.Title>
      <Styled.Description>
        Scroll down to review and accept your terms of service.
        <MoreInfoButton info={info} showInfo={(info) => showInfo(info)} />
      </Styled.Description>
      <Styled.TermsBoxWrapper>
        <Styled.TermsBox onScroll={(e) => handleScroll(e)}>
          <p className="title">Terms of Service</p>
          <br />
          <p>
            This Term Sheet is governed by and part of the Master Agreement between the Parties
            (“Agreement”), which consists of: (1) the General Terms, (2) the Product Specific Terms,
            (3) any Statement of Work, and (4) any Purchase Order or Country Pricing Schedule.{" "}
          </p>
          <p>
            The Agreement is entered into and effective as of the last date of signature of this
            Term Sheet (the "Effective Date"), by and between:{" "}
          </p>
          <p>
            Acme LLC, a Delaware company with a principal place of business at 175 Harbor Road, 10th
            Floor, Boston, Massachusetts 07310 USA (“Acme”), and
          </p>
          <p>
            Compnay name with a principal place of business at business address. For purposes of
            this Agreement, Customer includes Customer’s respective current parents, holding
            companies, successors, predecessors, joint ventures, and subsidiaries and affiliated or
            group companies.
          </p>
          <p>
            Acme and Customer may be referenced individually as “Party” or collectively as
            “Parties.”
          </p>
          <p className="title">Package offering and upgrade</p>
          <p>
            As of the Effective Date, Customer will have access to Acme’ current product offerings.
            Customer will be automatically upgraded to G-P Meridian Core upon availability, without
            any changes to Management Fees (Monthly Services Fee, Professional Cost, and Transition
            Administration Fee). Use of any available G-P Meridian products or services beyond those
            identified in the Products and Pricing section of this Term Sheet will be subject to
            acceptance of such product’s or service’s Product Specific Terms, which will be
            presented prior to use.
          </p>
          <p className="title">Products and Pricing</p>
          <p>
            Use of Acme’ products and services is subject to their respective terms (the “Product
            Specific Terms”). As of the Effective Date of this Agreement, Customer selects and
            agrees to the Product Specific Terms for the following products. “Total Cost of
            Employment” means salary, allowances, commissions, bonuses, required and supplemental
            insurances, termination costs, other statutory and required payments, and market-norm
            benefits that are paid through to the Professional. “Total Cost of Services” means
            salary, allowances, commissions, bonuses, required and supplemental insurances,
            termination costs, other statutory and required payments, and market-norm benefits that
            are paid through to the Professional performing the Services.
          </p>
          <p className="title">Standard Employer of Record</p>
          <p className="subtitle">Countries Available</p>
          <p>
            Albania, Algeria, Angola, Argentina, Australia, Austria, Azerbaijan, Bahamas,
            Bangladesh, Belarus, Benin, Bolivia, Bosnia, Botswana, Brazil, Bulgaria, Canada,
            Cambodia, Cameroon, Cape Verde, Central African Republic, Chad, Chile, China, Colombia,
            Congo, Costa Rica, Cote D'Ivoire, Croatia, Cyprus, Czech Republic, Democratic Republic
            of Congo - DRC, Denmark, Dominican Republic, Egypt, El Salvador, Estonia, Ethiopia,
            Finland, France, Gabon, Georgia, Germany, Ghana, Greece, Guatemala, Guinea (Conkary,
            Bissau), Honduras, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel,
            Italy, Ivory Coast, Jamaica, Jordan, Kazakhstan, Kenya, Kuwait, Kyrgyzstan, Latvia,
            Lebanon, Lesotho, Lithuania, Luxembourg, Macau, Macedonia, Madagascar, Malawi, Malaysia,
            Mali, Mauritania, Mauritius, Moldova, Mongolia, Montenegro, Morocco, Mozambique,
            Namibia, Netherlands, New Zealand, Nicaragua, Nigeria, Norway, Oman, Pakistan, Panama,
            Paraguay, Peru, Poland, Portugal, Puerto Rico, Qatar, Romania, Russia, Rwanda, Senegal,
            Serbia, Sierra Leone, Singapore, Slovak Republic, Slovenia, South Africa, South Korea,
            Spain, Sri Lanka, Swaziland, Sweden, Switzerland, Tajikistan, Tanzania, Thailand,
            Trinidad & Tobago, Tunisia, Turkey, Uganda, Ukraine, United Kingdom,United States,
            Uruguay, Uzbekistan, Vietnam, Zambia, Zimbabwe
          </p>
          <p className="subtitle">Pricing</p>
          <p className="list">Monthly services fee:USD 599 per professional per Month.</p>
          <p className="list">Professional Costs: USD 99 per professional per month</p>
          <p className="listlast">
            One-Time Transition Administration Fee per Professional: USD 850
          </p>
          <p className="subtitle">Cost of Compliant Employment</p>
          <p>Quoted per country</p>
          <p className="subtitle">Deposit</p>
          <p>1 month of the total cost of employment per professional</p>
          <p className="title">Currency and Payment</p>
          <p>
            Invoice currency: USDPayment Method: Direct Debit. Customer must authorize Acme for
            automated withdrawal of all invoices issued under this Agreement from the Customer’s
            designated bank account. Customer will provide the requested bank account details
            through the Acme Platform. Failure to authorize Acme for automated withdrawals from the
            designated bank account, failure to provide bank account details, and any rejected
            direct debit withdrawal attempt all constitute a breach of this Agreement.
          </p>
          <p>
            Payment Terms: Unless otherwise specified in the Product Specific Terms, payment on
            invoices is due 3 calendar days after the invoice date (“Payment Term”), via the Payment
            Method.
          </p>
        </Styled.TermsBox>
      </Styled.TermsBoxWrapper>
      <Navigation />
    </Styled.QuestionWrapper>
  );
}

/**
 * More Info Button
 */

interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};

/**
 * Navigation back and next buttons
 */
interface NavButtonProps {
  theme?: "white" | "blue" | "orange" | "green" | "red" | string;
  isValid?: boolean;
}
const Navigation = (props: NavButtonProps) => {
  const { setPageIndex, pageIndex, didScrollTerms } = useSignUp();
  return (
    <Styled.NavButtons>
      <NavButton
        theme={"white"}
        showicon={true}
        icon={ico.xCircle}
        iconStyle={{ size: 24, stroke: 1.5 }}
        label={"Decline"}
        length={200}
        onClick={() => setPageIndex(pageIndex - 1)}
      />
      <NavButton
        theme={"green"}
        state={didScrollTerms ? State.active : State.disabled}
        showicon={true}
        icon={ico.check}
        iconStyle={{ size: 24, stroke: 1.5 }}
        label={"Agree to Terms"}
        length={200}
        onClick={() => (didScrollTerms ? setPageIndex(pageIndex + 1) : null)}
      />
    </Styled.NavButtons>
  );
};
