import * as Styled from "./Style";

export default function Divider() {
  return (
    <Styled.Wrapper>
      <Styled.Line />
      or
      <Styled.Line />
    </Styled.Wrapper>
  );
}
