const Contractor = {
  questions: [
    {
      template: "exitflow",
      title: "Manage Contractors",
      alt: "Manage Contractors",
      description:
        'G-P has a unique solution for managing contractors, along with specific pricing options. Click "Sign Up" to be redirected to create a free G-P account and access our contractor portal.',
      responses: null,
      required: "none",
    },
  ],
};

export default Contractor;
