import styled from "styled-components";
import * as tokens from "../../pages/tokens";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 868px;
  width: 100%;
  gap: 88px;
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 225px;
  gap: 24px;
`;

export const MeetingTitle = styled.h1`
  ${tokens.type.pRegular};
  color: ${tokens.color.graysTextPrimary};
  font-size: 20px;
  ${tokens.type.pBoldFace};
  line-height: 1.2em;
  margin: 0;
  padding: 0;
`;

export const MeetingDetails = styled.div`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextTertiary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;

export const DetailsItem = styled.div`
  ${tokens.type.pMedium};
  color: ${tokens.color.graysTextTertiary};
  line-height: 1.2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const MeetingDescription = styled.p`
  ${tokens.type.pRegular};
  color: ${tokens.color.graysTextPrimary};
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
`;
