import { Product } from "../../assets/images/Products";
import QuoteCard from "../QuoteCard/QuoteCard";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import QuoteCards from "./QuoteData";
import * as Styled from "./Styles";
import ProgressCheck from "../ProgressCheck/ProgressCheck";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { ico } from "../../assets/images/Icon";
import { useNavigate } from "react-router-dom";
import UIButton from "../UIButton/UIButton";
import { useGIA } from "../../contexts/GIAContext";

type Info = {
  tit?: string;
  text?: string;
};

enum CostType {
  Base,
  Optional,
  Other,
}

type CardInfo = {
  type?: CostType;
  name?: string;
  value?: string;
  valuetype?: string;
  valuequalifyer?: string;
  strikeout?: boolean;
  image?: Product;
  description?: string;
  valueprops?: string[];
  disclaimer?: string;
  maxvalueprop?: number;
  info?: Info;
  clickInfo?: (info: Info) => null;
};

const disclaimerInfo = {
  title: "Clarifying Pricing Information",
  text: "Because the total cost of employment varies considerably based on an individual's salary, bonus, benefits, and legal costs related to compliant employment in each country, we provide pricing that reflects only what we charge you for our services.\n\nThe amount we ultimately pay each individual, on your behalf, will include the compensation package you agree to with each individual, plus any costs related to compliant employment in his/her country (taxes, required benefits, etc.).",
};

interface Props {
  cards?: any;
  navigate?: (direction: number) => void;
  clickInfo?: (info: Info) => void;
  showProgress?: boolean;
  contactInfo?: any;
}

export default function QuoteResult(props: Props) {
  const {
    cards = QuoteCards,
    showProgress = false,
    clickInfo = () => null,
    contactInfo = null,
  } = props;
  const { pushGIA, didPush } = useGIA();
  const [showQuote, setShowQuote] = useState(!showProgress);

  useEffect(() => {
    if (didPush) return;
    const name = contactInfo ? contactInfo.name.split(" ")[0] + ", p" : "P";
    const text = `${name}lease let me know if I can help clarify any of the items on your quote.`;
    const timer = setTimeout(() => pushGIA(text), 4000);
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  const renderQuote = () => {
    return (
      <motion.div
        style={{ margin: 0 }}
        initial={{ height: 0, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.75 }}
        animate={showQuote ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }}
      >
        <Styled.CardsContainer>
          <Styled.Disclaimer>
            Note: Prices are approximate and include G-P fees only. Your total cost of employment
            will vary based on the compensation package you agree to with each individual plus costs
            related to compliant employment in each country.
            <MoreInfoButton info={disclaimerInfo} showInfo={(info) => clickInfo(info)} />
          </Styled.Disclaimer>
          <Styled.CardsWrapper>
            <Styled.QuoteSection>
              <Styled.SectionTitle>Base Setup</Styled.SectionTitle>
              <Styled.SectionDescription>
                Based on your goals and specific crieteria, we propose the following G-P Meridian
                Products configuration:
              </Styled.SectionDescription>
            </Styled.QuoteSection>
            {cards
              .filter((card: CardInfo) => {
                return card.type === CostType.Base;
              })
              .map((card: CardInfo, i: number) => {
                return (
                  <QuoteCard
                    key={card.name + "_" + i}
                    image={card.image}
                    name={card.name}
                    value={card.value}
                    valuequalifyer={card.valuequalifyer}
                    valuetype={card.valuetype}
                    valueprops={card.valueprops}
                    description={card.description}
                    disclaimer={card.disclaimer}
                    strikeout={card.strikeout}
                    info={card.info}
                    clickInfo={(info) => clickInfo(info)}
                  />
                );
              })}
          </Styled.CardsWrapper>
          <Styled.CardsWrapper>
            <Styled.QuoteSection>
              <Styled.SectionTitle>Options / Add-ons</Styled.SectionTitle>
              <Styled.SectionDescription>
                We also suggest the following optional services and add-ons:
              </Styled.SectionDescription>
            </Styled.QuoteSection>
            {cards
              .filter((card: CardInfo) => {
                return card.type !== CostType.Base;
              })
              .map((card: CardInfo, i: number) => {
                return (
                  <QuoteCard
                    key={card.name + "_" + i}
                    image={card.image}
                    name={card.name}
                    value={card.value}
                    valuequalifyer={card.valuequalifyer}
                    valuetype={card.valuetype}
                    valueprops={card.valueprops}
                    description={card.description}
                    disclaimer={card.disclaimer}
                    strikeout={card.strikeout}
                    info={card.info}
                    clickInfo={(info) => clickInfo(info)}
                  />
                );
              })}
          </Styled.CardsWrapper>
          <Navigation />
        </Styled.CardsContainer>
      </motion.div>
    );
  };

  const renderProgress = () => {
    return (
      <ProgressCheck
        playing={true}
        size={100}
        stroke={0.5}
        progressEnd={() => {
          setShowQuote(true);
        }}
      />
    );
  };

  return (
    <Styled.QuoteWrapper>
      {renderProgress()}
      {renderQuote()}
    </Styled.QuoteWrapper>
  );
}

/**
 * More info
 */
interface InfoProps {
  info?: Info;
  showInfo?: (info: Info) => void;
}
const MoreInfoButton = (props: InfoProps) => {
  const { info, showInfo = () => null } = props;
  return (
    <Styled.InfoButton $show={info ? true : false}>
      <UIButton
        icon={ico.info}
        theme={"blueLink"}
        type={"unframed"}
        onClick={() => showInfo(info)}
      />
    </Styled.InfoButton>
  );
};

/**
 * Navigation back and next buttons
 */
const Navigation = () => {
  const navigate = useNavigate();
  const { setShowGIAMessages } = useGIA();
  return (
    <Styled.NavButtons>
      <Styled.Link>
        <PrimaryButton
          theme={"blue"}
          icon={ico.chatUser}
          type={"secondary"}
          length={180}
          label={"Review with expert"}
          animate={false}
          onClick={() => navigate("/contact")}
        />
        <PrimaryButton
          theme={"blue"}
          showicon={true}
          icon={ico.arrowRight}
          type={"primary"}
          length={180}
          label={"Get Started"}
          animate={false}
          onClick={() => {
            setShowGIAMessages(false);
            navigate("/account");
          }}
        />
      </Styled.Link>
      <UIButton
        icon={ico.download}
        size={"regular"}
        label={"Download Quote (PDF)"}
        theme={"blueLink"}
      />
    </Styled.NavButtons>
  );
};
