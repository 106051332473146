import styled from "styled-components";
import * as tokens from "../../pages/tokens";
import { motion } from "framer-motion";

export const variants = {
  default: {
    border: "1px solid rgba(255, 255, 255, 0.2)",
    scale: 1,
    backgroundColor: "rgba(17, 20, 23, 0.02)",
  },
  hover: {
    border: "1px solid rgba(255, 255, 255, 0.28)",
    scale: 1.02,
    backgroundColor: "rgba(17, 20, 23, 0.05)",
  },
};

export const Testimonial = styled(motion.div)`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: max-content;
  align-self: stretch;
  border-radius: 16px;
  border: ${variants.default.border};
  scale: ${variants.default.scale};
  background-color: ${variants.default.backgroundColor};
  cursor: pointer;
`;
export const Comment = styled.p`
  ${tokens.type.pRegular};
  opacity: 1;
  margin: 0;
  user-select: none;
`;
export const Company = styled.p<{ $theme: "light" | "dark" }>`
  ${tokens.type.pRegular};
  ${tokens.type.pBoldFace};
  color: ${(p) => (p.$theme === "dark" ? tokens.color.tertiary500 : tokens.color.graysTextPrimary)};
`;
