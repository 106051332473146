import { useEffect, useState } from "react";
// import this pages styled componets
import * as Styled from "./Styles";
import { color, motion as trans } from "../tokens";
// import components
import PageHeader from "../../components/PageHeader/PageHeader";
import Carousel from "../../components/Carousel/Carousel";
import Testimonial from "../../components/Testimonial/Testimonial";
import PageIndicator from "../../components/PageIndicator/PageIndicator";
import StartOptions from "../../components/StartOptions/StartOptions";
// import data objects
import { Testimonials } from "../../assets/content/Testimonials/Testimonials";
import { ValuePropGetStarted } from "../../assets/content/ValueProps/ValuePropGetStarted";
import { useGIA } from "../../contexts/GIAContext";
import InfoPanel from "../../components/InfoPanel/InfoPanel";

const clearInfo = {
  show: false,
  info: {
    title: null,
    text: null,
  },
};

export default function GetStarted() {
  const { showGIAMessages, setShowGIAMessages } = useGIA();
  const [infoPanel, setInfoPanel] = useState(clearInfo);

  useEffect(() => {
    document.body.style.backgroundColor = color.graysWhite;
  }, []);

  useEffect(() => {
    if (showGIAMessages)
      setInfoPanel({ show: true, info: { title: "title", text: "text" } });
    else setInfoPanel({ show: false, info: { title: "title", text: "text" } });
  }, [showGIAMessages]);

  //** transtion info for displing the right info panel */
  const viewportVariants = {
    showinfo: { marginRight: 349, borderRight: "1px solid rgba(0,0,0,1)" },
    default: { marginRight: 0, borderRight: "1px solid rgba(0,0,0,0)" },
  };

  return (
    <Styled.ViewPort
      variants={viewportVariants}
      initial={"default"}
      transition={{ ...trans.easIn, duration: 0.25 }}
      animate={infoPanel.show ? "showinfo" : "default"}
    >
      <InfoPanel
        title={infoPanel.info.title}
        text={infoPanel.info.text}
        visible={infoPanel.show}
        didClose={(show, info) => {
          setInfoPanel({ show: show, info: info });
          setShowGIAMessages(false);
        }}
      />
      <PageHeader />
      <Styled.Page>
        <Styled.ValuePropContainer>
          <Styled.ValuePropWrapper>
            <ValuePropGetStarted />
            <Carousel
              itemTemplate={<Testimonial />}
              paggingTemplate={<PageIndicator />}
              collection={Testimonials}
              paggingType={"logo"}
              theme={"dark"}
            />
          </Styled.ValuePropWrapper>
        </Styled.ValuePropContainer>
        <Styled.OptionsContainer>
          <StartOptions />
        </Styled.OptionsContainer>
      </Styled.Page>
    </Styled.ViewPort>
  );
}
