import home from "../../assets/images/homepage.png";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative" }}>
      <img src={home} width={"100%"} height={"auto"} alt={"Static view of the new homepage"} />
      <div
        style={{
          position: "absolute",
          width: 375,
          height: 150,
          top: 24,
          right: 24,
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
        onClick={() => navigate("/get-started")}
      />
    </div>
  );
}
