import { ButtonType } from "../../../SurveyButton/SurveyButton";
import * as Data from "../SurveyData";

const Project = {
  questions: [
    {
      title: "Let's start with your company size and industry.",
      alt: "Compnay size and industry",
      description:
        "Your company scale and particular type of business helps determine the platform specs and optional add-ons.",
      template: "question",
      responses: [
        {
          subtitle: "Company Size",
          options: Data.companysize,
          fieldname: "companysize",
          buttontype: ButtonType.default,
          multiselect: false,
        },
        {
          subtitle: "Industry",
          options: Data.indusrties,
          fieldname: "industry",
          buttontype: ButtonType.default,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      title: "In which country or countries will this  project be executed?",
      alt: "Countries",
      description:
        "Some countries have unique legal and compliance requirements that may require specific attention and services.",
      template: "question",
      responses: [
        {
          options: Data.countries,
          fieldname: "countries",
          buttontype: ButtonType.country,
          multiselect: true,
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "Are you looking to hire employees or contractors or both for this project?",
      alt: "How many employees or contractors",
      description:
        "As an EOR provider, we can hire the employees and provide them with benefits so you don't need to setup a legal entity or deal with compliance/legal issues.",
      responses: [
        {
          buttontype: ButtonType.multiline,
          options: Data.hiretype,
          fieldname: "hiretype",
          multiselect: false,
          size: { height: 100, width: 225 },
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "How many employees or contractors do you anticipate hiring for this project?",
      alt: "How many employees or contractors",
      description:
        "Depending on the number of employees or contractors you need, you may have access to size discounts and benefits.",
      responses: [
        {
          subtitle: "Employees (hired by us, managed by you)",
          options: Data.numberof,
          fieldname: "employees",
        },
        {
          subtitle: "Contractors",
          options: Data.numberof,
          fieldname: "contractors",
        },
      ],
      required: "any",
    },
    {
      template: "question",
      title: "Will you need assistance sourcing/recruiting candidates for this project?",
      alt: "Recruiting needs",
      description:
        "We can help with full the full range of recruiting and hiring services in over 180 countries.",
      responses: [
        {
          buttontype: ButtonType.multiline,
          options: Data.recruitoptions,
          fieldname: "recruitoptions",
          multiselect: true,
          size: { height: 100, width: 225 },
        },
      ],
      required: "none",
    },
    {
      template: "question",
      title: "How soon do you anticipate starting this project?",
      alt: "Timeline",
      description:
        "How quickly you need to get boots on the ground will drive specific legal, HR and compliance support to help expedite the process.",
      responses: [
        {
          options: Data.timeframe,
          fieldname: "timeframe",
        },
      ],
      required: "all",
    },
    {
      template: "question",
      title: "What are your priorities and areas of focus as you look to realize this project?",
      alt: "Priorities",
      description:
        "We'll make sure your proposed configuration and pricing emphasizes supporting those areas that you feel are most critical to a successful outcome for your project.",
      responses: [
        {
          options: Data.priorities,
          fieldname: "priorities",
          multiselect: true,
        },
      ],
      required: "all",
    },
  ],
};

export default Project;
