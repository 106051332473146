import * as Styled from "./Styles";
import ProgressCheck2 from "../../components/ProgressCheck/ProgressCheck";

export default function Preview() {
  return (
    <Styled.Page>
      <ProgressCheck2 />
    </Styled.Page>
  );
}
