import * as SC from "./Styles";
import { useRef, useState, useEffect } from "react";
import Icon, { ico } from "../../assets/images/Icon";
import { ChatMessage, MessageSender } from "../EnumsAndTypes/EnumsAndTypes";

interface Props {
  maxHeight?: number;
  focused?: boolean;
  height?: string;
  size?: "regular" | "small" | "tiny";
  placeholder?: string;
  onSendMessage?: (message: ChatMessage) => void;
}

export default function InputMessage(props: Props) {
  const {
    size = "regular",
    maxHeight = 300,
    focused = false,
    height = "24px",
    onSendMessage = () => null,
    placeholder = "Message GIA",
  } = props;

  const ref = useRef(null);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (focused && ref && ref.current) ref.current.focus();
  }, [focused]);

  const adjustHeight = () => {
    if (ref && ref.current) {
      ref.current.style.height = "0px";
      ref.current.style.height = Math.min(ref.current.scrollHeight, maxHeight) + "px";
    }
  };
  const resetHeight = () => {
    if (ref && ref.current) {
      ref.current.style.height = height;
    }
  };

  const doSubmit = () => {
    if (message !== "") {
      onSendMessage({
        message: { title: null, text: message },
        sender: MessageSender.User,
        display: true,
      });
      ref.current.value = null;
      setMessage("");
      resetHeight();
      ref.current.focus();
    }
  };

  const handleKeyDown = (event) => {
    if (!event.shiftKey && event.key === "Enter") {
      if (message !== "") {
        doSubmit();
        if (ref && ref.current) {
          ref.current.value = "";
        }
      }
      event.preventDefault();
    }
  };

  const styleOverride = () => {
    if (size === "small") return { padding: 10, gap: 4 };
  };

  return (
    <SC.Wrapper style={{ ...styleOverride() }}>
      <SC.TextArea
        id={"messageInput"}
        name={"messageInput"}
        ref={ref}
        value={message}
        onChange={({ target }) => setMessage(target.value)}
        onInput={() => adjustHeight()}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        rows={1}
        $size={size}
      />
      <Icon
        name={size === "regular" ? ico.arrowRight : ico.arrowRight}
        theme={message !== "" ? "blueButton" : "system"}
        onClick={() => doSubmit()}
        size={size === "regular" ? 24 : 20}
        stroke={size === "regular" ? 1.5 : 1.5}
      />
    </SC.Wrapper>
  );
}
