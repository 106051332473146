import { Country } from "../../../assets/images/flags/Flag";

/**
 * options for the scenarios question that comes first
 * the filter value is used to dynamically filter survey items
 * based on what the user selects here
 */
export const scenarios = [
  {
    text: "We're looking to enter a new market",
    filter: "newmarket",
    info: {
      title: "Entering new markets",
      text: "Test a new market: Before committing significant resources, companies can test waters using an EOR.\n\nRemote team expansion: For businesses embracing remote work and looking to hire talent from anywhere in the world. If your company is venturing into a new market partnering with GP can simplify the process, ensuring compliance, and reducing associated risks.",
    },
  },
  {
    text: "Transition temporary team members to permanent roles.",
    filter: "convert",
    info: {
      title: "Transition from temporary to permanent roles",
      text: "Choose this scenario if ou are looking to transition temporary team members to permanent roles.",
    },
  },
  {
    text: "We're looking to manage global contractors",
    filter: "contractor",
    info: {
      title: "Hire and Manage Contractors",
      text: "While hiring contractors is fairly straight forward, we can help ensure contracts are legally sound and follow all local compliance obligations.",
    },
  },
  {
    text: "We're switching from a current provider",
    filter: "switch",
    info: {
      title: "Switching Providers",
      text: "If you're contemplating switching EOR providers, it's essential to conduct a thorough assessment and ensure that the new provider aligns with your business needs, offers superior services, and can guarantee a smooth transition for your global workforce.",
    },
  },
  {
    text: "Our employee(s) is relocating abroad",
    filter: "relo",
    info: {
      title: "Relocating Employees",
      text: "G-Ps EOR solution streamlines the process of retaining an employee relocating to virtually any country in the world where you have no legal entity. In most cases, we can onboard your employee in a matter of days.",
    },
  },
  {
    text: "We have a temporary project",
    filter: "project",
    info: {
      title: "Temporary Projects",
      text: "When you have a temporary project, especially in a region where you don't have an established entity or face complex employment laws, G-P's Employer of Record solution can be invaluable. G-P enables quick hiring and management of staff without the commitment of long-term obligations.",
    },
  },
  {
    text: "Merger & Acquisition",
    filter: "manda",
    info: {
      title: "Mergers & Acquisitions",
      text: "Mergers and Acquisitions (M&A) can be a complex process, especially when integrating employees from different jurisdictions. GP can simplify the transitional phase, ensuring seamless employee management, retention, and compliance.",
    },
  },
  {
    text: "We're winding down our subsidiary",
    filter: "winddown",
    info: {
      title: "Entity Wind Downs",
      text: "Winding down a subsidiary, especially in foreign jurisdictions, presents unique challenges related to employee management, retention, and compliance. GP can provide solutions during this transition, ensuring a smooth and compliant process.",
    },
  },
  {
    text: "Other ...",
    filter: "other",
    info: {
      title: "Other Situations",
      text: "While there are many common scenarios where businesses utilize G-P's services, there are also unique, specific situations where G-P can provide invaluable support. Whatever your particular need, G-P offers flexibility and expertise to help you navigate complex employment landscapes.",
    },
  },
];

/**
 * All other question data
 */

export const roles = [
  { text: "Administrative" },
  { text: "Compliance" },
  { text: "Finance" },
  { text: "HR" },
  { text: "Marketing" },
  { text: "Legal" },
  { text: "IT/Technology" },
  { text: "Management" },
  { text: "Operations" },
  { text: "Other" },
];

export const countries = [
  { country: Country.Canada },
  { country: Country.China },
  { country: Country.Germany },
  { country: Country.India },
  { country: Country.Mexico },
  { country: Country.Philippines },
  { country: Country.Spain },
  { country: Country.UK },
];

export const numberof = [
  { text: "none" },
  { text: "1" },
  { text: "2" },
  { text: "3" },
  { text: "4" },
  { text: "5" },
  { text: "More than 5" },
];

export const companysize = [
  { text: "1 - 50" },
  { text: "51 - 200" },
  { text: "201 - 500" },
  { text: "501 - 1000" },
  { text: "1001 - 5000" },
  { text: "5000+" },
];

export const priorities = [
  { text: "Quick onboarding" },
  { text: "Compliance" },
  { text: "Overall Expertise" },
  { text: "Your employee experience" },
  { text: "Costs and transparency" },
  { text: "Other" },
];

export const timeframe = [
  { text: "Immediately" },
  { text: "Within 1 month" },
  { text: "Within 3 months" },
  { text: "Within 6 months" },
  { text: "More than 6 months" },
];

export const indusrties = [
  { text: "Agriculture" },
  { text: "Automotive" },
  { text: "Construction" },
  { text: "Consulting" },
  { text: "Design" },
  { text: "Education" },
  { text: "Finance" },
  { text: "Health Care" },
  { text: "Hospitality" },
  { text: "IT / Software" },
  { text: "Manufacturing" },
  { text: "Retail" },
  { text: "Transportation" },
  { text: "Other" },
];

export const reasonstoswitch = [
  { text: "Cost Concerns" },
  { text: "Service Quality Issues" },
  { text: "Geo Limitations" },
  { text: "Scalability Issues" },
  { text: "Compliance Challenges" },
  { text: "Other" },
];

export const transitionreasons = [
  { text: "Cost Savings" },
  { text: "Streamline operations" },
  { text: "Preserve reputation" },
  { text: "Reduce exposure to geo risk" },
  { text: "Relocate resources" },
  { text: "Other" },
];

export const relotype = [{ text: "Temporary" }, { text: "Permanent" }];

export const hiretype = [
  {
    text: ["Full time employee(s)", "We'll be their legal employer on you're behalf"],
    info: {
      title: "FTE's hired by us on your behalf",
      text: "As an EOR, we'll be the legal entity hiring the employees for your project.\n\nWe'll ensure full employment compliance and provide them with:\n- Compliant benefits\n- Additional benefits you choose\nLocal HR support\n\nWe can also provide them with additional support as needed and decided by you (IT equipment, office space, etc. ).",
    },
  },
  {
    text: ["Contractor(s)", "They will work strictly on contract"],
  },
  {
    text: "Both",
  },
];

export const yesnomaybe = [{ text: "Yes" }, { text: "No" }, { text: "Not sure" }];

export const recruitoptions = [
  {
    text: ["G-P Recruit: Source", "Evaluate and close in 180+ countries"],
    info: {
      title: "G-P Recruit",
      text: "G-P recruit lets you find, recruit and hire your top candidates, anywhere in the world.\n\nYou'll be able to track your recruiting activity, create new job orders, review your request order on the hiring process, sign your recruiting contract, and hire your new team member, all within G-P Recruit.",
    },
  },
  {
    text: ["G-P Background Checks", "Verify who you work with"],
    info: {
      title: "G-P Background Checks",
      text: "We can perform background checks to make sure the people you work with are a credible contributor.\n\nCosts for background checks vary based on both country and level of completeness.",
    },
  },
  {
    text: ["Work visas", "We'll work to secure legal working compliance"],
    info: {
      title: "Work Visas",
      text: "G-P can work both locally and in the country of origin to help secure working visas.\n\nVisa costs vary significantly from country to country.",
    },
  },
];
