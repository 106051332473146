export const AllCountries = [
  { text: "Albania" },
  { text: "Algeria" },
  { text: "Andorra" },
  { text: "Angola" },
  { text: "Argentina" },
  { text: "Armenia" },
  { text: "Aruba" },
  { text: "Australia" },
  { text: "Austria" },
  { text: "Azerbaijan" },
  { text: "Bahamas- Americas" },
  { text: "Bahrain" },
  { text: "Bangladesh" },
  { text: "Barbados" },
  { text: "Belarus" },
  { text: "Belgium" },
  { text: "Benin" },
  { text: "Bermuda" },
  { text: "Bolivia" },
  { text: "Bosnia and Herzegovina" },
  { text: "Botswana" },
  { text: "Brazil" },
  { text: "Brunei" },
  { text: "Bulgaria" },
  { text: "Burkina Faso" },
  { text: "Cambodia" },
  { text: "Cameroon" },
  { text: "Canada" },
  { text: "Cayman Islands (UK)" },
  { text: "Chad" },
  { text: "Chile" },
  { text: "China" },
  { text: "Colombia" },
  { text: "Comoros" },
  { text: "Costa Rica" },
  { text: "Cote d?Ivoire" },
  { text: "Croatia" },
  { text: "Curacao (Netherlands)" },
  { text: "Cyprus" },
  { text: "Czech Republic" },
  { text: "Democratic Rep of Congo" },
  { text: "Denmark" },
  { text: "Djbouti" },
  { text: "Dominican Republic" },
  { text: "Dominica" },
  { text: "Ecuador" },
  { text: "Egypt" },
  { text: "El Salvador" },
  { text: "Equatorial Guinea" },
  { text: "Estonia" },
  { text: "Ethiopia" },
  { text: "Faroe Islands (Denmark)" },
  { text: "Fiji" },
  { text: "Finland" },
  { text: "France" },
  { text: "Gabon" },
  { text: "Gambia" },
  { text: "Georgia" },
  { text: "Germany" },
  { text: "Ghana" },
  { text: "Greece" },
  { text: "Guatemala" },
  { text: "Guernsey (UK)" },
  { text: "Guinea" },
  { text: "Guyana" },
  { text: "Haiti" },
  { text: "Honduras" },
  { text: "Hong Kong (China)" },
  { text: "Hungary" },
  { text: "Iceland" },
  { text: "India" },
  { text: "Indonesia" },
  { text: "Ireland" },
  { text: "Isle of Man" },
  { text: "Israel" },
  { text: "Italy" },
  { text: "Jamaica" },
  { text: "Japan" },
  { text: "Jersey" },
  { text: "Jordan" },
  { text: "Kazakhstan" },
  { text: "Kenya" },
  { text: "Kosovo" },
  { text: "Kuwait" },
  { text: "Kyrgyzstan" },
  { text: "Laos" },
  { text: "Latvia" },
  { text: "Lebanon" },
  { text: "Lesotho" },
  { text: "Liberia" },
  { text: "Liechtenstein" },
  { text: "Lithuania" },
  { text: "Luxembourg" },
  { text: "Macedonia" },
  { text: "Madagascar" },
  { text: "Malawi" },
  { text: "Malaysia" },
  { text: "Maldives" },
  { text: "Mali" },
  { text: "Malta" },
  { text: "Mauritania" },
  { text: "Mauritius" },
  { text: "Mexico" },
  { text: "Moldova" },
  { text: "Montenegro" },
  { text: "Morocco" },
  { text: "Mozambique" },
  { text: "Myanmar" },
  { text: "Namibia" },
  { text: "Nepal" },
  { text: "Netherlands" },
  { text: "New Zealand" },
  { text: "Nicaragua" },
  { text: "Niger" },
  { text: "Nigeria" },
  { text: "Norway" },
  { text: "Pakistan" },
  { text: "Palestine" },
  { text: "Panama" },
  { text: "Papua New Guinea" },
  { text: "Paraguay" },
  { text: "Peru" },
  { text: "Philippines" },
  { text: "Poland" },
  { text: "Portugal" },
  { text: "Puerto Rico (USA)" },
  { text: "Qatar" },
  { text: "Republic of the Congo" },
  { text: "Romania" },
  { text: "Russia" },
  { text: "Rwanda" },
  { text: "Saint Kitts and Nevis" },
  { text: "Saudi Arabia" },
  { text: "Senegal" },
  { text: "Serbia" },
  { text: "Seychelles" },
  { text: "Sierra Leone" },
  { text: "Singapore" },
  { text: "Slovakia" },
  { text: "Slovenia" },
  { text: "South Africa" },
  { text: "South Korea" },
  { text: "Spain" },
  { text: "Sri Lanka" },
  { text: "Suriname" },
  { text: "Swaziland / Eswatini" },
  { text: "Sweden" },
  { text: "Switzerland" },
  { text: "Taiwan" },
  { text: "Tajikistan" },
  { text: "Tanzania" },
  { text: "Thailand" },
  { text: "Togo" },
  { text: "Trinidad and Tobago" },
  { text: "Tunisia" },
  { text: "Türkiye" },
  { text: "Turkmenistan" },
  { text: "Uganda" },
  { text: "Ukraine" },
  { text: "United Arab Emirates", alt: "UAE" },
  { text: "United Kingdom", alt: "UK" },
  { text: "United States", alt: "USA" },
  { text: "Uruguay" },
  { text: "Uzbekistan" },
  { text: "Vietnam" },
  { text: "Zambia" },
  { text: "Zanzibar" },
  { text: "Zimbabwe" },
];
